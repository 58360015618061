import { Badge, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { useDeleteEntityModal } from '../../Form/DeleteEntity.tsx';
import {
  ContainerIcon,
  EmptyContainerIcon,
  PartiallyFullContainerIcon,
  ProcessBufferTransferIcon,
} from '../../Icons';
import { ProcessIdName } from '../../Process/ProcessName.tsx';
import { EditProcessBufferTransferDrawerForm } from '../../ProcessBufferTransfer/EditProcessBufferTransferDrawerForm.tsx';
import { useDeleteProcessBufferTransfer } from '../../api/processBufferTransfer.ts';
import { CalendarDateTime } from '../../common';
import { ProcessBufferTransferDTO } from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export const ProcessBufferTransferRow = (props: {
  processBufferTransfer: ProcessBufferTransferDTO;
}) => {
  const { processBufferTransfer } = props;

  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.transactionStatus(processBufferTransfer);

  const deleteMutation = useDeleteProcessBufferTransfer();
  const openDeleteModal = useDeleteEntityModal({
    mutationVariables: processBufferTransfer.id,
    deleteMutation,
    entityName: 'Feedstock Transfer',
  });
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <InventoryLedgerRowTemplate
        entryStatus={status}
        date={
          <CalendarDateTime
            iso8601={processBufferTransfer.effectiveTimestamp}
          />
        }
        eventType={
          <Group spacing='xs'>
            <ProcessBufferTransferIcon />
            <Badge color='yellow'>Feedstock Transfer</Badge>
          </Group>
        }
        source={
          <Group spacing='xs'>
            <ContainerIcon />
            <ContainerIdName
              containerId={processBufferTransfer.containerId}
              time={dayjs.utc(processBufferTransfer.effectiveTimestamp)}
            />
            {processBufferTransfer.sourceEmptied ? (
              <EmptyContainerIcon />
            ) : (
              <PartiallyFullContainerIcon />
            )}
          </Group>
        }
        destination={
          <ProcessIdName processId={processBufferTransfer.processId} withIcon />
        }
        actions={
          <RowActionsMenu>
            <EditMenuItem onClick={openDrawer} />
            <DeleteMenuItem onClick={openDeleteModal} />
          </RowActionsMenu>
        }
      />
      <EditProcessBufferTransferDrawerForm
        processBufferTransferId={processBufferTransfer.id}
        opened={drawerOpened}
        onClose={closeDrawer}
      />
    </>
  );
};
