import {
  Button,
  Flex,
  Input,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconFilterX, IconListDetails } from '@tabler/icons-react';
import {
  AgGridEvent,
  AllCommunityModule,
  CellClassParams,
  CellStyle,
  ClientSideRowModelModule,
  ColDef,
  ColGroupDef,
  ColumnRowGroupChangedEvent,
  DefaultMenuItem,
  FilterChangedEvent,
  GetMainMenuItemsParams,
  GridOptions,
  IAggFuncParams,
  IRowNode,
  KeyCreatorParams,
  MenuItemDef,
  ModuleRegistry,
  RowDataUpdatedEvent,
  SideBarDef,
  themeQuartz,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import {
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  GroupFilterModule,
  LicenseManager,
  PivotModule,
  RowGroupingModule,
  RowGroupingPanelModule,
  SetFilterModule,
  TreeDataModule,
} from 'ag-grid-enterprise';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { match, P } from 'ts-pattern';
import { useTenantContext } from '../../App/TenantContext';
import { CommodityName } from '../../Commodity/CommodityName';
import { useFacilityContext } from '../../Facility/FacilityContext';
import { InternalMaterialSourceName } from '../../InternalMaterialSource/InternalMaterialSourceName';
import { LinkText } from '../../Link';
import { MaterialClassSetSelect } from '../../MaterialClassSet/MaterialClassSetSelect';
import { ProcessName } from '../../Process/ProcessName';
import { RecoveryStrategyName } from '../../RecoveryStrategy/RecoveryStategyName';
import { InternalMaterialSinkName } from '../../Repository/RepositoryName';
import {
  BasicProcessDTO,
  CommodityDTO,
  InternalMaterialSinkDTO,
  InternalMaterialSourceDTO,
  MaterialClassSetFlattenedSamplesDTO,
  ProductTypeDTO,
  RecoveryStrategyDTO,
  WeightUnit,
} from '../../rest-client';
import { Router } from '../../router';
import { LICENSEKEY } from '../../Schupan/RimasReportAgGrid';
import { formatPercentWithMinThreshold } from '../../util/percentages';
import { WithUnit } from '../../util/WithUnit';
import { WeightWithUnit } from '../../Weights/WeightUnitExpectedTotals';
import { AllSamplesTableVariant } from './AllSamplesTable';
import { CommoditySampleTableVariant } from './CommoditySampleTable';
import {
  convertedSampleAnalysisToSampleTableRow,
  flattenedSampleDtoToSampleTableRow,
  SampleTableCommodityGroupRow,
  SampleTableRow,
  SampleTableRowMetadata,
} from './SampleTableState';

const NET_WEIGHT_DIFF_PROP_THRESH = 0.03; // TODO: Move to user-controlled config and make flagging more first-class

const theme = themeQuartz.withParams({ spacing: 5 });

LicenseManager.setLicenseKey(LICENSEKEY);
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  AllCommunityModule,
  SetFilterModule,
  ColumnMenuModule,
  ContextMenuModule,
  ColumnsToolPanelModule,
  RowGroupingPanelModule,
  RowGroupingModule,
  GroupFilterModule,
  TreeDataModule,
  PivotModule,
]);

export type SampleTableBaseProps = {
  materialClassSetSamples: MaterialClassSetFlattenedSamplesDTO;
};

export function SampleTable(
  props: SampleTableBaseProps & {
    variant: AllSamplesTableVariant | CommoditySampleTableVariant;
  },
) {
  const { materialClassSetSamples, variant } = props;
  const { setFilteredRows } = variant;

  const { timeZoneId } = useFacilityContext();
  const tenant = useTenantContext();
  const isRadius = tenant.tenantId === 1;

  // convert FlattenedSampleDTOs to table rows of type SampleRowData
  const tableRows = useMemo(
    () => [
      ...materialClassSetSamples.completeSamples.map((sample) =>
        flattenedSampleDtoToSampleTableRow(sample, timeZoneId),
      ),
      ...materialClassSetSamples.convertedSampleAnalyses.map((converted) =>
        convertedSampleAnalysisToSampleTableRow(converted, timeZoneId),
      ),
    ],
    [
      materialClassSetSamples.completeSamples,
      materialClassSetSamples.convertedSampleAnalyses,
      timeZoneId,
    ],
  );

  const [showGrams, setShowGrams] = useState<boolean>(false);

  // ag-grid component refs are required for table to bottom row column alignment
  const gridRef = useRef<AgGridReact<SampleTableRow>>(null);
  const COMMODITY_COL_ID = 'commodity';

  const toggleGroupByCommodity = useCallback(() => {
    match(variant)
      .with(
        { type: 'allSamples' },
        ({ groupedByCommodity, setGroupedByCommodity }) => {
          if (setGroupedByCommodity === undefined) {
            return;
          }
          if (!gridRef.current) {
            return;
          }
          if (groupedByCommodity) {
            gridRef.current.api.removeRowGroupColumns([COMMODITY_COL_ID]);
            gridRef.current.api.onGroupExpandedOrCollapsed();
            setGroupedByCommodity(false);
          } else {
            gridRef.current.api.setRowGroupColumns([COMMODITY_COL_ID]);
            gridRef.current.api.forEachNode((node) => {
              if (node.level === 1) {
                gridRef.current!.api.setRowNodeExpanded(node, true);
              }
            });
            gridRef.current.api.onGroupExpandedOrCollapsed();
            setGroupedByCommodity(true);
          }
        },
      )
      .with({ type: 'commodity' }, () => null)
      .exhaustive();
  }, [variant]);

  // when table row data changes, reset the filtered rows
  const onRowDataUpdated = useCallback(
    (
      e:
        | FilterChangedEvent<SampleTableRow>
        | RowDataUpdatedEvent<SampleTableRow>
        | AgGridReact<SampleTableRow>,
    ) => {
      const newFilteredRows: SampleTableRow[] = [];
      e.api.forEachNodeAfterFilterAndSort(
        (rowNode: IRowNode<SampleTableRow>) => {
          if (rowNode.data) {
            newFilteredRows.push(rowNode.data);
          }
        },
      );
      newFilteredRows.sort(
        (a, b) =>
          (a.metadata.sampleNumericId ?? 0) - (b.metadata.sampleNumericId ?? 0),
      );
      setFilteredRows(newFilteredRows);
    },
    [setFilteredRows],
  );

  // when any column filter chanes, re-compute filteredRows and re-aggregate
  const onFilterChanged = useCallback(
    (e: FilterChangedEvent<SampleTableRow>) => {
      const newFilteredRows: SampleTableRow[] = [];
      e.api.forEachNodeAfterFilter((rowNode: IRowNode<SampleTableRow>) => {
        if (rowNode.data) {
          newFilteredRows.push(rowNode.data);
        }
      });

      setFilteredRows(newFilteredRows);
    },
    [setFilteredRows],
  );

  // when columns are grouped, update the bottom, aggregate row's column groupings
  const onColumnRowGroupChanged = useCallback(
    (e: ColumnRowGroupChangedEvent<SampleTableRow>) => {
      match(variant)
        .with(
          { type: 'allSamples' },
          ({
            groupedByCommodity,
            setGroupedByCommodity,
            setCommodityGroupRows,
          }) => {
            const columnGroupColIds = e.api
              .getRowGroupColumns()
              .map((col) => col.getColId());
            // the commodity column grouping can be set by the user using the column options;
            // therefore, we need to handle the groupedByCommodityState on those unhandled events.
            if (
              columnGroupColIds.indexOf(COMMODITY_COL_ID) >= 0 &&
              !groupedByCommodity
            ) {
              setGroupedByCommodity(true);
            } else if (
              columnGroupColIds.indexOf(COMMODITY_COL_ID) < 0 &&
              groupedByCommodity
            ) {
              setGroupedByCommodity(false);
            }
            const newCommodityGroupRows: SampleTableCommodityGroupRow[] = [];
            if (groupedByCommodity) {
              e.api.forEachNodeAfterFilterAndSort(
                (rowNode: IRowNode<SampleTableRow>) => {
                  if (
                    rowNode.group &&
                    rowNode.rowGroupColumn?.getColId() === COMMODITY_COL_ID
                  ) {
                    const commodityGroupRow: SampleTableCommodityGroupRow = {
                      commodityName: rowNode.key ?? 'Unknown',
                      materialClassToProportionMap: Object.fromEntries(
                        materialClassSetSamples.materialClassSet.materialClasses.map(
                          (materialClass) => [
                            materialClass.id,
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unsafe-member-access
                            (rowNode.aggData[materialClass.id]?.value ??
                              0) as number,
                          ],
                        ),
                      ),
                    };
                    newCommodityGroupRows.push(commodityGroupRow);
                  }
                },
              );
            }
            setCommodityGroupRows(newCommodityGroupRows);
          },
        )
        .with({ type: 'commodity' }, () => null)
        .exhaustive();
    },
    [materialClassSetSamples.materialClassSet.materialClasses, variant],
  );

  // needs to be memoized so column defintions do net reset on each render
  const defaultColDef: ColDef | ColGroupDef = useMemo(
    () => ({
      suppressHeaderFilterButton: true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      comparator: (valueA, valueB, _nodeA, _nodeB, isDescending) => {
        if (valueA == valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      cellRendererParams: {
        suppressCount: true,
      },
      mainMenuItems: (params: GetMainMenuItemsParams) => {
        const menuItems: (MenuItemDef | DefaultMenuItem)[] = [];
        const itemsToExclude = [
          'autoSizeThis',
          'autoSizeAll',
          'columnChooser',
          'resetColumns',
          'pinSubMenu',
        ];
        params.defaultItems.forEach((item) => {
          if (itemsToExclude.indexOf(item) < 0) {
            menuItems.push(item);
          }
        });
        return menuItems;
      },
    }),
    [],
  );

  // needs to be memoized so column defintions do net reset on each render
  const gridOptions: GridOptions<SampleTableRow> = useMemo(
    () => ({
      defaultColDef: defaultColDef,
      aggFuncs: {
        dateRange: (params: IAggFuncParams<SampleTableRow, string>) => {
          const dates = params.values.map((value) => dayjs(value));
          if (dates.some((date) => !date.isValid())) {
            return '';
          }
          return `${dayjs.max(dates)?.diff(dayjs.min(dates), 'day') ?? 0} days`;
        },
      },
      columnDefs: [
        {
          // field is left undefined because the row value is required for the cellRenderer
          // field: 'metadata.sampleNumericId', // sampleNumericId should be an int
          headerName: 'ID',
          cellDataType: 'number',
          type: 'rightAligned',
          filter: 'agNumberColumnFilter',
          enableRowGroup: false,
          aggFunc: 'count',
          pinned: true,
          sort: 'desc',
          suppressHeaderMenuButton: true,
          valueGetter: (params: ValueGetterParams<SampleTableRow, number>) =>
            params.data?.metadata.sampleNumericId,
          cellRenderer: sampleIdColCellRenderer,
        },
        {
          colId: COMMODITY_COL_ID,
          headerName: 'Commodity',
          pinned: true,
          cellDataType: 'object',
          filter: 'agSetColumnFilter',
          enableRowGroup: true,
          suppressHeaderMenuButton: false,
          hide: variant.type === 'commodity',
          suppressFiltersToolPanel: variant.type === 'commodity',
          keyCreator: (
            params: KeyCreatorParams<SampleTableRow, ProductTypeDTO>,
          ) =>
            match(params.value)
              .with(null, undefined, { type: 'Unknown' }, () => '')
              .with(
                { type: 'Commodity' },
                (commodity) => commodity.commodity.name,
              )
              .with({ type: 'Intermediate' }, () => 'intermediate')
              .exhaustive(),
          valueGetter: (
            params: ValueGetterParams<SampleTableRow, ProductTypeDTO>,
          ) =>
            match(params.data?.metadata.productType)
              .with({ type: P.string }, (productType) => productType)
              .with(null, undefined, () => undefined)
              .exhaustive(),
          valueFormatter: (
            params: ValueFormatterParams<SampleTableRow, ProductTypeDTO>,
          ) => {
            return match(params.value)
              .with(
                { type: 'Commodity' },
                (commodity) => commodity.commodity.name,
              )
              .with({ type: 'Intermediate' }, () => 'intermediate')
              .with({ type: 'Unknown' }, () => 'Unknown')
              .with(null, undefined, () => '-')
              .otherwise((value) => value);
          },
          cellRenderer: commodityColCellRenderer,
        },
        {
          headerName: 'Metadata',
          openByDefault: false,
          children: [
            {
              field: 'metadata.sampleName',
              headerName: 'Name',
              headerTooltip: 'Sample Name',
              columnGroupShow: 'open',
              hide: true, // user can toggle this column on in the sidebar menu if they are using the sample name overrid field in the NotionalSampleForm
              cellDataType: 'text',
              filter: 'agTextColumnFilter',
              enableRowGroup: false,
              aggFunc: 'count',
              suppressHeaderMenuButton: true,
            },
            {
              field: 'metadata.sampleTakenDate',
              headerName: 'Date',
              headerTooltip: 'Sample Taken Date',
              columnGroupShow: undefined, // always show
              cellDataType: 'dateString',
              filter: 'agDateColumnFilter',
              enableRowGroup: false,
              aggFunc: 'dateRange',
              suppressHeaderMenuButton: true,
            },
            {
              field: 'metadata.upstreamSourceCommodity',
              headerName: 'Source Commodity',
              headerTooltip: 'Upstream Source Commodity',
              columnGroupShow: 'open',
              hide: true, // user can toggle this column on in the sidebar menu
              cellDataType: 'object',
              filter: 'agSetColumnFilter',
              enableRowGroup: true,
              aggFunc: 'count',
              suppressHeaderMenuButton: true,
              valueFormatter: (
                params: ValueFormatterParams<SampleTableRow, CommodityDTO>,
              ) => params.value?.name ?? 'Not Specified',
              cellRenderer: upstreamSourceCommodityColCellRenderer,
            },
            {
              field: 'metadata.employedRecoveryStrategy',
              headerName: 'Strategy',
              headerTooltip: 'Employed Recovery Strategy',
              columnGroupShow: 'open',
              cellDataType: 'object',
              filter: 'agSetColumnFilter',
              enableRowGroup: true,
              aggFunc: 'count',
              suppressHeaderMenuButton: true,
              valueFormatter: (
                params: ValueFormatterParams<
                  SampleTableRow,
                  RecoveryStrategyDTO
                >,
              ) => params.value?.name ?? 'Not Specified',
              cellRenderer: employedRecoveryStrategyColCellRenderer,
            },
            {
              field: 'metadata.producingProcess',
              headerName: 'Process',
              headerTooltip: 'Producing Process',
              columnGroupShow: 'open',
              cellDataType: 'object',
              filter: 'agSetColumnFilter',
              enableRowGroup: true,
              aggFunc: 'count',
              suppressHeaderMenuButton: true,
              valueFormatter: (
                params: ValueFormatterParams<SampleTableRow, BasicProcessDTO>,
              ) => params.value?.name ?? 'Not Specified',
              cellRenderer: producingProcessColCellRenderer,
            },
            {
              field: 'metadata.exportDestination',
              headerName: 'Export',
              headerTooltip: 'Export Destination',
              columnGroupShow: 'open',
              cellDataType: 'object',
              filter: 'agSetColumnFilter',
              enableRowGroup: true,
              aggFunc: 'count',
              suppressHeaderMenuButton: true,
              valueFormatter: (
                params: ValueFormatterParams<
                  SampleTableRow,
                  InternalMaterialSinkDTO
                >,
              ) => params.value?.name ?? 'Not Specified',
              cellRenderer: exportDestinationColCellRenderer,
            },
          ],
        },
        ...(isRadius
          ? [
              {
                headerName: '3DS Parameters',
                openByDefault: false,
                children: [
                  {
                    field: 'radius3DSParameters.relativeDensity',
                    headerName: 'Relative Density',
                    headerTooltip: 'Relative Density',
                    columnGroupShow: undefined, // always show
                    cellDataType: 'number',
                    type: 'rightAligned',
                    filter: 'agNumberColumnFilter',
                    enableRowGroup: true,
                    aggFunc: 'avg',
                    suppressHeaderMenuButton: true,
                    cellRenderer: (
                      params: CustomCellRendererProps<SampleTableRow, number>,
                    ) =>
                      params.value !== undefined && params.value !== null
                        ? (Math.round(params.value * 100) / 100).toFixed(2)
                        : '',
                  },
                  {
                    field: 'radius3DSParameters.shortTonsPerHour',
                    headerName: 'Throughput',
                    headerTooltip: 'Throughput',
                    columnGroupShow: undefined, // always show
                    cellDataType: 'number',
                    type: 'rightAligned',
                    filter: 'agNumberColumnFilter',
                    enableRowGroup: true,
                    aggFunc: 'avg',
                    suppressHeaderMenuButton: true,
                    cellRenderer: (
                      params: CustomCellRendererProps<SampleTableRow, number>,
                    ) =>
                      params.value !== undefined && params.value !== null ? (
                        <WithUnit unitSuffix='tph'>
                          {Math.round(params.value)}
                        </WithUnit>
                      ) : (
                        ''
                      ),
                  },
                ],
              },
            ]
          : []),
        {
          headerName: 'Summary',
          openByDefault: false,
          children: [
            {
              field: 'analysis.explicitNetWeightGrams',
              headerName: 'Whole Sample',
              headerTooltip: 'Whole Sample Capture Weight',
              columnGroupShow: 'open',
              hide: !showGrams,
              cellDataType: 'number',
              type: 'rightAligned',
              filter: 'agNumberColumnFilter',
              enableRowGroup: true,
              aggFunc: 'avg',
              suppressHeaderMenuButton: true,
              cellRenderer: (
                params: CustomCellRendererProps<SampleTableRow, number | null>,
              ) =>
                params.value !== null && params.value !== undefined ? (
                  <WeightWithUnit
                    weightUnit={WeightUnit.GRAM}
                    totalInt={Math.round(params.value)}
                  />
                ) : (
                  ''
                ),
            },
            {
              field: 'analysis.accumulatedNetWeightGrams',
              headerName: 'Accumulated',
              headerTooltip: 'Accumulated Captures Weight',
              columnGroupShow: 'open',
              hide: !showGrams,
              cellDataType: 'number',
              type: 'rightAligned',
              filter: 'agNumberColumnFilter',
              enableRowGroup: true,
              aggFunc: 'avg',
              suppressHeaderMenuButton: true,
              cellRenderer: (
                params: CustomCellRendererProps<SampleTableRow, number>,
              ) => (
                <WeightWithUnit
                  weightUnit={WeightUnit.GRAM}
                  totalInt={params.value ? Math.round(params.value) : 0}
                />
              ),
            },
            {
              field: showGrams
                ? 'analysis.netWeightDifferenceGrams'
                : 'analysis.netWeightDifferenceProportion',
              headerName: 'Error',
              headerTooltip: showGrams
                ? 'Sample Weight Error = Accumulated Weight - Whole Sample Weight'
                : 'Sample Weight Error = (Accumulated - Whole Sample) / Whole Sample',
              columnGroupShow: undefined, // always show
              cellDataType: 'number',
              type: 'rightAligned',
              filter: 'agNumberColumnFilter',
              enableRowGroup: true,
              aggFunc: showGrams ? 'sum' : 'avg',
              suppressHeaderMenuButton: true,
              cellStyle: (params: CellClassParams<SampleTableRow, number>) => {
                if (params.value === null || params.value === undefined)
                  return null;
                const diffProportion =
                  params.data?.analysis.netWeightDifferenceProportion ?? 0;
                const highlight =
                  Math.abs(diffProportion) > NET_WEIGHT_DIFF_PROP_THRESH;
                const style: CellStyle = {
                  color: highlight ? 'red' : 'black',
                };
                return style;
              },
              cellRenderer: (
                params: CustomCellRendererProps<SampleTableRow, number>,
              ) =>
                params.value !== null && params.value !== undefined ? (
                  showGrams ? (
                    <WeightWithUnit
                      weightUnit={WeightUnit.GRAM}
                      totalInt={Math.round(params.value)}
                      styleAsErrant={false}
                    />
                  ) : (
                    formatPercentWithMinThreshold(params.value ?? 0)
                  )
                ) : (
                  ''
                ),
            },
            {
              field: showGrams
                ? 'analysis.moistureNetWeightGrams'
                : 'analysis.moistureProportion',
              headerName: 'Sample Moisture',
              headerTooltip: 'Whole Sample Moisture Content',
              columnGroupShow: 'open',
              hide: true, // user can toggle this column on in the sidebar menu
              cellDataType: 'number',
              type: 'rightAligned',
              filter: 'agNumberColumnFilter',
              enableRowGroup: false,
              aggFunc: showGrams ? 'sum' : 'avg',
              suppressHeaderMenuButton: true,
              cellRenderer: (
                params: CustomCellRendererProps<SampleTableRow, number>,
              ) =>
                params.value !== null && params.value !== undefined ? (
                  showGrams ? (
                    <WeightWithUnit
                      weightUnit={WeightUnit.GRAM}
                      totalInt={Math.round(params.value)}
                    />
                  ) : (
                    formatPercentWithMinThreshold(params.value ?? 0)
                  )
                ) : (
                  ''
                ),
            },
            ...materialClassSetSamples.driedMaterialClassIds.map(
              (materialClassId) => {
                const materialClassName =
                  materialClassSetSamples.materialClassSet.materialClassIdMap[
                    materialClassId
                  ].shortName ??
                  materialClassSetSamples.materialClassSet.materialClassIdMap[
                    materialClassId
                  ].name;
                const materialClassMoistureColumn:
                  | ColDef<SampleTableRow>
                  | ColGroupDef<SampleTableRow> = {
                  colId: materialClassId + '_moisture',
                  headerName: `${materialClassName} Moisture`,
                  headerTooltip: `${materialClassName} Moisture Content`,
                  columnGroupShow: undefined, // always show
                  cellDataType: 'number',
                  type: 'rightAligned',
                  filter: 'agNumberColumnFilter',
                  enableRowGroup: false,
                  aggFunc: showGrams ? 'sum' : 'avg',
                  suppressHeaderMenuButton: true,
                  hide: !materialClassSetSamples.anySamplesWithDriedMaterialClasses,
                  valueGetter: (
                    params: ValueGetterParams<SampleTableRow, number | null>,
                  ) =>
                    showGrams
                      ? params.data?.analysis
                          .materialClassToMoistureNetWeightGramsMap
                        ? params.data?.analysis
                            .materialClassToMoistureNetWeightGramsMap[
                            materialClassId
                          ]
                        : null
                      : params.data?.analysis
                            .materialClassToMoistureProportionMap
                        ? params.data?.analysis
                            .materialClassToMoistureProportionMap[
                            materialClassId
                          ]
                        : null,
                  cellRenderer: (
                    params: CustomCellRendererProps<SampleTableRow, number>,
                  ) =>
                    params.value !== null && params.value !== undefined ? (
                      showGrams ? (
                        <WeightWithUnit
                          weightUnit={WeightUnit.GRAM}
                          totalInt={Math.round(params.value)}
                        />
                      ) : (
                        formatPercentWithMinThreshold(params.value ?? 0)
                      )
                    ) : (
                      ''
                    ),
                };
                return materialClassMoistureColumn;
              },
            ),
          ],
        },
        {
          headerName: 'Material Class Composition',
          openByDefault: true,
          children:
            materialClassSetSamples.materialClassSet.materialClasses.map(
              (materialClass) => {
                const materialClassColumn:
                  | ColDef<SampleTableRow>
                  | ColGroupDef<SampleTableRow> = {
                  colId: materialClass.id,
                  headerName: materialClass.shortName ?? materialClass.name,
                  headerTooltip: materialClass.name,
                  cellDataType: 'number',
                  type: 'rightAligned',
                  filter: 'agNumberColumnFilter',
                  enableRowGroup: false,
                  aggFunc: showGrams ? 'sum' : 'avg',
                  suppressHeaderMenuButton: true,
                  valueGetter: (
                    params: ValueGetterParams<SampleTableRow, number>,
                  ) =>
                    showGrams
                      ? params.data?.analysis.materialClassToNetWeightGramsMap[
                          materialClass.id
                        ] ?? 0
                      : params.data?.analysis.materialClassToProportionMap[
                          materialClass.id
                        ] ?? 0,
                  cellRenderer: (
                    params: CustomCellRendererProps<SampleTableRow, number>,
                  ) =>
                    showGrams ? (
                      <WeightWithUnit
                        weightUnit={WeightUnit.GRAM}
                        totalInt={params.value ? Math.round(params.value) : 0}
                      />
                    ) : (
                      formatPercentWithMinThreshold(params.value ?? 0)
                    ),
                };
                return materialClassColumn;
              },
            ),
        },
      ],
    }),
    [
      defaultColDef,
      isRadius,
      materialClassSetSamples.anySamplesWithDriedMaterialClasses,
      materialClassSetSamples.driedMaterialClassIds,
      materialClassSetSamples.materialClassSet.materialClassIdMap,
      materialClassSetSamples.materialClassSet.materialClasses,
      showGrams,
      variant.type,
    ],
  );

  const resetFilters = useCallback(() => {
    if (!gridRef.current) {
      return;
    }
    gridRef.current.api.setFilterModel(null);
  }, []);

  const onColumnVisibleOrGroupOpened = useCallback((e: AgGridEvent) => {
    e.api.autoSizeAllColumns(true);
  }, []);

  const sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivotMode: true,
          suppressValues: true,
        },
      },
    ],
  };

  return (
    <Stack style={{ flex: '1 1 auto' }}>
      <Flex justify='flex-start' align='flex-end' gap='md'>
        {match(variant)
          .with({ type: 'allSamples' }, () => undefined)
          .with(
            { type: 'commodity' },
            ({ materialClassSetId, setMaterialClassSetId }) => (
              <MaterialClassSetSelect
                value={materialClassSetId}
                onChange={setMaterialClassSetId}
                firstSelectedByDefault
              />
            ),
          )
          .exhaustive()}
        <Button
          onClick={resetFilters}
          variant='outline'
          leftIcon={<IconFilterX />}
          color='red'
          size='md'
        >
          Reset Filters
        </Button>
        {match(variant)
          .with({ type: 'allSamples' }, ({ groupedByCommodity }) => (
            <Button
              onClick={toggleGroupByCommodity}
              variant='outline'
              leftIcon={<IconListDetails />}
              color={groupedByCommodity ? 'red' : 'teal'}
              size='md'
            >
              {groupedByCommodity
                ? 'Ungroup by Commodity'
                : 'Group by Commodity'}
            </Button>
          ))
          .with({ type: 'commodity' }, () => undefined)
          .exhaustive()}

        <ShowMaterialClassGramsToggle
          showGrams={showGrams}
          setShowGrams={setShowGrams}
        />
      </Flex>
      <div
        className={'table-container'}
        style={{
          height: match(variant)
            .with({ type: 'allSamples' }, () => '75vh')
            .with({ type: 'commodity' }, () => '20rem')
            .exhaustive(),
          width: '100%',
        }}
      >
        <AgGridReact
          ref={gridRef}
          theme={theme}
          className={'table'}
          rowData={tableRows}
          onFilterChanged={onFilterChanged}
          onRowDataUpdated={onRowDataUpdated}
          onColumnRowGroupChanged={onColumnRowGroupChanged}
          onColumnVisible={onColumnVisibleOrGroupOpened}
          onColumnGroupOpened={onColumnVisibleOrGroupOpened}
          autoSizeStrategy={{ type: 'fitCellContents', skipHeader: true }}
          tooltipShowDelay={0}
          groupDisplayType='multipleColumns'
          suppressAggFuncInHeader
          suppressColumnVirtualisation
          suppressHorizontalScroll
          alwaysShowVerticalScroll
          grandTotalRow='bottom'
          sideBar={sideBar}
          {...gridOptions}
        />
      </div>
    </Stack>
  );
}

function ShowMaterialClassGramsToggle(props: {
  showGrams: boolean;
  setShowGrams: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { showGrams, setShowGrams } = props;

  return (
    <Tooltip
      label={`toggle to show ${showGrams ? 'percent' : 'grams'}`}
      color='gray'
    >
      <Input.Wrapper label='Composition Display'>
        <Switch
          checked={showGrams}
          onChange={(e) => setShowGrams(e.currentTarget.checked)}
          color='gray'
          size='xl'
          onLabel={<Text size='lg'>g</Text>}
          offLabel={<Text size='lg'>%</Text>}
        />
      </Input.Wrapper>
    </Tooltip>
  );
}

const sampleIdColCellRenderer = (
  params: CustomCellRendererProps<SampleTableRow, number>,
) => {
  if (!params.data?.metadata.sampleId) {
    return params.value?.toString();
  }
  return (
    params.data && (
      <LinkText
        to={Router.SampleDetail({
          sampleId: params.data?.metadata.sampleId,
        })}
      >
        {params.value}
      </LinkText>
    )
  );
};

export const commodityColCellRenderer = (
  params: CustomCellRendererProps<
    SampleTableRow | SampleTableRowMetadata,
    ProductTypeDTO
  >,
) => {
  return match(params.value)
    .with({ type: 'Commodity' }, (commodity) => (
      <CommodityName commodity={commodity.commodity} />
    ))
    .with({ type: 'Intermediate' }, () => 'intermediate')
    .with({ type: 'Unknown' }, () => 'Unknown')
    .with(null, undefined, () => '')
    .exhaustive();
};

export const upstreamSourceColCellRenderer = (
  params: CustomCellRendererProps<
    SampleTableRow | SampleTableRowMetadata,
    InternalMaterialSourceDTO
  >,
) => {
  if (params.value) {
    return <InternalMaterialSourceName internalMaterialSource={params.value} />;
  }
  return '-';
};

export const upstreamSourceCommodityColCellRenderer = (
  params: CustomCellRendererProps<
    SampleTableRow | SampleTableRowMetadata,
    CommodityDTO
  >,
) => {
  if (params.value) {
    return <CommodityName commodity={params.value} />;
  }
  return '-';
};

export const employedRecoveryStrategyColCellRenderer = (
  params: CustomCellRendererProps<
    SampleTableRow | SampleTableRowMetadata,
    RecoveryStrategyDTO
  >,
) => {
  if (params.value) {
    return <RecoveryStrategyName recoveryStrategy={params.value} />;
  }
  return '-';
};

export const producingProcessColCellRenderer = (
  params: CustomCellRendererProps<
    SampleTableRow | SampleTableRowMetadata,
    BasicProcessDTO
  >,
) => {
  if (params.value) {
    return <ProcessName process={params.value} />;
  }
  return '-';
};

export const exportDestinationColCellRenderer = (
  params: CustomCellRendererProps<
    SampleTableRow | SampleTableRowMetadata,
    InternalMaterialSinkDTO
  >,
) => {
  if (params.value) {
    return <InternalMaterialSinkName internalMaterialSink={params.value} />;
  }
  return '-';
};
