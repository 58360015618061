import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Checkbox,
  Flex,
  FocusTrap,
  Group,
  Loader,
  Modal,
  NumberInput,
  Progress,
  RingProgress,
  Select,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconRefresh } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { match, P } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { CommodityName } from '../Commodity/CommodityName';
import { EditIcon, InternalMaterialSourceIcon } from '../Icons';
import { InternalMaterialSourceIdName } from '../InternalMaterialSource/InternalMaterialSourceName';
import { InternallySourcedMaterialIdName } from '../InternallySourcedMaterial/InternallySourcedMaterialIdName';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { BinaryConfusionMatrixStats } from '../RecoveryGoal/BinaryConfusionMatrixStats';
import {
  RecoveryGoalExcludedIcon,
  RecoveryGoalIncludedIcon,
} from '../RecoveryGoal/RecoveryGoalIcons';
import { RecoveryGoalName } from '../RecoveryGoal/RecoveryGoalName';
import { RecoveryStrategyName } from '../RecoveryStrategy/RecoveryStategyName';
import { CommoditySpreadMaterialNodeBody } from '../RecoveryTree/CommoditySpreadRecoveryTreeNodes';
import { TableEmptyBasicContent } from '../Table/TableEmptyBasicContent';
import { useCommodities } from '../api/commodity';
import {
  useInternalMaterialSourceComposition,
  useInternalMaterialSources,
} from '../api/internalMaterialSource';
import { usePatchMaterialClassSetComposition } from '../api/materialClassSetComposition';
import { useRecoveryGoals } from '../api/recoveryGoal';
import {
  usePatchRecoveryStrategySimulation,
  useRecoveryStrategySimulation,
} from '../api/recoveryStrategySimulation';
import { LabeledValue } from '../common';
import { EChart } from '../echarts/BareEChart';
import { useCategoricalColors } from '../lib/colors';
import {
  CommoditySpreadMaterialNodeDTO,
  CommoditySpreadRecoveryGoalNodeDTO,
  InternallySourcedMaterialCompositionDTO,
  InternalMaterialSourceBulkCompositionAnalysisResultDTO,
  InternalMaterialSourceDTO,
  InternalMaterialSourceId,
  MaterialClassSetDTO,
} from '../rest-client';
import { Router } from '../router';
import { formatPercentage } from '../util/percentages';
import { getWeightFromNetWeight } from '../util/weightFromNetWeight';
import { DeleteRecoveryStrategySimulationButton } from './DeleteRecoveryStrategySimulationButton';
import { MaterialClassLegendItem } from './MaterialClassLegendItem';
import {
  InputCompositionInternalMaterialSourceVariant,
  InputCompositionSource,
  RecoveryStrategySimulationCompositionCtxProvider,
  useRecoveryStrategySimulationCompositionCtx,
} from './RecoveryStrategySimulationCompositionContext';
import {
  RecoveryStrategySimulationCtxProvider,
  useRecoveryStrategySimulationCtx,
} from './RecoveryStrategySimulationContext';
import classes from './RecoveryStrategySimulationDetailPage.module.css';
import { RecoveryStrategySimulationDiagram } from './RecoveryStrategySimulationDiagram';
import { RecoveryStrategySimulationTree } from './RecoveryStrategySimulationTree';

export default function RecoveryStrategySimulationDetailPage(props: {
  simulationId: string;
}) {
  const { simulationId } = props;

  const { data: simulation } = useRecoveryStrategySimulation(simulationId);

  const [selectedMaterialClassId, setSelectedMaterialClassId] = useState<
    string | null
  >(null);
  const [selectedRecoveryGoalNode, setSelectedRecoveryGoalNode] =
    useState<CommoditySpreadRecoveryGoalNodeDTO | null>(null);
  const [selectedMaterialNode, setSelectedMaterialNode] =
    useState<CommoditySpreadMaterialNodeDTO | null>(null);
  const [feedTotal, setFeedTotal] = useState<number | null>(null);

  if (!simulation) {
    return (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    );
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          title: 'Recovery Strategies',
          routeName: Router.RecoveryStrategyList(),
        },
        {
          title: simulation.recoveryStrategy.name,
          routeName: Router.RecoveryStrategyDetail({
            recoveryStrategyId: simulation.recoveryStrategy.id,
          }),
        },
        simulation.name,
      ]}
      titleRight={
        <DeleteRecoveryStrategySimulationButton simulationId={simulationId} />
      }
    >
      <RecoveryStrategySimulationCtxProvider
        simulation={simulation}
        selectedMaterialClassId={selectedMaterialClassId}
        setSelectedMaterialClassId={setSelectedMaterialClassId}
        selectedRecoveryGoalNode={selectedRecoveryGoalNode}
        setSelectedRecoveryGoaNode={setSelectedRecoveryGoalNode}
        selectedMaterialNode={selectedMaterialNode}
        setSelectedMaterialNode={setSelectedMaterialNode}
        feedTotal={feedTotal}
        setFeedTotal={setFeedTotal}
      >
        <RecoveryStrategySimulationDetailsSection />
        <AppPage.Section>
          <Flex justify='space-between' align='center'>
            <Title order={3}>Simulation Parameters</Title>
          </Flex>
          <SimulationParametersInput />
          <Flex gap={'md'} justify={'start'} align={'end'}>
            <NumberInput
              size='md'
              mt='lg'
              w='fit-content'
              label='Feed Total'
              value={feedTotal ?? ''}
              onChange={(v) => setFeedTotal(v === '' ? null : v)}
            />
            <PopulateFromInternalMaterialSourceModal />
          </Flex>
        </AppPage.Section>
        <Stack spacing='xs'>
          <Title order={2} ta='center'>
            Mass Flow
          </Title>
          <RecoveryStrategySimulationDiagram />
        </Stack>
        <Stack>
          <SelectedRecoveryGoalMetrics />
          <SelectedOutputComposition />
        </Stack>
      </RecoveryStrategySimulationCtxProvider>
    </AppPage>
  );
}

function RecoveryStrategySimulationDetailsSection() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const [editing, setEditing] = useState<boolean>(false);

  return (
    <AppPage.Section>
      <Stack>
        <Stack spacing='xs'>
          <Title order={3}>Simulation Details</Title>
          <Group>
            <LabeledValue label='Name'>{simulation.name}</LabeledValue>
            <LabeledValue label='Created at'>
              {dayjs.utc(simulation.insertedAt).format('LLL')}
            </LabeledValue>
            <LabeledValue label='Recovery Strategy'>
              <RecoveryStrategyName
                recoveryStrategy={simulation.recoveryStrategy}
              />
            </LabeledValue>
            <LabeledValue label='Sample Analysis Material Class Set'>
              <MaterialClassSetName
                materialClassSet={simulation.materialClassSet}
              />
            </LabeledValue>
            <CompositionSourceInfoText />
            <CommoditySourceInfoEditor />
          </Group>
        </Stack>
        <Stack spacing='xs'>
          <Group>
            <Title order={4}>Recovery Strategy Behavior Tree</Title>
            <Button
              leftIcon={<IconEdit />}
              style={{ cursor: 'pointer' }}
              onClick={() => setEditing(true)}
              disabled={editing}
            >
              Edit
            </Button>
          </Group>
          {simulation.rootMaterialNode !== null ? (
            <RecoveryStrategySimulationTree
              rootMaterialNode={simulation.rootMaterialNode}
              simulationId={simulation.id}
              editing={editing}
              setEditing={setEditing}
            />
          ) : (
            <Alert title='No Recovery Tree' color='blue'>
              There has either been no recovery sequence configured for this
              recovery strategy or multiple incompatible sequences configured.
              Please specify a recovery sequence or compatible sequences.
            </Alert>
          )}
        </Stack>
      </Stack>
    </AppPage.Section>
  );
}

function SimulationParametersInput() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const colors = useCategoricalColors();

  const rgQuery = useRecoveryGoals();
  const recoveryGoals =
    rgQuery.data && new Map(rgQuery.data.map((rg) => [rg.id, rg]));
  const recoveryGoalIdSet = new Set<string>();
  simulation.recoveryStrategy.paths
    .flatMap((p) => p.steps.map((s) => s.recoveryGoalId))
    .forEach((recoveryGoalId) => {
      recoveryGoalIdSet.add(recoveryGoalId);
    });

  const recoveryGoalIds = [...recoveryGoalIdSet];

  // TODO: can we delete this, it should always be 1
  const inputComposition =
    simulation.rootMaterialNode?.materialClassComposition;
  const inputCompositionTotal =
    inputComposition !== undefined
      ? Object.values(inputComposition).reduce((t, v) => t + v, 0)
      : 1;

  // TODO(2339): highlight row and column with color?

  return (
    <Table>
      <thead>
        <tr>
          <th>Material Class</th>
          <th style={{ textAlign: 'end' }}>Composition</th>
          <th>Feedstock %</th>

          {recoveryGoalIds.map((rgId) => (
            <th
              key={rgId}
              onClick={() => {
                // TODO(2339): Change the recovery goal
              }}
            >
              {recoveryGoals?.get(rgId)?.name ?? ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {simulation.materialClassSet.materialClasses.map((materialClass, i) => {
          const materialClassProportion =
            inputComposition !== undefined
              ? inputComposition[materialClass.id]
              : 0;

          return (
            <tr key={materialClass.id}>
              <td>
                <MaterialClassLegendItem
                  materialClass={materialClass}
                  color={colors[i]}
                />
              </td>

              <td>
                <InputCompositionInput
                  key={materialClass.id}
                  materialClassId={materialClass.id}
                />
              </td>

              <td>
                <Progress
                  h='100%'
                  radius='xs'
                  color={colors[i]}
                  value={
                    (100 * materialClassProportion) / inputCompositionTotal
                  }
                />
                {materialClassProportion >= 0
                  ? (100 * materialClassProportion).toFixed(2) + '%'
                  : '?'}
              </td>

              {recoveryGoalIds.map((rgId) => {
                const rg = recoveryGoals?.get(rgId);
                const rgClasses = rg
                  ? new Set(rg.materialClasses.map((mc) => mc.id))
                  : undefined;
                const classIncluded = rgClasses?.has(materialClass.id);
                return (
                  <td
                    key={`${rgId}-${materialClass.id}`}
                    style={{ textAlign: 'right' }}
                  >
                    <Group position='center' noWrap spacing={0}>
                      {match(classIncluded)
                        .with(undefined, () => <Loader size='xs' />)
                        .with(true, () => (
                          <RecoveryGoalIncludedIcon
                            size='1.4em'
                            color='green'
                          />
                        ))
                        .with(false, () => (
                          <RecoveryGoalExcludedIcon
                            size='1.4em'
                            color='orange'
                          />
                        ))
                        .exhaustive()}
                      <RingProgress
                        size={25}
                        thickness={5}
                        sections={[
                          {
                            value:
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] * 100,
                            color: 'teal',
                          },
                          {
                            value:
                              100 -
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] *
                                100,
                            color: 'red',
                          },
                        ]}
                      />
                      <RecoveryGoalAccuracyInput
                        recoveryGoalId={rgId}
                        materialClassId={materialClass.id}
                      />
                    </Group>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function InputCompositionInput(props: { materialClassId: string }) {
  const { materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const patchMutation = usePatchMaterialClassSetComposition();
  const [value, setValue] = useState<number | undefined>(undefined);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const proportions =
      simulation.rootMaterialNode !== null
        ? new Map(
            Object.entries(
              simulation.rootMaterialNode.materialClassComposition,
            ),
          )
        : new Map(
            simulation.materialClassSet.materialClasses.map((mc) => [mc.id, 0]),
          );

    setValue(proportions.get(materialClassId));
  }, [
    materialClassId,
    simulation.materialClassSet.materialClasses,
    simulation.rootMaterialNode,
    simulation.rootMaterialNode?.materialClassComposition,
  ]);

  const mutate = useCallback(() => {
    if (value === undefined) return;
    return patchMutation.mutate(
      {
        id:
          simulation.rootMaterialNode?.index.toString() ?? 'noRootMaterialNode',
        patch: { materialClassesProportions: { [materialClassId]: value } },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [
    value,
    patchMutation,
    simulation.rootMaterialNode?.index,
    materialClassId,
  ]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setValue(
            simulation.rootMaterialNode?.materialClassComposition[
              materialClassId
            ],
          );
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <FocusTrap>
      <NumberInput
        data-autofocus
        hideControls
        disabled={patchMutation.isLoading}
        rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
        w='8ch'
        value={value ?? ''}
        onChange={(v) => {
          if (v === '') {
            setValue(undefined);
          } else {
            setValue(v);
          }
        }}
        onBlur={() => mutate()}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            mutate();
          }
        }}
        precision={3}
      />
    </FocusTrap>
  ) : (
    <Text ta='right' onClick={() => setEditing(true)}>
      {value?.toFixed(3) ?? '-'}
    </Text>
  );
}

function RecoveryGoalAccuracyInput(props: {
  recoveryGoalId: string;
  materialClassId: string;
}) {
  const { recoveryGoalId, materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const [editing, setEditing] = useState(false);
  const patchMutation = usePatchRecoveryStrategySimulation();

  const currentAccuracy =
    simulation.recoveryGoalAccuracies[recoveryGoalId][materialClassId];

  const [accuracy, setAccuracy] = useState(currentAccuracy);

  const mutate = useCallback(() => {
    patchMutation.mutate(
      {
        simulationId: simulation.id,
        patch: {
          recoveryGoalAccuracies: {
            [recoveryGoalId]: { [materialClassId]: accuracy },
          },
        },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [
    patchMutation,
    simulation.id,
    recoveryGoalId,
    materialClassId,
    accuracy,
    setEditing,
  ]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setAccuracy(currentAccuracy);
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <NumberInput
      disabled={patchMutation.isLoading}
      rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
      ref={(e) => e?.focus()}
      w='8ch'
      step={0.01}
      precision={2}
      hideControls
      value={accuracy * 100}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          mutate();
        }
      }}
      onChange={(v) => {
        if (v === '') {
          setAccuracy(0);
        } else {
          setAccuracy(v / 100);
        }
      }}
      onBlur={() => mutate()}
    />
  ) : (
    <Text weight={500} onClick={() => setEditing(true)}>
      {(accuracy * 100).toFixed(2)}%
    </Text>
  );
}

function SelectedOutputComposition() {
  const { simulation, selectedMaterialNode, feedTotal } =
    useRecoveryStrategySimulationCtx();

  const colors = useCategoricalColors();

  const valueFormatter = useCallback(
    (val: number | null) => (val === null ? '?' : val.toFixed(2)),
    [],
  );

  if (simulation.rootMaterialNode === null) {
    return null;
  }

  if (selectedMaterialNode === null) {
    return null;
  }

  const selectedMaterialNodeComposition =
    selectedMaterialNode.materialClassComposition;

  return (
    <AppPage.Section>
      <Flex>
        <Stack>{CommoditySpreadMaterialNodeBody(selectedMaterialNode)}</Stack>

        <EChart
          h={500}
          w='100%'
          option={{
            grid: { containLabel: true, top: 0, bottom: 0 },
            tooltip: {
              valueFormatter: feedTotal ? valueFormatter : formatPercentage,
            },
            yAxis: {
              type: 'category',
              data: simulation.materialClassSet.materialClasses
                .map((mc) => mc.name)
                .reverse(),
            },
            xAxis: {
              type: 'value',
              axisLabel: {
                formatter: feedTotal ? undefined : formatPercentage,
              },
            },
            series: [
              {
                type: 'bar',
                data: simulation.materialClassSet.materialClasses
                  .map((mc, i) => ({
                    value: feedTotal
                      ? feedTotal * selectedMaterialNodeComposition[mc.id]
                      : selectedMaterialNodeComposition[mc.id],
                    itemStyle: {
                      color: colors[i],
                    },
                  }))
                  .reverse(),
              },
            ],
          }}
        />
      </Flex>
    </AppPage.Section>
  );
}

function SelectedRecoveryGoalMetrics() {
  const { simulation, selectedRecoveryGoalNode } =
    useRecoveryStrategySimulationCtx();

  if (simulation.rootMaterialNode === null) {
    return null;
  }

  if (selectedRecoveryGoalNode === null) {
    return null;
  }

  return (
    <>
      <Title order={3}>
        <RecoveryGoalName
          recoveryGoal={selectedRecoveryGoalNode.recoveryGoal}
          noLink
        />{' '}
        Recovery Performance
      </Title>
      <Group position='apart'>
        <BinaryConfusionMatrixStats
          matrix={selectedRecoveryGoalNode.binaryConfusionMatrix}
        />
      </Group>
    </>
  );
}

function CommoditySourceInfoEditor() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <LabeledValue label='Input Commodity'>
      <Modal
        opened={opened}
        onClose={close}
        title='Commodity Association'
        centered
        size={'xl'}
      >
        <Stack>
          <Text fw='700' mb='md'>
            Select an input commodity to associate with this simulation
          </Text>
          <CommoditySelector onSelect={close} />
        </Stack>
      </Modal>

      <Flex justify='space-between' align={'center'} gap='lg'>
        <Text>
          {simulation.rootMaterialNode?.commodity ? (
            <CommodityName commodity={simulation.rootMaterialNode.commodity} />
          ) : (
            'Not Specified'
          )}
        </Text>
        <ActionIcon onClick={open} variant='subtle'>
          <EditIcon />
        </ActionIcon>
      </Flex>
    </LabeledValue>
  );
}

function CommoditySelector(props: { onSelect?: () => void }) {
  const { onSelect } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();
  const [selectedCommodityId, setSelectedCommodityId] = useState<
    string | undefined
  >(undefined);
  const commoditiesQuery = useCommodities();
  const patchRecoverySimulationMutation = usePatchRecoveryStrategySimulation();

  const mutateRecoverySimulationCompositionSource = () => {
    if (selectedCommodityId === undefined) {
      throw new Error('no selected commodity');
    }
    const patchMutationArgs = {
      simulationId: simulation.id,
      patch: {
        inputCommodityId: selectedCommodityId,
      },
    };
    patchRecoverySimulationMutation.mutate(patchMutationArgs, {
      onSuccess() {
        onSelect?.();
      },
    });
  };

  if (patchRecoverySimulationMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          patchRecoverySimulationMutation.reset();
        }}
      >
        <IconRefresh /> Mutation Failed - Reset
      </Button>
    );
  }

  if (commoditiesQuery.data) {
    return (
      <Stack align='end' m='lg'>
        <Table highlightOnHover={true} p='lg'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {commoditiesQuery.data.map((c) => {
              return (
                <tr
                  className={`${classes.pointer} ${selectedCommodityId === c.id ? classes.selected : ''}`}
                  key={c.id}
                  onClick={() => setSelectedCommodityId(c.id)}
                >
                  <td>{c.name}</td>
                  <td>{c.description ?? '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          w='10rem'
          onClick={() => {
            mutateRecoverySimulationCompositionSource();
          }}
          disabled={!selectedCommodityId}
          loading={patchRecoverySimulationMutation.isLoading}
        >
          Apply
        </Button>
      </Stack>
    );
  }

  if (commoditiesQuery.isLoading) {
    return (
      <Skeleton h='10rem'>
        <></>
      </Skeleton>
    );
  }

  if (commoditiesQuery.isLoadingError) {
    return (
      <Flex align={'center'} justify={'center'} w='100%' h='100%'>
        <Alert title='Uh oh...' color='red' miw={'50%'}>
          Failed to load available commodities.
        </Alert>
      </Flex>
    );
  }
}

function CompositionSourceInfoText() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  return (
    <LabeledValue label='Composition Source'>
      {simulation.compositionSourceInternalMaterialSource !== null ? (
        <Flex gap={4}>
          {`${simulation.compositionSourceInternalMaterialSource.isWeighted ? 'Weighted' : 'Unweighted'}`}
          <InternalMaterialSourceIdName
            internalMaterialSourceId={
              simulation.compositionSourceInternalMaterialSource
                .internalMaterialSourceId
            }
          />
        </Flex>
      ) : simulation.compositionSourceInternallySourcedMaterialId !== null ? (
        <InternallySourcedMaterialIdName
          internallySourcedMaterialId={
            simulation.compositionSourceInternallySourcedMaterialId
          }
        />
      ) : (
        `Manual Entry`
      )}
    </LabeledValue>
  );
}

interface CompositionSelectorTableStatusTextProps {
  isEmpty: boolean;
  hasMaterialSet: boolean;
}

function CompositionSelectorTableStatusText(
  props: CompositionSelectorTableStatusTextProps,
) {
  const { isEmpty, hasMaterialSet } = props;
  const dataInfoMessage =
    "The data below is the set of observed or computed compositions from material sets matching the material class of this simulation. Select an entry and click 'Apply' to populate the simulation.";
  const nothingMessage = !hasMaterialSet
    ? isEmpty
      ? 'No Compositions Found for Material Source'
      : 'Material Class Set for Simulation does not match that of found compositions.'
    : '';

  return isEmpty || !hasMaterialSet ? (
    <Flex
      justify={'center'}
      align={'center'}
      miw={'100%'}
      p={'md'}
      mih={'100%'}
      mt={'xl'}
    >
      <TableEmptyBasicContent>{nothingMessage}</TableEmptyBasicContent>
    </Flex>
  ) : (
    <Alert title='Compositional Data Breakdown' color='blue' miw={'100%'}>
      {dataInfoMessage}
    </Alert>
  );
}

interface InternallySourcedCompositionSelectorTableProps {
  internallySourcedMaterialCompositions: InternallySourcedMaterialCompositionDTO[];
  materialClassSet: MaterialClassSetDTO;
}

function InternallySourcedCompositionSelectorTable(
  props: InternallySourcedCompositionSelectorTableProps,
) {
  const { materialClassSet, internallySourcedMaterialCompositions } = props;

  const {
    setSelectedMaterialClassSetComposition,
    selectedInputCompositionSource: selectedSourceComposition,
    setSelectedInputCompositionSource,
  } = useRecoveryStrategySimulationCompositionCtx();

  return (
    <Table w='100%' highlightOnHover={true}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start', width: '10rem' }}>Name</th>
          {materialClassSet.materialClasses.map((materialClass) => {
            return <th key={materialClass.id}>{materialClass.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {internallySourcedMaterialCompositions.map(
          (composition: InternallySourcedMaterialCompositionDTO) => {
            const compPercentages =
              composition.materialClassSetCompositions[materialClassSet.id];
            const compValues = Object.fromEntries(
              Object.entries(compPercentages).map(([key, percentage]) => {
                return [
                  key,
                  percentage *
                    (composition.netWeight === null
                      ? 100
                      : getWeightFromNetWeight(composition.netWeight)),
                ];
              }),
            );
            return (
              <tr
                key={composition.internallySourcedMaterial.id}
                className={`${classes.pointer} ${match(
                  selectedSourceComposition,
                )
                  .with(
                    {
                      internallySourcedMaterialId:
                        composition.internallySourcedMaterial.id,
                    },
                    () => classes.selected,
                  )
                  .otherwise(() => '')}`}
                onClick={() => {
                  setSelectedMaterialClassSetComposition(compValues);
                  setSelectedInputCompositionSource({
                    internallySourcedMaterialId:
                      composition.internallySourcedMaterial.id,
                  });
                }}
              >
                <td style={{ maxWidth: '10%' }}>
                  {composition.internallySourcedMaterial.name}
                </td>
                {materialClassSet.materialClasses.map((materialClass) => {
                  return (
                    <td key={materialClass.id}>
                      {Number(compPercentages[materialClass.id]).toFixed(2)}
                    </td>
                  );
                })}
              </tr>
            );
          },
        )}
      </tbody>
    </Table>
  );
}

interface AggregateCompositionSourceSelectorTableProps {
  weightedCompositions: Record<string, Record<string, number>>;
  unweightedCompositions: Record<string, Record<string, number>>;
  materialClassSet: MaterialClassSetDTO;
}

function AggregateCompositionSourceSelectorTable(
  props: AggregateCompositionSourceSelectorTableProps,
) {
  const { weightedCompositions, unweightedCompositions, materialClassSet } =
    props;
  const {
    selectedInputCompositionSource: selectedSourceComposition,
    setSelectedInputCompositionSource,
    setSelectedMaterialClassSetComposition,
  } = useRecoveryStrategySimulationCompositionCtx();

  const hasWeightedComp = materialClassSet.id in weightedCompositions;
  const hasUnweightedComp = materialClassSet.id in unweightedCompositions;

  return (
    <Table w='100%' highlightOnHover={true}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start', width: '10rem' }}>Name</th>
          {materialClassSet.materialClasses.map((materialClass) => {
            return <th key={materialClass.id}>{materialClass.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {hasUnweightedComp && (
          <tr
            className={`${classes.pointer} ${match(selectedSourceComposition)
              .with(
                InputCompositionInternalMaterialSourceVariant.unweighted,
                () => classes.selected,
              )
              .otherwise(() => '')}`}
            onClick={() => {
              const percentages = unweightedCompositions[materialClassSet.id];
              const compVals = Object.fromEntries(
                Object.entries(percentages).map(([key, value]) => [
                  key,
                  value * 100,
                ]),
              );
              setSelectedMaterialClassSetComposition(compVals);
              setSelectedInputCompositionSource(
                InputCompositionInternalMaterialSourceVariant.unweighted,
              );
            }}
          >
            <td>Unweighted Composition</td>
            {materialClassSet.materialClasses.map((materialClass) => {
              const compVals = unweightedCompositions[materialClassSet.id];
              return (
                <td key={materialClass.id}>
                  {Number(compVals[materialClass.id]).toFixed(2)}
                </td>
              );
            })}
          </tr>
        )}
        {hasWeightedComp && (
          <tr
            className={`${classes.pointer} ${match(selectedSourceComposition)
              .with(
                InputCompositionInternalMaterialSourceVariant.weighted,
                () => classes.selected,
              )
              .otherwise(() => '')}`}
            onClick={() => {
              const percentages = weightedCompositions[materialClassSet.id];
              const compVals = Object.fromEntries(
                Object.entries(percentages).map(([key, value]) => [
                  key,
                  value * 100,
                ]),
              );
              setSelectedMaterialClassSetComposition(compVals);
              setSelectedInputCompositionSource(
                InputCompositionInternalMaterialSourceVariant.weighted,
              );
            }}
          >
            <td>Weighted Composition</td>
            {materialClassSet.materialClasses.map((materialClass) => {
              const compVals = weightedCompositions[materialClassSet.id];
              return (
                <td key={materialClass.id}>
                  {Number(compVals[materialClass.id]).toFixed(2)}
                </td>
              );
            })}
          </tr>
        )}
      </tbody>
    </Table>
  );
}

interface CompositionSourceAnalysisProps {
  imsId: string | null;
  imsCommodityId: string | undefined;
  interval: [string | undefined, string | undefined];
  analysis: InternalMaterialSourceBulkCompositionAnalysisResultDTO;
}

function CompositionSelectorTable(
  props: ModalProps & CompositionSourceAnalysisProps,
) {
  const { onClose, imsId, imsCommodityId, interval, analysis } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const emptyInputComposition: Record<string, number> = {};
  simulation.materialClassSet.materialClasses.map(
    (mc) => (emptyInputComposition[mc.id] = 0),
  );
  const [
    selectedMaterialClassSetComposition,
    setSelectedMaterialClassSetComposition,
  ] = useState<Record<string, number>>(
    simulation.rootMaterialNode?.materialClassComposition ??
      emptyInputComposition,
  );
  const [selectedInputCompositionSource, setSelectedInputCompositionSource] =
    useState<InputCompositionSource>({
      materialClassSetCompositionId:
        simulation.compositionSourceMaterialClassSetCompositionId,
    });
  const [associateCommodity, setAssociateCommodity] = useState<boolean>(true);

  const patchRecoverySimulationMutation = usePatchRecoveryStrategySimulation();
  const mutateRecoverySimulationCompositionSource = useCallback(() => {
    const patchMutationArgs = match(selectedInputCompositionSource)
      .with(null, { materialClassSetCompositionId: P.string }, () => {
        return null;
      })
      .with(InputCompositionInternalMaterialSourceVariant.weighted, () => {
        return {
          simulationId: simulation.id,
          patch: {
            aggregateCompositionSource: {
              internalMaterialSourceId: imsId ?? '',
              intervalStart: interval[0] ?? null,
              intervalEnd: interval[1] ?? null,
              isWeighted: true,
            },
            materialClassSetComposition: {
              materialClassesProportions: selectedMaterialClassSetComposition,
            },
            ...(associateCommodity && { inputCommodityId: imsCommodityId }),
          },
        };
      })
      .with(InputCompositionInternalMaterialSourceVariant.unweighted, () => {
        return {
          simulationId: simulation.id,
          patch: {
            aggregateCompositionSource: {
              internalMaterialSourceId: imsId ?? '',
              intervalStart: interval[0] ?? null,
              intervalEnd: interval[1] ?? null,
              isWeighted: false,
            },
            materialClassSetComposition: {
              materialClassesProportions: selectedMaterialClassSetComposition,
            },
            ...(associateCommodity && { inputCommodityId: imsCommodityId }),
          },
        };
      })
      .with(
        { internallySourcedMaterialId: P.string },
        ({ internallySourcedMaterialId }) => {
          return {
            simulationId: simulation.id,
            patch: {
              internallySourcedMaterialCompositionSource: {
                internallySourcedMaterialId: internallySourcedMaterialId,
              },
              materialClassSetComposition: {
                materialClassesProportions: selectedMaterialClassSetComposition,
              },
              ...(associateCommodity && { inputCommodityId: imsCommodityId }),
            },
          };
        },
      )
      .exhaustive();
    if (patchMutationArgs !== null)
      patchRecoverySimulationMutation.mutate(patchMutationArgs);
  }, [
    selectedInputCompositionSource,
    patchRecoverySimulationMutation,
    simulation.id,
    imsId,
    imsCommodityId,
    associateCommodity,
    interval,
    selectedMaterialClassSetComposition,
  ]);

  if (analysis === null) return <></>;

  const isEmpty = analysis.internallySourcedMaterialCompositions.length === 0;
  const hasMaterialSet =
    simulation.materialClassSet.id in analysis.materialClassSets;

  const disableApplyButton =
    patchRecoverySimulationMutation.isLoading ||
    match(selectedInputCompositionSource)
      .with(
        {
          materialClassSetCompositionId:
            simulation.compositionSourceMaterialClassSetCompositionId,
        },
        () => true,
      )
      .otherwise(() => false);
  return (
    <Stack justify={'end'} h='100%'>
      <RecoveryStrategySimulationCompositionCtxProvider
        selectedMaterialClassSetComposition={
          selectedMaterialClassSetComposition
        }
        setSelectedMaterialClassSetComposition={
          setSelectedMaterialClassSetComposition
        }
        selectedInputCompositionSource={selectedInputCompositionSource}
        setSelectedInputCompositionSource={setSelectedInputCompositionSource}
      >
        <CompositionSelectorTableStatusText
          isEmpty={isEmpty}
          hasMaterialSet={hasMaterialSet}
        />
        {hasMaterialSet && (
          <InternallySourcedCompositionSelectorTable
            internallySourcedMaterialCompositions={
              analysis.internallySourcedMaterialCompositions
            }
            materialClassSet={simulation.materialClassSet}
          />
        )}
        {hasMaterialSet && (
          <AggregateCompositionSourceSelectorTable
            weightedCompositions={analysis.weightedCompositions}
            unweightedCompositions={analysis.unweightedCompositions}
            materialClassSet={simulation.materialClassSet}
          />
        )}
        <Flex align={'center'} justify={'end'} w={'100%'}>
          {patchRecoverySimulationMutation.isError ? (
            <Button
              miw='100%'
              color='red'
              onClick={() => {
                patchRecoverySimulationMutation.reset();
                setSelectedMaterialClassSetComposition(
                  simulation.rootMaterialNode?.materialClassComposition ??
                    emptyInputComposition,
                );
                setSelectedInputCompositionSource(
                  selectedInputCompositionSource,
                );
              }}
            >
              <IconRefresh />
              <Text>An Error Occurred - Click Here to Reset</Text>
            </Button>
          ) : (
            !isEmpty && (
              <Flex justify={'between'} gap={'lg'} align={'center'}>
                <Checkbox
                  checked={associateCommodity}
                  onChange={(e) => setAssociateCommodity(e.target.checked)}
                  label={'Inherit Commodity'}
                  labelPosition='left'
                />
                <Button
                  onClick={() => {
                    mutateRecoverySimulationCompositionSource();
                    onClose?.();
                  }}
                  disabled={disableApplyButton}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      mutateRecoverySimulationCompositionSource();
                      onClose?.();
                    }
                  }}
                >
                  Apply
                </Button>
              </Flex>
            )
          )}
        </Flex>
      </RecoveryStrategySimulationCompositionCtxProvider>
    </Stack>
  );
}

interface ModalProps {
  onOpen?: () => void;
  onClose?: () => void;
}

function PopulateFromInternalMaterialSourceWidget(props: ModalProps) {
  const [selectedImsId, setSelectedImsId] =
    useState<InternalMaterialSourceId | null>(null);
  const [interval, setInterval] = useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);

  const selectionMsg = 'No Material Source Selected';

  const imsQuery = useInternalMaterialSources();

  const imsCompositionQuery = useInternalMaterialSourceComposition({
    internalMaterialSourceId: selectedImsId,
    intervalStart: interval[0],
    intervalEnd: interval[1],
  });

  if (imsQuery.data) {
    return (
      <Stack miw={'100%'}>
        <Flex
          justify={'space-between'}
          align={'center'}
          miw={'100%'}
          mih={'100%'}
        >
          <Select
            size={'md'}
            mt='lg'
            miw={'49%'}
            label='Get Composition From Material Source'
            value={selectedImsId ?? ''}
            onChange={(ims: string) => setSelectedImsId(ims)}
            data={
              imsQuery.data?.map((f) => ({
                value: f.id,
                label: f.name,
              })) ?? []
            }
            disabled={imsQuery.isLoading}
            dropdownPosition={'bottom'}
          />
          <DatePickerInput
            size={'md'}
            mt={'lg'}
            miw={'49%'}
            label='Date Range for Source Composition'
            type='range'
            onChange={(val: DatesRangeValue) => {
              setInterval([val[0]?.toISOString(), val[1]?.toISOString()]);
            }}
          />
        </Flex>
        <Skeleton
          visible={imsCompositionQuery.isLoading && selectedImsId !== null}
          mih='20rem'
          mt='lg'
        >
          {imsCompositionQuery.data ? (
            <CompositionSelectorTable
              {...props}
              imsId={selectedImsId}
              imsCommodityId={
                imsQuery.data.find(
                  (s: InternalMaterialSourceDTO) => s.id === selectedImsId,
                )?.commodityId ?? undefined
              }
              interval={interval}
              analysis={imsCompositionQuery.data}
            />
          ) : (
            <Stack justify='center' align='center' h='100%'>
              <Alert
                title='Select a Material Source'
                color='blue'
                miw={'100%'}
                mb={'xl'}
              >
                In order to autopopulate material composition you must select a
                material source.
              </Alert>
              <TableEmptyBasicContent>{selectionMsg}</TableEmptyBasicContent>
            </Stack>
          )}
        </Skeleton>
      </Stack>
    );
  }

  if (imsQuery.isLoading) {
    return <Skeleton mih='30rem' w='100%'></Skeleton>;
  }

  if (imsQuery.error || imsQuery.isLoadingError) {
    return (
      <Stack h='30rem'>
        <Flex align={'center'} justify={'center'} w='100%' h='100%'>
          <Alert title='Uh oh...' color='red' miw={'50%'}>
            Failed to load Internal Material Sources. Check your network
            connectivity.
          </Alert>
        </Flex>
      </Stack>
    );
  }

  if (imsCompositionQuery.isLoadingError || imsCompositionQuery.isError) {
    return (
      <Stack h='30rem'>
        <Flex align={'center'} justify={'center'} w='100%' h='100%'>
          <Alert title='Uh oh...' color='red' miw={'50%'}>
            Failed to load composition for internal material sources. Check your
            network connectivity.
          </Alert>
        </Flex>
      </Stack>
    );
  }
}

function PopulateFromInternalMaterialSourceModal() {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title='Composition Association'
        centered
        size='100%'
      >
        <Stack mih='100%'>
          <Text fw='700' mb='md'>
            Associate a Material Source to automatically configure your material
            composition.
          </Text>
          <PopulateFromInternalMaterialSourceWidget
            onClose={close}
            onOpen={open}
          />
        </Stack>
      </Modal>

      <Button
        leftIcon={<InternalMaterialSourceIcon />}
        onClick={open}
        w='fit-content'
        size='md'
      >
        Populate Composition from Material Source
      </Button>
    </>
  );
}
