import { MantineColor, Text } from '@mantine/core';
import { useMaterialClassSet } from '../api/materialClassSet';
import { LinkText } from '../Link';
import { MaterialClassSetDTO, MaterialClassSetId } from '../rest-client';
import { Router } from '../router';

export type MaterialClassSetNameProps = {
  materialClassSet: MaterialClassSetDTO;
  noLink?: boolean;
  color?: 'dimmed' | MantineColor;
};

export function MaterialClassSetName(props: MaterialClassSetNameProps) {
  const { materialClassSet, noLink, color } = props;

  if (noLink) {
    return <Text color={color}>{materialClassSet.name}</Text>;
  }

  return (
    <LinkText
      to={Router.MaterialClassSetDetail({
        materialClassSetId: materialClassSet.id,
      })}
    >
      {materialClassSet.name}
    </LinkText>
  );
}

type MaterialClassSetIdNameProps = Omit<
  MaterialClassSetNameProps,
  'materialClassSet'
> & {
  materialClassSetId: MaterialClassSetId;
};

export function MaterialClassSetIdName(props: MaterialClassSetIdNameProps) {
  const { materialClassSetId, ...materialClassSetNameProps } = props;
  const {
    data: materialClassSet,
    isLoading,
    isLoadingError,
  } = useMaterialClassSet(materialClassSetId);

  if (isLoading) {
    return <Text color='dimmed'>Loading...</Text>;
  }
  if (isLoadingError) {
    return <Text color='red'>Error getting material class set name</Text>;
  }

  return (
    <MaterialClassSetName
      materialClassSet={materialClassSet}
      {...materialClassSetNameProps}
    />
  );
}
