import { createContext, ReactNode, useContext } from 'react';

export interface SamplingSuiteAnalysisContext {
  samplingSuiteSampleAnalysisId: string;
}

const SamplingSuiteAnalysisContext = createContext<
  SamplingSuiteAnalysisContext | undefined
>(undefined);

export function useSamplingSuiteAnalysisContext(): SamplingSuiteAnalysisContext {
  const ctx = useContext(SamplingSuiteAnalysisContext);
  if (!ctx) {
    throw new Error(
      'component must be within a sampling suite analysis context',
    );
  }
  return ctx;
}

export function useOptionalSampleSuiteAnalysisContext():
  | SamplingSuiteAnalysisContext
  | undefined {
  return useContext(SamplingSuiteAnalysisContext);
}

export function SamplingSuiteAnalysisContextProvider(props: {
  samplingSuiteSampleAnalysisId: string;
  children: ReactNode;
}) {
  const { samplingSuiteSampleAnalysisId, children } = props;
  return (
    <SamplingSuiteAnalysisContext.Provider
      value={{ samplingSuiteSampleAnalysisId }}
    >
      {children}
    </SamplingSuiteAnalysisContext.Provider>
  );
}
