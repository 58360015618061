/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FlattenedSamplesByMaterialClassSetDTO } from '../models/FlattenedSamplesByMaterialClassSetDTO';
import type { Radius3DSSampleParametersCreationDTO } from '../models/Radius3DSSampleParametersCreationDTO';
import type { Radius3DSSampleParametersPatchDTO } from '../models/Radius3DSSampleParametersPatchDTO';
import type { SampleCreationDTO } from '../models/SampleCreationDTO';
import type { SampleDTO } from '../models/SampleDTO';
import type { SamplePatchDTO } from '../models/SamplePatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SampleService {
    /**
     * @param commodityId
     * @returns FlattenedSamplesByMaterialClassSetDTO Success
     * @throws ApiError
     */
    public static getFlattenedSamples(
        commodityId?: string,
    ): CancelablePromise<FlattenedSamplesByMaterialClassSetDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/samples/flattened-samples',
            query: {
                'CommodityId': commodityId,
            },
        });
    }
    /**
     * @param containerId
     * @returns SampleDTO Success
     * @throws ApiError
     */
    public static getSamples(
        containerId?: string,
    ): CancelablePromise<Array<SampleDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/samples',
            query: {
                'containerId': containerId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSample(
        requestBody: SampleCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/samples',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sampleId
     * @returns SampleDTO Success
     * @throws ApiError
     */
    public static getSampleById(
        sampleId: string,
    ): CancelablePromise<SampleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/samples/{sampleId}',
            path: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @param sampleId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSampleById(
        sampleId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/samples/{sampleId}',
            path: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @param sampleId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateSampleById(
        sampleId: string,
        requestBody: SamplePatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/samples/{sampleId}',
            path: {
                'sampleId': sampleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sampleId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createRadius3DsSampleParameters(
        sampleId: string,
        requestBody: Radius3DSSampleParametersCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/samples/{sampleId}/radius/3ds-parameters',
            path: {
                'sampleId': sampleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sampleId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchRadius3DsSampleParameters(
        sampleId: string,
        requestBody: Radius3DSSampleParametersPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/samples/{sampleId}/radius/3ds-parameters',
            path: {
                'sampleId': sampleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param sampleId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRadius3DsSampleParameters(
        sampleId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/samples/{sampleId}/radius/3ds-parameters',
            path: {
                'sampleId': sampleId,
            },
        });
    }
}
