import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../../Form/DrawerForm';
import { useCreateSample } from '../../api/sample';
import { SampleCreationDTO } from '../../rest-client';
import {
  ContainerSampleFormFields,
  ContainerSampleFormProps,
  ContainerSampleFormValues,
  useContainerSampleForm,
} from './ContainerSampleForm';

export type AddContainerSampleDrawerFormProps = ContainerSampleFormProps & {
  onSuccess?: (containerSample: SampleCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddContainerSampleDrawerForm(
  props: AddContainerSampleDrawerFormProps,
) {
  const {
    onSuccess,
    containerId,
    sampleTakenTimestamp,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateSample();
  const form = useContainerSampleForm({
    containerId,
    sampleTakenTimestamp,
  });
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    sampleTakenTimestamp,
    containerId,
  }: ContainerSampleFormValues) => {
    if (sampleTakenTimestamp === null) {
      throw new Error('Timestamp cannot be null ');
    }
    if (containerId === null) {
      throw new Error('Container cannot be null ');
    }

    const containerSample: SampleCreationDTO = {
      id,
      link: {
        containerId,
        sampleTakenTimestamp: sampleTakenTimestamp.utc().toISOString(),
        type: 'container',
      },
    };

    setId(uuidv4());
    return containerSample;
  };

  return (
    <DrawerForm
      entityName='Container Sample'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <ContainerSampleFormFields form={form} />
    </DrawerForm>
  );
}
