import { Button, Drawer, Stack } from '@mantine/core';
import { IconArchive } from '@tabler/icons-react';
import { useState } from 'react';
import {
  usePatchSamplingSuiteCapture,
  useSamplingSuiteCaptures,
} from '../../api/samplingSuite';
import { SamplingSuiteCaptureDTO } from '../../rest-client';
import { CapturePreview } from '../SamplingSuiteCapture';

export function CaptureArchiveDrawerButton() {
  const archivedCapturesQuery = useSamplingSuiteCaptures({
    unlinked: true,
    suiteId: null,
    archived: true,
  });
  const [opened, setOpened] = useState(false);

  if (archivedCapturesQuery.data) {
    const captures = archivedCapturesQuery.data;

    if (captures.length > 0) {
      return (
        <>
          <Button
            ml='auto'
            variant='outline'
            color='gray'
            leftIcon={<IconArchive />}
            onClick={() => setOpened(true)}
          >
            Restore Captures
          </Button>
          <Drawer
            title='Archived Captures'
            opened={opened}
            onClose={() => setOpened(false)}
            position='right'
          >
            <Stack align='center'>
              {captures.map((capture) => (
                <ArchivedCapture key={capture.captureId} capture={capture} />
              ))}
            </Stack>
          </Drawer>
        </>
      );
    } else {
      if (opened) {
        setOpened(false);
      }
    }
  }
}

function ArchivedCapture(props: { capture: SamplingSuiteCaptureDTO }) {
  const { capture } = props;

  const patchMutation = usePatchSamplingSuiteCapture();

  return (
    <CapturePreview
      capture={capture}
      loading={!patchMutation.isIdle}
      showWetDryToggle={false}
      leftButtonProps={{
        onClick: () => {
          patchMutation.mutate({
            capture,
            patch: {
              archived: false,
            },
          });
        },
        color: 'blue',
        label: 'restore',
      }}
      onMaximize={() => {
        // TODO: Open lightbox of archived captures
      }}
    />
  );
}
