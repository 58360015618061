import { ActionIcon, Flex, Group, Modal, Stack } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import { ReactNode, useCallback } from 'react';
import { LabeledValue } from '../common';
import { useFacilityContext } from '../Facility/FacilityContext';
import { SamplingSuiteCaptureDTO } from '../rest-client';
import Temporal from '../Temporal/temporal';
import GrossWeight from '../Weights/GrossWeight';
import NetWeight from '../Weights/NetWeight';
import TareWeight from '../Weights/TareWeight';
import { FullResSamplingSuiteCaptureImage } from './Analysis/SamplingSuiteCaptureOld';
import classes from './CaptureLightbox.module.css';

export function CaptureLightbox(props: {
  captures: SamplingSuiteCaptureDTO[];
  selectedCaptureId: string | undefined;
  onCaptureSelect: (captureId: string | undefined) => void;
  titleFn: (capture: SamplingSuiteCaptureDTO) => ReactNode;
  opened: boolean;
  onClose: () => void;
}) {
  const {
    captures,
    selectedCaptureId,
    onCaptureSelect,
    titleFn,
    opened,
    onClose,
  } = props;

  const { timeZoneId: tz } = useFacilityContext();

  const captureIdx = captures.findIndex(
    (c) => c.captureId === selectedCaptureId,
  );

  const capture = captures.at(captureIdx % captures.length);

  const movePrev = useCallback(() => {
    const prevIdx = (captureIdx - 1) % captures.length;
    const prevCapture = captures.at(prevIdx);
    onCaptureSelect(prevCapture?.captureId);
  }, [captureIdx, captures, onCaptureSelect]);

  const moveNext = useCallback(() => {
    const prevIdx = (captureIdx + 1) % captures.length;
    const prevCapture = captures.at(prevIdx);
    onCaptureSelect(prevCapture?.captureId);
  }, [captureIdx, captures, onCaptureSelect]);

  useHotkeys([
    ['ArrowLeft', movePrev],
    ['ArrowRight', moveNext],
  ]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size='calc(100vw)'
      title={capture && titleFn(capture)}
    >
      <Flex align='stretch' h='80vh' gap='sm'>
        <ActionIcon
          onClick={movePrev}
          size='xl'
          variant='light'
          className={classes.changeCaptureButton}
        >
          <IconArrowLeft />
        </ActionIcon>
        <Group
          className={classes.captureContainer}
          spacing='sm'
          w='100%'
          noWrap
        >
          {capture && (
            <div className={classes.captureImageContainer}>
              <FullResSamplingSuiteCaptureImage
                className={classes.captureSvg}
                capture={capture}
                showSegmentation={false}
                zoomable
              />
            </div>
          )}
          {capture && (
            <Stack>
              <LabeledValue label='Taken At' miw='25ch'>
                {Temporal.Instant.from(capture.captureInstant)
                  .toZonedDateTimeISO(tz)
                  .toLocaleString()}
              </LabeledValue>
              <LabeledValue label='Dried'>
                {capture.isDried ? 'Yes' : 'No'}
              </LabeledValue>
              <LabeledValue label='Net Weight'>
                <NetWeight
                  weight={capture.netWeight}
                  sourceIconMode='icon-tooltip'
                />
              </LabeledValue>
              {capture.customTray !== null ? (
                <>
                  <LabeledValue label='Gross Weight'>
                    <GrossWeight weight={capture.grossWeight} />
                  </LabeledValue>
                  <LabeledValue label='Tray Tare'>
                    <TareWeight weight={capture.customTray.tareWeight} />
                  </LabeledValue>
                </>
              ) : null}
            </Stack>
          )}
        </Group>
        <ActionIcon
          onClick={moveNext}
          size='xl'
          variant='light'
          className={classes.changeCaptureButton}
        >
          <IconArrowRight />
        </ActionIcon>
      </Flex>
    </Modal>
  );
}
