import { DefaultMantineColor, SystemProp, Text } from '@mantine/core';
import { Fragment } from 'react';
import { WeightUnit } from '../rest-client';
import { WithUnit } from '../util/WithUnit';
import { getWeightUnitAbbreviation } from './units';

export default function WeightUnitExpectedTotals(props: {
  expectedUnitTotals: Record<string, string>;
  totalIsNegative: boolean;
}) {
  const { expectedUnitTotals, totalIsNegative } = props;

  const unitTotalEntries = Object.entries(expectedUnitTotals);

  return (
    <div style={{ display: 'inline-flex' }}>
      {unitTotalEntries.length === 0 ? '0' : null}
      {unitTotalEntries.map(([unit, total], i) => (
        <Fragment key={unit}>
          <WeightWithUnit
            weightUnit={unit as WeightUnit}
            totalInt={parseInt(total)}
            styleAsErrant={totalIsNegative}
          />
          {i + 1 < unitTotalEntries.length ? <Text weight={500}>+</Text> : ''}
        </Fragment>
      ))}
    </div>
  );
}

export function WeightWithUnit(props: {
  weightUnit: WeightUnit;
  totalInt: number;
  styleAsErrant?: boolean;
}) {
  const { weightUnit, totalInt, styleAsErrant } = props;

  const errant =
    styleAsErrant !== false && (totalInt < 0 || styleAsErrant === true);
  const color: SystemProp<DefaultMantineColor> | undefined = errant
    ? 'red'
    : undefined;
  return (
    <WithUnit unitSuffix={getWeightUnitAbbreviation(weightUnit)} c={color}>
      <Text c={color}>{new Intl.NumberFormat().format(totalInt)}</Text>
    </WithUnit>
  );
}
