import {
  ActionIcon,
  Alert,
  Checkbox,
  Group,
  Skeleton,
  Stack,
  Text,
  Title,
  createStyles,
  getStylesRef,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { AppPage } from '../../App/AppPage';
import { MutationErrorAlert } from '../../Form/MutationErrorAlert';
import { DeleteIcon, SaveIcon } from '../../Icons';
import { RecoveryGoalIdName } from '../../RecoveryGoal/RecoveryGoalIdName';
import { RecoveryGoalSelect } from '../../RecoveryGoal/RecoveryGoalSelect';
import { SortSystemIdName } from '../../SortSystem/SortSystemIdName';
import {
  usePatchRedWaveProfileRevision,
  useRedWaveProfileRevision,
  useRedWaveSortProgramSnapshot,
} from '../../api/redWave';
import { useSortSystem } from '../../api/sortSystem';
import { LabeledValue } from '../../common';
import {
  ApiError,
  RedWaveProfileRevisionPatchDTO,
  RedWaveSortProgramNumber,
  SortSystemId,
} from '../../rest-client';
import { Router } from '../../router';
import {
  RedWaveMaterialClassTable,
  RedWaveMaterialClassTableTemplate,
} from './RedWaveMaterialClassTable';

export function RedWaveSortProgramDetail({
  sortSystemId,
  sortProgramNumber,
  timestamp,
}: {
  sortSystemId: SortSystemId;
  sortProgramNumber: RedWaveSortProgramNumber;
  timestamp: Dayjs | undefined;
}) {
  const sortProgramQuery = useRedWaveSortProgramSnapshot(
    sortSystemId,
    sortProgramNumber,
    timestamp,
  );
  const sortSystemQuery = useSortSystem(sortSystemId);

  const breadcrumbs = [
    {
      routeName: Router.ProcessList(),
      title: 'Processes',
    },
    'Sort Systems',
    {
      routeName: Router.SortSystemDetail({ sortSystemId }),
      title:
        sortSystemQuery.data === undefined ? null : sortSystemQuery.data.name,
    },
  ];
  const isLoading = sortProgramQuery.isLoading;

  const sortProgram = sortProgramQuery.data;
  const adjustSortProgramNumber = sortProgramNumber + 1;

  if (sortProgramQuery.isError) {
    //TODO(2296): add discriminated union on the error to determine if it's a 404 or if it's an actual problem
    if ((sortProgramQuery.error as ApiError).status === 404) {
      return (
        <AppPage
          breadcrumbs={breadcrumbs}
          title={<Text c='dimmed'>Unknown</Text>}
        >
          <AppPage.Section>
            <Alert color='blue' title='Sort Program Unused'>
              This sort program has not yet been used. The material classes are
              unknown until material data is observed while the sort program is
              active. You can specify the recovery goal as a function of the
              ejected or passed material classes once they are observed.
            </Alert>
          </AppPage.Section>
        </AppPage>
      );
    } else {
      throw sortProgramQuery.error;
    }
  }

  return (
    <AppPage breadcrumbs={breadcrumbs} title={sortProgram?.name ?? null}>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Sort Program Details</Title>
          <Group spacing='xl'>
            <LabeledValue label='Sort System'>
              <SortSystemIdName sortSystemId={sortSystemId} />
            </LabeledValue>
            <LabeledValue label='Sort Program Number'>
              <Text>{adjustSortProgramNumber.toString()}</Text>
            </LabeledValue>
            <LabeledValue label='Profile Number'>
              <Skeleton visible={sortProgramQuery.isLoading}>
                <Text>
                  {sortProgram?.profileNumber.toString() ?? 'Loading...'}
                </Text>
              </Skeleton>
            </LabeledValue>
            <LabeledValue label='Configuration Number'>
              <Skeleton visible={sortProgramQuery.isLoading}>
                <Text>
                  {sortProgram?.configurationNumber.toString() ?? 'Loading...'}
                </Text>
              </Skeleton>
            </LabeledValue>
          </Group>
          <LabeledValue label='Recovery Goal'>
            <>
              {match(sortProgram?.profileRevisionId)
                .with(undefined, () => (
                  <Skeleton visible={sortProgramQuery.isLoading}>
                    <Text>Loading...</Text>
                  </Skeleton>
                ))
                .with(null, () => <Text c='dimmed'>none</Text>)
                .otherwise((value) => (
                  <RedWaveProfileRecoveryGoal profileRevisionId={value} />
                ))}
            </>
          </LabeledValue>
        </Stack>
      </AppPage.Section>

      <AppPage.Section>
        <Title order={2}>Material Classes</Title>
        {sortProgram?.profileRevisionId ? (
          <RedWaveMaterialClassTable
            profileRevisionId={sortProgram.profileRevisionId}
          />
        ) : (
          <RedWaveMaterialClassTableTemplate isLoading={isLoading}>
            {null}
          </RedWaveMaterialClassTableTemplate>
        )}
      </AppPage.Section>
    </AppPage>
  );
}

const useRedWaveProfileRecoveryGoalStyles = createStyles(() => ({
  deleteButton: {
    ref: getStylesRef('deleteButton'),
    visibility: 'hidden',
  },
  description: {
    [`&:hover .${getStylesRef('deleteButton')}`]: {
      visibility: 'visible',
    },
  },
}));

function RedWaveProfileRecoveryGoal(props: { profileRevisionId: string }) {
  const { profileRevisionId } = props;
  const { classes } = useRedWaveProfileRecoveryGoalStyles();
  const {
    data: profileRevision,
    isError,
    error,
    isLoading,
  } = useRedWaveProfileRevision(profileRevisionId);

  const [selectedRecoveryGoalId, setSelectedRecoveryGoalId] = useState<
    string | null
  >(null);

  const [eject, setEject] = useState(false);
  const [ejectConfigured, setEjectConfigured] = useState(false);

  const patchMutation = usePatchRedWaveProfileRevision(profileRevisionId);

  if (isLoading) {
    return <Skeleton visible>Loading...</Skeleton>;
  }

  if (isError) {
    throw error;
  }

  if (profileRevision.recoveryGoal !== null) {
    // We can also show an icon for positive programs / negative programs where a postive program has the material of interest on the eject and a negative program has on the pass
    return (
      <Group className={classes.description}>
        <Text>
          <RecoveryGoalIdName
            recoveryGoalId={profileRevision.recoveryGoal.recoveryGoalId}
          />{' '}
          on the {profileRevision.recoveryGoal.eject ? 'eject' : 'pass'} stream
        </Text>
        {patchMutation.isError ? (
          <Alert
            color='red'
            title='Error clearing recovery goal'
            withCloseButton
            closeButtonLabel='Clear Error'
            onClick={() => patchMutation.reset()}
          >
            There was an error clearing the recovery goal link. You can clear
            the error and try again.
          </Alert>
        ) : (
          <ActionIcon
            className={classes.deleteButton}
            color='red'
            disabled={!patchMutation.isIdle}
            loading={patchMutation.isLoading}
            onClick={() =>
              patchMutation.mutate(
                { recoveryGoal: null },
                {
                  onSuccess() {
                    showNotification({
                      title: 'Recovery Goal Link Cleared',
                      message:
                        'The link to the recovery goal has been cleared successfully',
                      color: 'green',
                      icon: <IconCheck />,
                    });
                    patchMutation.reset();
                  },
                },
              )
            }
          >
            <DeleteIcon />
          </ActionIcon>
        )}
      </Group>
    );
  } else {
    return (
      <Group>
        <RecoveryGoalSelect
          value={selectedRecoveryGoalId}
          onChange={setSelectedRecoveryGoalId}
        />

        {patchMutation.isError ? (
          <MutationErrorAlert
            errorTitle="Error Updating Sort Program's Recovery Goal"
            entityName="Sort Program's Recovery Goal"
            mutation={patchMutation}
            formVariant='edit'
          />
        ) : selectedRecoveryGoalId !== null ? (
          <>
            <Checkbox
              indeterminate={!ejectConfigured}
              checked={eject}
              onChange={(e) => {
                setEjectConfigured(true);
                setEject(e.currentTarget.checked);
              }}
              size='md'
              label='Eject'
              labelPosition='left'
            />
            {ejectConfigured ? (
              <ActionIcon
                color='teal'
                variant='filled'
                disabled={!patchMutation.isIdle}
                loading={patchMutation.isLoading}
                onClick={() => {
                  const patch: RedWaveProfileRevisionPatchDTO = {
                    recoveryGoal: {
                      eject,
                      recoveryGoalId: selectedRecoveryGoalId,
                    },
                  };
                  patchMutation.mutate(patch, {
                    onSuccess() {
                      showNotification({
                        title: 'Recovery Goal Saved',
                        message: 'The recovery goal has been saved',
                        color: 'green',
                        icon: <IconCheck />,
                      });
                      patchMutation.reset();
                    },
                  });
                }}
              >
                <SaveIcon />
              </ActionIcon>
            ) : undefined}
          </>
        ) : undefined}
      </Group>
    );
  }
}
