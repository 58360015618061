import { Center, useMantineTheme } from '@mantine/core';
import {
  IconCheck,
  IconProgressAlert,
  IconProgressX,
} from '@tabler/icons-react';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';
import { useFacilityContext } from '../../Facility/FacilityContext.tsx';
import { LinkText } from '../../Link.tsx';
import {
  FlattenedSampleDTO,
  FlattenedSampleMetadataDTO,
  ProductTypeDTO,
} from '../../rest-client/index.ts';
import { Router } from '../../router.ts';
import {
  commodityColCellRenderer,
  employedRecoveryStrategyColCellRenderer,
  exportDestinationColCellRenderer,
  producingProcessColCellRenderer,
  upstreamSourceColCellRenderer,
  upstreamSourceCommodityColCellRenderer,
} from './SampleTable.tsx';
import {
  flattenedSampleMetadataDtoToSampleRowMetadata,
  SampleStatus,
  SampleTableRowMetadata,
} from './SampleTableState.ts';

export type UnanalyzedSampleTableProps = {
  unanalyzedSamplesMetadata: FlattenedSampleMetadataDTO[];
  inProgressSamples: FlattenedSampleDTO[];
};

export function IncompleteSampleTable(props: UnanalyzedSampleTableProps) {
  const { unanalyzedSamplesMetadata, inProgressSamples } = props;
  const { timeZoneId } = useFacilityContext();
  const { colors } = useMantineTheme();

  const dateStringFormat = 'YYYY-MM-DD';
  const tableRows = useMemo(
    () =>
      inProgressSamples
        .map((sample) =>
          flattenedSampleMetadataDtoToSampleRowMetadata(
            sample.metadata,
            timeZoneId,
            dateStringFormat,
            'in-progress',
          ),
        )
        .concat(
          unanalyzedSamplesMetadata.map((metadata) =>
            flattenedSampleMetadataDtoToSampleRowMetadata(
              metadata,
              timeZoneId,
              dateStringFormat,
              'unanalyzed',
            ),
          ),
        ),
    [inProgressSamples, timeZoneId, unanalyzedSamplesMetadata],
  );

  const defaultColDef: ColDef | ColGroupDef = {
    suppressHeaderFilterButton: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };

  const gridOptions: GridOptions<SampleTableRowMetadata> = {
    rowData: tableRows,
    defaultColDef: defaultColDef,
    // if any column options are to be enabled, each column of cellDataType = 'object'
    // must have its valueFormatter defined; the valueFormatters from SampleTableAgGrid.tsx
    // can be factored out and used in that case
    columnDefs: [
      {
        field: 'sampleNumericId', // sampleNumericId should be an int
        headerName: 'ID',
        cellDataType: 'number',
        type: 'rightAligned',
        pinned: true,
        sort: 'desc',
        cellRenderer: sampleIdColCellRenderer,
      },
      {
        field: 'productType',
        headerName: 'Commodity',
        pinned: true,
        cellDataType: 'object',
        valueFormatter: (
          params: ValueFormatterParams<SampleTableRowMetadata, ProductTypeDTO>,
        ) => {
          return match(params.value)
            .with(
              { type: 'Commodity' },
              (commodity) => commodity.commodity.name,
            )
            .with({ type: 'Intermediate' }, () => 'intermediate')
            .with({ type: 'Unknown' }, () => '?')
            .with(null, undefined, () => '-')
            .exhaustive();
        },
        cellRenderer: commodityColCellRenderer,
      },
      {
        field: 'status',
        headerName: 'Status',
        cellDataType: 'text',
        tooltipField: 'status',
        cellRenderer: (
          params: CustomCellRendererProps<SampleTableRowMetadata, SampleStatus>,
        ) => {
          return (
            <Center mt={5}>
              {match(params.value)
                .with(P.nullish, () => '')
                .with('unanalyzed', () => (
                  <IconProgressX size={30} color={colors.gray[6]} />
                ))
                .with('in-progress', () => (
                  <IconProgressAlert size={30} color={colors.yellow[6]} />
                ))
                .with('complete', () => (
                  <IconCheck size={30} color={colors.green[6]} />
                ))
                .exhaustive()}
            </Center>
          );
        },
      },
      {
        field: 'sampleTakenDate',
        headerName: 'Date',
        cellDataType: 'dateString',
      },
      {
        field: 'upstreamSource',
        headerName: 'Upstream Source',
        // cellDataType: 'object',
        cellRenderer: upstreamSourceColCellRenderer,
      },
      {
        field: 'upstreamSourceCommodity',
        headerName: 'Upstream Source Commodity',
        // cellDataType: 'object',
        cellRenderer: upstreamSourceCommodityColCellRenderer,
      },
      {
        field: 'employedRecoveryStrategy',
        headerName: 'Recovery Strategy',
        // cellDataType: 'object',
        cellRenderer: employedRecoveryStrategyColCellRenderer,
      },
      {
        field: 'producingProcess',
        headerName: 'Process',
        // cellDataType: 'object',
        cellRenderer: producingProcessColCellRenderer,
      },
      {
        field: 'exportDestination',
        headerName: 'Export Destination',
        // cellDataType: 'object',
        cellRenderer: exportDestinationColCellRenderer,
      },
    ],
  };

  return (
    <div
      className={'table-container ag-theme-quartz'}
      style={{ width: '100%' }}
    >
      <AgGridReact
        {...gridOptions}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        suppressColumnVirtualisation
        domLayout='autoHeight'
        overlayNoRowsTemplate='No unanalyzed samples. Any new, unanalyzed samples will be shown here.'
        tooltipShowDelay={0}
        pagination
        paginationPageSize={5}
        paginationPageSizeSelector={[5, 10, 25]}
      />
    </div>
  );
}

const sampleIdColCellRenderer = (
  params: CustomCellRendererProps<SampleTableRowMetadata, number>,
) => {
  if (!params.data?.sampleId) {
    return params.value?.toString();
  }
  return (
    params.data && (
      <LinkText
        to={Router.SampleDetail({
          sampleId: params.data?.sampleId,
        })}
      >
        {params.value}
      </LinkText>
    )
  );
};
