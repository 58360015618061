import { Alert, Loader, Table, Text, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { P, match } from 'ts-pattern';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { ContainerIdName } from '../Container/ContainerIdName';
import { useFacilityContext } from '../Facility/FacilityContext';
import { InternalMaterialSinkIdName } from '../InternalMaterialSink/InternalMaterialSinkIdName';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { TableEmptyBasicContent } from '../Table/TableEmptyBasicContent';
import { useInternallySinkedMaterialSets } from '../api/internalSinkContainerTransfer';
import { CalendarDateTime } from '../common';
import { InternalMaterialSinkId } from '../rest-client';
import { InternallySinkedMaterialName } from './InternallySinkedMaterialName';

interface InternallySinkedMaterialTableProps {
  internalMaterialSinkId?: InternalMaterialSinkId;
}

export function InternallySinkedMaterialTable(
  props: InternallySinkedMaterialTableProps,
) {
  const { internalMaterialSinkId } = props;
  const facility = useFacilityContext();
  const theme = useMantineTheme();
  const sinkedMaterialsQuery = useInternallySinkedMaterialSets(
    facility.id,
    internalMaterialSinkId,
  );

  if (sinkedMaterialsQuery.isLoadingError) {
    throw sinkedMaterialsQuery.error;
  }

  if (sinkedMaterialsQuery.data?.status === 'ledger-error') {
    return (
      <Alert color='orange' title='Ledger Error'>
        Cannot compute material data while there is a ledger error.
      </Alert>
    );
  }

  const sinkedMaterials =
    sinkedMaterialsQuery.data?.internallySinkedMaterialSets;

  const isLoading = sinkedMaterialsQuery.isLoading;
  const isEmpty = sinkedMaterials && sinkedMaterials.length === 0;
  const showDestinationSink = internalMaterialSinkId === undefined;

  const body = sinkedMaterials?.map((sinkedMaterial) => {
    return (
      <tr key={sinkedMaterial.hash}>
        {match(sinkedMaterial.consumingEffect)
          .with(
            { kind: P.union('PartialSinkTransfer', 'TotalSinkTransfer') },
            (sinkTransfer) => (
              <>
                <td>
                  <InternallySinkedMaterialName
                    sinkTransfer={sinkTransfer.sinkTransfer}
                  />
                </td>
                <td>
                  <ContainerIdName
                    containerId={sinkTransfer.sinkTransfer.containerId}
                    variant='name-only-link'
                    time={dayjs.utc(sinkTransfer.effectiveTimestamp)}
                  />
                </td>
                {showDestinationSink && (
                  <td>
                    <InternalMaterialSinkIdName
                      internalMaterialSinkId={
                        sinkTransfer.sinkTransfer.internalSinkId
                      }
                    />
                  </td>
                )}
                <td>
                  <CalendarDateTime iso8601={sinkTransfer.effectiveTimestamp} />
                </td>
                <td>
                  <MaterialSetCommodity materialSet={sinkedMaterial} />
                </td>
                <td>
                  <MaterialSetMass materialSet={sinkedMaterial} />
                </td>
              </>
            ),
          )
          // this should never happen
          .otherwise(() => (
            <>
              <td>
                <Text c='dimmed'>?</Text>
              </td>
              {showDestinationSink && (
                <td>
                  <Text c='dimmed'>?</Text>
                </td>
              )}
              <td>
                <Text c='dimmed'>?</Text>
              </td>
              <td>
                <Text c='dimmed'>?</Text>
              </td>
              <td style={{ textAlign: 'right' }}>
                <Text c='dimmed'>?</Text>
              </td>
            </>
          ))}
      </tr>
    );
  });

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>
              No Exported Materials
            </TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
          <th>Container Exported From</th>
          {showDestinationSink && <th>Destination Exported To</th>}
          <th>Export Time</th>
          <th>Commodity</th>
          <th style={{ textAlign: 'right' }}>Mass</th>
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </Table>
  );
}
