import {
  Alert,
  Button,
  Center,
  Flex,
  Grid,
  Group,
  List,
  Loader,
  Progress,
  Skeleton,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { P, match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { ContainerIdName } from '../Container/ContainerIdName';
import { WeightedCompositionChart } from '../Container/ContainerWeighedAndSampledConstituents';
import { EditIcon } from '../Icons';
import {
  InferredPropertyTextWrapper,
  InferredPropertyWrapper,
} from '../InferredPropertyWrapper';
import { InternalMaterialSinkIdName } from '../InternalMaterialSink/InternalMaterialSinkIdName';
import { InternalMaterialSourcesDerivedFromTextModal } from '../InternalMaterialSource/InternalMaterialSourcesDerivedFromTextModal';
import { DeleteInternalSinkContainerTransferButton } from '../InternalSinkContainerTransfer/DeleteInternalSinkContainerTransferButton';
import { EditInternalSinkContainerTransferDrawerForm } from '../InternalSinkContainerTransfer/EditInternalSinkContainerTransferDrawerForm';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { RecoveryGoalPathsTextModal } from '../RecoveryGoal/RecoveryGoalPathsTextModal';
import { RecoveryStrategyIdName } from '../RecoveryStrategy/RecoveryStategyName';
import NetWeight from '../Weights/NetWeight';
import { useInternallySinkedMaterialDetails } from '../api/internalSinkContainerTransfer';
import { CalendarDateTime, LabeledValue } from '../common';
import {
  InternalSinkContainerTransferId,
  InternallySinkedMaterialDetailsDTO,
  LedgerErrorInternallySinkedMaterialDetailsDTO,
} from '../rest-client';
import { Router } from '../router';
import { PCTFORMAT } from '../util/percentages';
import { getWeightFromNetWeight } from '../util/weightFromNetWeight';

export function InternallySinkedMaterialDetailsPage(props: {
  sinkTransferId: InternalSinkContainerTransferId;
}) {
  const { sinkTransferId } = props;
  const sinkTransferDetailsQuery =
    useInternallySinkedMaterialDetails(sinkTransferId);
  const [editDrawerOperned, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);

  const { data: sinkTransferDetailsResult } = sinkTransferDetailsQuery;

  const breadcrumbs = [
    { routeName: Router.InternalSinkList(), title: 'Material Exports' },
    {
      routeName: sinkTransferDetailsResult
        ? Router.InternalSinkDetail({
            internalMaterialSinkId:
              sinkTransferDetailsResult.internalMaterialSink.id,
          })
        : Router.InternalSinkList(),
      title: sinkTransferDetailsResult ? (
        <InternalMaterialSinkIdName
          internalMaterialSinkId={
            sinkTransferDetailsResult.internalMaterialSink.id
          }
          variant='name-only'
        />
      ) : (
        'Loading...'
      ),
    },
  ];

  return (
    <AppPage
      title={
        sinkTransferDetailsResult
          ? sinkTransferDetailsResult.sinkTransfer.name ?? 'Unnamed'
          : 'Loading...'
      }
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <Grid.Col span={4}>
          <AppPage.Section h='100%'>
            <Stack>
              <Group position='apart'>
                <Title order={3}>Exported Material Details</Title>
                <Group>
                  <>
                    <Button onClick={openEditDrawer} leftIcon={<EditIcon />}>
                      Edit
                    </Button>
                    <EditInternalSinkContainerTransferDrawerForm
                      opened={editDrawerOperned}
                      onClose={closeEditDrawer}
                      sinkTransferId={sinkTransferId}
                    />
                  </>
                  <DeleteInternalSinkContainerTransferButton
                    sinkTransferId={sinkTransferId}
                    onSucess={() => Router.replace('InternalSinkList')}
                  />
                </Group>
              </Group>
              <Flex gap='md' wrap='wrap'>
                <LabeledValue label='Name'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ sinkTransfer: { name: null } }, () => 'Unnamed')
                    .otherwise(({ sinkTransfer }) => sinkTransfer.name)}
                </LabeledValue>
                <LabeledValue label='Commodity'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents }) => (
                        <InferredPropertyWrapper>
                          <MaterialSetCommodity
                            materialSet={
                              materialSetConstituents.rootMaterialSet
                            }
                          />
                        </InferredPropertyWrapper>
                      ),
                    )
                    .exhaustive()}
                </LabeledValue>
                <LabeledValue label='Mass'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents }) => (
                        <MaterialSetMass
                          materialSet={materialSetConstituents.rootMaterialSet}
                          unknownVerbose
                        />
                      ),
                    )
                    .exhaustive()}
                </LabeledValue>
                <LabeledValue label='Container Exported From'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .otherwise(({ sinkTransfer }) => (
                      <ContainerIdName
                        containerId={sinkTransfer.containerId}
                        time={dayjs.utc(sinkTransfer.effectiveTimestamp)}
                      />
                    ))}
                </LabeledValue>
                <LabeledValue label='Destination Exported To'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .otherwise(({ sinkTransfer }) => (
                      <InternalMaterialSinkIdName
                        internalMaterialSinkId={sinkTransfer.internalSinkId}
                      />
                    ))}
                </LabeledValue>
                <LabeledValue label='Export Time'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .otherwise(({ sinkTransfer }) => (
                      <CalendarDateTime
                        iso8601={sinkTransfer.effectiveTimestamp}
                      />
                    ))}
                </LabeledValue>
              </Flex>
            </Stack>
          </AppPage.Section>
        </Grid.Col>
        <Grid.Col span={4}>
          <AppPage.Section h='100%'>
            <Stack>
              <Title order={3}>Production Details</Title>
              <Flex gap='md' wrap='wrap'>
                <LabeledValue label='Production Start Time'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents, sinkTransfer }) => (
                        <CalendarDateTime
                          iso8601={
                            materialSetConstituents.constituentProducingInterval
                              ?.start ?? sinkTransfer.effectiveTimestamp
                          }
                        />
                      ),
                    )
                    .exhaustive()}
                </LabeledValue>
                <LabeledValue label='Production End Time'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents, sinkTransfer }) => (
                        <CalendarDateTime
                          iso8601={
                            materialSetConstituents.constituentProducingInterval
                              ?.end ?? sinkTransfer.effectiveTimestamp
                          }
                        />
                      ),
                    )
                    .exhaustive()}
                </LabeledValue>
                <LabeledValue label='Production Duration'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents }) =>
                        match(
                          materialSetConstituents.constituentProducingInterval,
                        )
                          .with(null, () => <Text c='dimmed'>Unknown</Text>)
                          .when(
                            ({ start, end }) => !start || !end,
                            () => <Text c='dimmed'>none</Text>,
                          )
                          .otherwise(({ start, end }) => (
                            <Text>
                              {dayjs.utc(end).diff(dayjs.utc(start), 'days')}{' '}
                              days
                            </Text>
                          )),
                    )
                    .exhaustive()}
                </LabeledValue>
                <LabeledValue label='Sources Derived From'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents }) =>
                        match(materialSetConstituents.sourcesDerivedFrom)
                          .when(
                            (sources) => sources.length === 0,
                            () => <Text c='dimmed'>none</Text>,
                          )
                          .otherwise((sources) => (
                            <InternalMaterialSourcesDerivedFromTextModal
                              showSourceNames={false}
                              sources={sources}
                              modalProps={{
                                title: 'Material Sources Derived From',
                                size: 'auto',
                              }}
                            />
                          )),
                    )
                    .exhaustive()}
                </LabeledValue>
              </Flex>
            </Stack>
          </AppPage.Section>
        </Grid.Col>
        <Grid.Col span={4}>
          <AppPage.Section h='100%'>
            <Stack>
              <Title order={3}>Processing Details</Title>
              <Flex gap='md' wrap='wrap'>
                <LabeledValue label='Active Processing Duration'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents }) =>
                        match(
                          materialSetConstituents.rootMaterialSet
                            .totalActiveMinutes,
                        )
                          .with(0, () => <Text c='dimmed'>not processed</Text>)
                          .with(P.number.gt(60), (minutes) => (
                            <Text>
                              {Math.floor(minutes / 60)}h {minutes % 60}m
                            </Text>
                          ))
                          .otherwise((minutes) => <Text>{minutes % 60}m</Text>),
                    )
                    .exhaustive()}
                </LabeledValue>
                <LabeledValue label='Compatible Recovery Strategies'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents }) => (
                        <Group>
                          {materialSetConstituents.rootMaterialSet.compatibleRecoveryStrategyIds.map(
                            (recoveryStrategyId) => (
                              <RecoveryStrategyIdName
                                recoveryStrategyId={recoveryStrategyId}
                                key={recoveryStrategyId}
                              />
                            ),
                          )}
                        </Group>
                      ),
                    )
                    .exhaustive()}
                </LabeledValue>
                <LabeledValue label='Incompatible Recovery Sequences'>
                  {match(sinkTransferDetailsResult)
                    .with(undefined, () => (
                      <Skeleton>
                        <Text>Loading...</Text>
                      </Skeleton>
                    ))
                    .with({ status: 'ledger-error' }, () => (
                      <Text c='red'>Ledger Error</Text>
                    ))
                    .with(
                      { status: 'success' },
                      ({ materialSetConstituents }) =>
                        materialSetConstituents.rootMaterialSet
                          .unmappedGlobalRecoveryGoalPaths.length === 0 ? (
                          <Text c='dimmed'>none</Text>
                        ) : (
                          <RecoveryGoalPathsTextModal
                            pathsOrderedByDescendingLength={
                              materialSetConstituents.rootMaterialSet
                                .unmappedGlobalRecoveryGoalPaths
                            }
                            modalProps={{
                              title: 'Incompatible Recovery Sequences',
                              size: 'auto',
                            }}
                            topSection={
                              <Alert color='blue' maw={800}>
                                <List mr='md' size='sm'>
                                  <List.Item>
                                    Incompatible recovery sequences are recoery
                                    sequences that deviate from pre-defined
                                    recovery strategies.
                                  </List.Item>
                                  <List.Item>
                                    Every constituent set of material in the
                                    exported material has an automatically
                                    calculated recovery sequence, which are
                                    cross referenced against the recovery
                                    strategies.
                                  </List.Item>
                                  <List.Item>
                                    Rows with no recovery steps indicate that
                                    there exists unprocessed material in the
                                    exported material or, at least, material
                                    that was not linked to any process data.
                                  </List.Item>
                                  <List.Item>
                                    The source commodity column indicates the
                                    commodity of the material source which the
                                    constituent set of material was derived
                                    from, while the other columns indicate the
                                    recovery steps employed on that source
                                    material.
                                  </List.Item>
                                </List>
                              </Alert>
                            }
                          />
                        ),
                    )
                    .exhaustive()}
                </LabeledValue>
              </Flex>
            </Stack>
          </AppPage.Section>
        </Grid.Col>
      </Grid>
      <AppPage.Section>
        <Stack>
          <Title order={3}>Sampled Composition</Title>
          <InternallySinkedMaterialConstituentsComposition
            sinkTransferDetailsResult={sinkTransferDetailsResult}
          />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}

function InternallySinkedMaterialConstituentsComposition(props: {
  sinkTransferDetailsResult:
    | LedgerErrorInternallySinkedMaterialDetailsDTO
    | InternallySinkedMaterialDetailsDTO
    | undefined;
}) {
  const { sinkTransferDetailsResult } = props;

  return match(sinkTransferDetailsResult)
    .with(undefined, () => (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    ))
    .with({ status: 'ledger-error' }, () => (
      <Alert color='red' title='Ledger Error'>
        The composition for this exported material could not be computed because
        there is a ledger error. To view the exported material composition, fix
        the ledger error, and return to this page.
      </Alert>
    ))
    .with({ status: 'success' }, ({ materialSetConstituents }) => {
      const {
        unmeasuredConstituents,
        materialClassSetWeighedAndSampledConstituents,
        measuredAggregateWeight,
        unmeasuredAggregateWeight,
      } = materialSetConstituents;

      const measuredWeight = getWeightFromNetWeight(measuredAggregateWeight);
      let unmeasuredWeight: number | null = null;
      let measuredWeightProportion: number | null = null;
      let unmeasuredWeightProportion: number | null = null;

      if (unmeasuredAggregateWeight !== null) {
        unmeasuredWeight = getWeightFromNetWeight(unmeasuredAggregateWeight);
        measuredWeightProportion =
          measuredWeight / (measuredWeight + unmeasuredWeight);
        unmeasuredWeightProportion =
          unmeasuredWeight / (measuredWeight + unmeasuredWeight);
      }

      return (
        <>
          <Flex justify='flex-start' gap='md'>
            <LabeledValue label='Sampled Constituents'>
              {materialClassSetWeighedAndSampledConstituents
                .map((c) => c.weighedAndSampledMaterialSets.length)
                .reduce((a, b) => a + b, 0)}
            </LabeledValue>
            <LabeledValue label='Unsampled Constituents'>
              {unmeasuredConstituents.length}
            </LabeledValue>
            <LabeledValue label='Sampled Weight'>
              <InferredPropertyTextWrapper>
                <NetWeight
                  weight={measuredAggregateWeight}
                  sourceIconMode='icon-tooltip'
                />
              </InferredPropertyTextWrapper>
            </LabeledValue>
            {unmeasuredWeight && unmeasuredAggregateWeight && (
              <>
                <LabeledValue label='Unsampled Weight'>
                  <InferredPropertyTextWrapper>
                    <NetWeight
                      weight={unmeasuredAggregateWeight}
                      sourceIconMode='icon-tooltip'
                    />
                  </InferredPropertyTextWrapper>
                </LabeledValue>
                <LabeledValue label='Sampling Coverage by Weight'>
                  <Stack spacing={0} w='15rem'>
                    <Group position='apart'>
                      <Text color='teal'>Sampled</Text>
                      <Text color='red'>Unsampled</Text>
                    </Group>
                    <Progress
                      size='xl'
                      radius='xs'
                      sections={[
                        {
                          value: (measuredWeightProportion ?? Number.NaN) * 100,
                          color: 'teal',
                        },
                        {
                          value:
                            (unmeasuredWeightProportion ?? Number.NaN) * 100,
                          color: 'red',
                        },
                      ]}
                    />
                    <Group position='apart' fw={600}>
                      <Text color='teal'>
                        {PCTFORMAT.format(
                          measuredWeightProportion ?? Number.NaN,
                        )}
                      </Text>
                      <Text color='red'>
                        {PCTFORMAT.format(
                          unmeasuredWeightProportion ?? Number.NaN,
                        )}
                      </Text>
                    </Group>
                  </Stack>
                </LabeledValue>
              </>
            )}
          </Flex>
          {materialClassSetWeighedAndSampledConstituents.length === 0 ? (
            <Alert title='No Constituent Compositions' color='blue'>
              The composition of this exported material could not be computed.
              None of the constituent sets of material in this exported material
              have both a measured or inferred weight and a composition.
            </Alert>
          ) : (
            materialClassSetWeighedAndSampledConstituents.map((wc) => (
              <WeightedCompositionChart
                key={wc.materialClassSet.id}
                weightedComposition={wc}
                measuredAggregateWeight={measuredAggregateWeight}
              />
            ))
          )}
        </>
      );
    })
    .exhaustive();
}
