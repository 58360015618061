import dayjs from 'dayjs';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { useDeleteEntityModal } from '../../Form/DeleteEntity';
import { TruckLoadIdName } from '../../TruckLoad/TruckLoadIdName';
import { useDeleteTruckLoadTransfer } from '../../api/truckLoad';
import { CalendarDateTime } from '../../common';
import { MaterialTruckLoadTransferDTO } from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem';
import { TransferTypeCell } from '../InventoryLedgerEventTypeCell';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { TransactionStatus } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu';

export function TruckLoadTransferRow(props: {
  id: string;
  transfer: MaterialTruckLoadTransferDTO;
  status: TransactionStatus;
}) {
  const { transfer, status, id } = props;
  const deleteMutation = useDeleteTruckLoadTransfer();
  const openDeleteModal = useDeleteEntityModal({
    mutationVariables: transfer.id,
    deleteMutation,
    entityName: 'Truck Load Transfer',
  });

  const truckLoadName = (
    <TruckLoadIdName
      truckLoadId={transfer.truckLoadId}
      variant='icon-name-link'
    />
  );
  const containerName = (
    <ContainerIdName
      containerId={transfer.containerId}
      variant='icon-name-link'
      time={dayjs.utc(transfer.effectiveTimestamp)}
    />
  );

  return (
    <InventoryLedgerRowTemplate
      id={id}
      entryStatus={status}
      date={<CalendarDateTime iso8601={transfer.effectiveTimestamp} />}
      eventType={<TransferTypeCell partial={!transfer.sourceEmptied} />}
      source={transfer.unload ? truckLoadName : containerName}
      destination={transfer.unload ? containerName : truckLoadName}
      actions={
        <RowActionsMenu>
          {/* TODO: implement truckload edit */}
          <DeleteMenuItem onClick={openDeleteModal} />
        </RowActionsMenu>
      }
    />
  );
}
