import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { DeleteIcon, EditIcon } from '../Icons';
import { useDeleteMaterialClass, useMaterialClass } from '../api/materialClass';
import { LabeledValue } from '../common';
import { Router } from '../router';
import { EditMaterialClassDrawerForm } from './EditMaterialClassDrawerForm';

export function MaterialClassDetailPage(props: { materialClassId: string }) {
  const { materialClassId } = props;

  const {
    data: materialClass,
    isLoadingError,
    error,
  } = useMaterialClass(materialClassId);
  const [editDrawerOperned, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);
  const deleteMutation = useDeleteMaterialClass();

  if (isLoadingError) {
    throw error;
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          routeName: Router.MaterialClassList(),
          title: 'Material Classes',
        },
      ]}
      title={materialClass?.name ?? null}
    >
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Material Class Details</Title>
            <>
              <Button onClick={openEditDrawer} leftIcon={<EditIcon />}>
                Edit
              </Button>
              <EditMaterialClassDrawerForm
                opened={editDrawerOperned}
                onClose={closeEditDrawer}
                materialClassId={materialClassId}
              />
            </>
            <Button
              leftIcon={<DeleteIcon />}
              loading={!deleteMutation.isIdle}
              color='red'
              onClick={() => {
                deleteMutation.mutate(materialClassId, {
                  onError() {
                    showNotification({
                      title: 'Error Deleting Material Class',
                      message: 'An error occurred deleted the material class.',
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'Material Class Deleted',
                      message: 'The material class was sucessfully deleted.',
                      color: 'green',
                      icon: <IconCheck />,
                    });
                    Router.replace('MaterialClassList');
                  },
                  onSettled() {
                    deleteMutation.reset();
                  },
                });
              }}
            >
              Delete
            </Button>
          </Group>
          <Group>
            <LabeledValue label='Name'>
              {materialClass?.name ?? 'Loading...'}
            </LabeledValue>
            <LabeledValue label='Short Name'>
              {match(materialClass?.shortName)
                .with(undefined, () => 'Loading...')
                .with(null, '', () => <Text color='dimmed'>none</Text>)
                .otherwise((n) => n)}
            </LabeledValue>
            <LabeledValue label='Description'>
              {match(materialClass?.description)
                .with(undefined, () => 'Loading...')
                .with(null, '', () => <Text color='dimmed'>none</Text>)
                .otherwise((d) => d)}
            </LabeledValue>
          </Group>
        </Stack>
      </AppPage.Section>
      {/* TODO(2318): Link to related recovery goals */}
      {/* <AppPage.Section>
        <Title order={2}>Recovery Goals</Title>
      </AppPage.Section> */}
      {/* TODO(2318): Link to related sample analyses */}
      {/* <AppPage.Section>
        <Title order={2}>Sample Analyses</Title>
      </AppPage.Section> */}
    </AppPage>
  );
}
