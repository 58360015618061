import { Badge, Loader, Table, Text, useMantineTheme } from '@mantine/core';
import { Dayjs } from 'dayjs';
import { match } from 'ts-pattern';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { TruckLoadName } from '../Repository/RepositoryName';
import { TableEmptyBasicContent } from '../Table/TableEmptyBasicContent';
import { useTruckLoadContentsLookup, useTruckLoads } from '../api/truckLoad';

interface TruckLoadTableProps {
  timestamp: Dayjs | undefined;
}

export function TruckLoadTable(props: TruckLoadTableProps) {
  const { timestamp } = props;
  const theme = useMantineTheme();
  const {
    data: truckLoads,
    isLoading,
    isLoadingError,
    error,
  } = useTruckLoads();
  const getTruckLoadState = useTruckLoadContentsLookup(timestamp);

  if (isLoadingError) {
    throw error;
  }

  const isEmpty = truckLoads && truckLoads.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>No Truck Loads</TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
          <th>Commodity</th>
          <th>Weight</th>
        </tr>
      </thead>
      <tbody>
        {truckLoads?.map((truckLoad) => {
          const state = getTruckLoadState(truckLoad.id);
          return (
            <tr key={truckLoad.id}>
              <td>
                <TruckLoadName truckLoad={truckLoad} />
              </td>
              {match(state)
                .with({ status: 'loading' }, () => (
                  <>
                    <td>Loading...</td>
                    <td>Loading...</td>
                  </>
                ))
                .with({ status: 'request-error' }, () => (
                  <>
                    <td>
                      <Text color='red'>Error</Text>
                    </td>
                    <td>
                      <Text color='red'>Error</Text>
                    </td>
                  </>
                ))
                .with({ status: 'ledger-error' }, () => (
                  <>
                    <td>
                      <Text color='orange'>Ledger Error</Text>
                    </td>
                    <td>
                      <Text color='orange'>Ledger Error</Text>
                    </td>
                  </>
                ))
                .with({ status: 'empty' }, () => (
                  <>
                    <td>
                      {' '}
                      <Badge color='gray' variant='outline'>
                        Empty
                      </Badge>
                    </td>
                    <td>
                      {' '}
                      <Badge color='gray' variant='outline'>
                        Empty
                      </Badge>
                    </td>
                  </>
                ))
                .with({ status: 'occupied' }, ({ materialSet }) => (
                  <>
                    <td>
                      <MaterialSetCommodity materialSet={materialSet} />
                    </td>
                    <td>
                      <MaterialSetMass materialSet={materialSet} />
                    </td>
                  </>
                ))
                .exhaustive()}
            </tr>
          );
        }) ?? (
          <tr>
            <td colSpan={3}>
              <Loader />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
