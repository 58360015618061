/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MaterialClassSetConversionCreationDTO } from '../models/MaterialClassSetConversionCreationDTO';
import type { MaterialClassSetConversionDTO } from '../models/MaterialClassSetConversionDTO';
import type { MaterialClassSetConversionGraphDTO } from '../models/MaterialClassSetConversionGraphDTO';
import type { MaterialClassSetConversionPatchDTO } from '../models/MaterialClassSetConversionPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialClassSetConversionService {
    /**
     * @param sourceMaterialClassSetId
     * @param targetMaterialClassSetId
     * @returns MaterialClassSetConversionDTO Success
     * @throws ApiError
     */
    public static getMaterialClassSetConversions(
        sourceMaterialClassSetId?: string,
        targetMaterialClassSetId?: string,
    ): CancelablePromise<Array<MaterialClassSetConversionDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-set-conversions',
            query: {
                'sourceMaterialClassSetId': sourceMaterialClassSetId,
                'targetMaterialClassSetId': targetMaterialClassSetId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createMaterialClassSetConversion(
        requestBody?: MaterialClassSetConversionCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/material-class-set-conversions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param materialClassSetConversionId
     * @returns MaterialClassSetConversionDTO Success
     * @throws ApiError
     */
    public static getMaterialClassSetConversion(
        materialClassSetConversionId: string,
    ): CancelablePromise<MaterialClassSetConversionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-set-conversions/{materialClassSetConversionId}',
            path: {
                'materialClassSetConversionId': materialClassSetConversionId,
            },
        });
    }
    /**
     * @param materialClassSetConversionId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchMaterialClassSetConversion(
        materialClassSetConversionId: string,
        requestBody: MaterialClassSetConversionPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/material-class-set-conversions/{materialClassSetConversionId}',
            path: {
                'materialClassSetConversionId': materialClassSetConversionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param materialClassSetConversionId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMaterialClassSetConversion(
        materialClassSetConversionId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/material-class-set-conversions/{materialClassSetConversionId}',
            path: {
                'materialClassSetConversionId': materialClassSetConversionId,
            },
        });
    }
    /**
     * @returns MaterialClassSetConversionGraphDTO Success
     * @throws ApiError
     */
    public static getMaterialClassSetConversionGraph(): CancelablePromise<MaterialClassSetConversionGraphDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-set-conversions/graph',
        });
    }
}
