import { Group, SegmentedControl, Stack, Text } from '@mantine/core';
import { EChartsCoreOption } from 'echarts/core';
import { useCallback, useMemo, useState } from 'react';
import { EChart } from '../../echarts/BareEChart';
import { useCategoricalColors } from '../../lib/colors';
import { MaterialClassDTO } from '../../rest-client';
import Temporal from '../../Temporal/temporal';
import { SampleTableRow } from './SampleTableState';

export function SampleCompositionLineChart(props: {
  materialClasses: MaterialClassDTO[];
  filteredRows: SampleTableRow[] | undefined;
}) {
  const { materialClasses, filteredRows } = props;
  const colors = useCategoricalColors();

  const [windowDays, setWindowDays] = useState(7);

  const tooltipLabelFormatter = useCallback(
    (value: number | null) =>
      `${value === null ? 'unknown' : value.toFixed(1)}%`,
    [],
  );

  const option = useMemo((): EChartsCoreOption => {
    const samples = filteredRows?.sort(
      (a, b) =>
        Temporal.Instant.from(a.metadata.sampleTakenTime).epochSeconds -
        Temporal.Instant.from(b.metadata.sampleTakenTime).epochSeconds,
    );

    const firstSample = samples?.at(0);
    const lastSample = samples?.at(-1);

    const dailyRows = Map.groupBy(
      samples ?? [],
      (sample) => sample.metadata.sampleTakenDate,
    );

    const graphPoints: { date: string; composition: number[] }[] = [];
    if (firstSample && lastSample) {
      let movingDate = Temporal.PlainDate.from(
        firstSample.metadata.sampleTakenDate,
      );
      const endDate = Temporal.PlainDate.from(
        lastSample.metadata.sampleTakenDate,
      );
      while (Temporal.PlainDate.compare(movingDate, endDate) <= 0) {
        const windowRows: SampleTableRow[] = [];
        for (let backDays = 0; backDays < windowDays; backDays++) {
          const backDay = movingDate.subtract({ days: backDays }).toString();
          const backDayRows = dailyRows.get(backDay) ?? [];
          windowRows.push(...backDayRows);
        }

        const numRows = windowRows.length;
        if (numRows > 0) {
          graphPoints.push({
            date: movingDate.toString(),
            composition: materialClasses.map(
              (materialClass) =>
                windowRows
                  .map(
                    (row) =>
                      row.analysis.materialClassToProportionMap[
                        materialClass.id
                      ],
                  )
                  .reduce((a, b) => a + b) / numRows,
            ),
          });
        }

        movingDate = movingDate.add({ days: 1 });
      }
    }

    return {
      xAxis: {
        type: 'time',
      },
      yAxis: {
        type: 'value',
        name: '% Sample Mass',
        align: 'right',
        axisLabel: {
          formatter: '{value} %',
        },
      },
      legend: {
        selected: Object.fromEntries(
          materialClasses.map((materialClass, i) => [
            materialClass.shortName ?? materialClass.name,
            i < materialClasses.length - 1 || materialClasses.length > 2,
          ]),
        ),
      },
      grid: {
        left: 50,
        right: 20,
        top: 80, // hacky fix to make room for legend wrapping with large MaterialClassSets
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: tooltipLabelFormatter,
        order: 'valueDesc',
      },
      series: materialClasses.map((materialClass, idx) => ({
        type: 'line',
        id: materialClass.id,
        color: colors[idx],
        showSymbol: graphPoints.length < 20,
        name: materialClass.shortName ?? materialClass.name,
        smooth: false,
        sampling: 'average',
        data: graphPoints.map(({ date, composition }) => ({
          value: [date, composition[idx] * 100.0],
        })),
      })),
    };
  }, [
    filteredRows,
    materialClasses,
    tooltipLabelFormatter,
    windowDays,
    colors,
  ]);

  return (
    <Group noWrap w='100%'>
      <Stack>
        <Text size='xs' color='dimmed'>
          Moving Avg.
        </Text>
        <SegmentedControl
          style={{ flex: '0 1 auto' }}
          orientation='vertical'
          data={[
            { label: 'Day', value: '1' },
            { label: 'Week', value: '7' },
            { label: '30 Day', value: '30' },
          ]}
          value={`${windowDays}`}
          onChange={(v) => setWindowDays(Number(v))}
        />
      </Stack>

      <EChart option={option} h={'35vh'} style={{ flex: '1 1 auto' }} />
    </Group>
  );
}
