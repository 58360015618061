import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import {
  Alert,
  AppShell,
  Button,
  Center,
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  MantineThemeOverride,
  NumberInput,
  Skeleton,
  Stack,
} from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { useHotkeys } from '@mantine/hooks';
import { modals, ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { useLocation } from '@swan-io/chicane';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import React, { ReactNode, useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { CommodityArea } from '../Commodity/CommodityArea';
import { ContainerArea } from '../Container/ContainerArea';
import { CustomerArea } from '../Customers/CustomerArea';
import { ErrorBoundary, FallbackProps } from '../ErrorBoundary';
import { FacilityInsightsArea } from '../Facility/Insights/FacilityInsightsArea';
import { FeedFlowGroupArea } from '../FeedFlowGroup/FeedFlowGroupArea';
import { InternalSinkArea } from '../InternalMaterialSink/InternalMaterialSinkArea';
import { InternalSourceArea } from '../InternalMaterialSource/InternalMaterialSourceArea';
import { InventoryLedgerPage } from '../InventoryLedger/InventoryLedgerPage';
import { LoginPage } from '../Login';
import { MaterialClassArea } from '../MaterialClass/MaterialClassArea';
import { MaterialClassSetConversionPage } from '../MaterialClass/MaterialClassSetConversionPage';
import { MaterialClassSetArea } from '../MaterialClassSet/MaterialClassSetArea';
import { msalInstance } from '../Microsoft/msalConfig';
import { GetMsalAccessToken } from '../Microsoft/msalUtil';
import { ProcessArea } from '../Process/ProcessArea';
import { ProductionArea } from '../Production/ProductionArea';
import { RecoveryGoalArea } from '../RecoveryGoal/RecoveryGoalArea';
import RecoveryStrategyArea from '../RecoveryStrategy/RecoveryStrategyArea';
import RecoveryStrategySimulationArea from '../RecoveryStrategySimulation/RecoveryStrategySimulationArea';
import { SampleArea } from '../Sample/SampleArea';
import { SamplingSessionArea } from '../SamplingSuite/SamplingSessionArea';
import { SchupanApp } from '../Schupan/SchupanApp';
import { SortSystemArea } from '../SortSystem/SortSystemsArea';
import { The404 } from '../The404';
import { TruckLoadArea } from '../TruckLoad/TruckLoadArea';
import { VendorArea } from '../Vendor/VendorArea';
import darkBoldTheme from '../echarts/echartsDarkBoldTheme';
import grayTheme from '../echarts/echartsGrayTheme';
import macaronsTheme from '../echarts/echartsMacaronsTheme';
import { queryClient } from '../queryClient';
import { OpenAPI } from '../rest-client';
import { Router } from '../router';
import cssClasses from './App.module.css';
import { AppHeader } from './AppHeader';
import { AppNavbar } from './AppNavbar';
import { AppPage } from './AppPage';
import { TenantContextProvider, useTenantContext } from './TenantContext';
import { useFacilityStore } from './facilityStore';

dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);

OpenAPI.TOKEN = GetMsalAccessToken;

echarts.use(CanvasRenderer);
echarts.registerTheme('gray', grayTheme);
echarts.registerTheme('dark-bold', darkBoldTheme);
echarts.registerTheme('macarons', macaronsTheme);

function AppShellErrorFallback({ resetError }: FallbackProps) {
  const location = useLocation();

  useEffect(() => {
    resetError();
  }, [location, resetError]);

  return (
    <AppPage>
      <Center className={cssClasses.errorWrapper}>
        <Alert color='red' title='Error'>
          <Stack>
            Something went wrong.
            <Button onClick={resetError}>Try Again</Button>
          </Stack>
        </Alert>
      </Center>
    </AppPage>
  );
}

function AppMainErrorFallback({ resetError }: FallbackProps) {
  const location = useLocation();

  useEffect(() => {
    resetError();
  }, [location, resetError]);

  return (
    <AppPage>
      <Alert color='red' title='Error'>
        <Stack justify='flex-start'>
          Something went wrong loading this page.
          <Button onClick={resetError}>Try Again</Button>
        </Stack>
      </Alert>
    </AppPage>
  );
}

export function Providers(props: {
  theme: MantineThemeOverride;
  colorScheme: ColorScheme;
  toggleColorScheme: () => void;
  children: ReactNode;
}) {
  const { theme, colorScheme, toggleColorScheme, children } = props;
  return (
    <MsalProvider instance={msalInstance}>
      <DatesProvider
        settings={{ locale: 'en', firstDayOfWeek: 1, weekendDays: [0, 6] }}
      >
        <QueryClientProvider client={queryClient}>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              theme={theme}
              withGlobalStyles
              withNormalizeCSS
              withCSSVariables
            >
              <Notifications />
              <ModalsProvider>{children}</ModalsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </QueryClientProvider>
      </DatesProvider>
    </MsalProvider>
  );
}

function TopLevelArea() {
  const route = Router.useRoute([
    'LedgerHistory',
    'ProductionArea',
    'ProcessArea',
    'FeedFlowGroupArea',
    'SortSystemArea',
    'RecoveryGoalArea',
    'RecoveryStrategyArea',
    'RecoveryStrategySimulationArea',
    'ContainerArea',
    'TruckLoadArea',
    'InternalSourceArea',
    'InternalSinkArea',
    'VendorArea',
    'CustomerArea',
    'CommodityArea',
    'MaterialClassArea',
    'MaterialClassSetConversionDetail',
    'SampleArea',
    'MaterialClassSetArea',
    'FacilityInsightsArea',
    'SamplingSessionArea',
  ]);

  useEffect(() => {
    if (route === undefined) {
      Router.push('FacilityInsightsDashboard');
    }
  }, [route]);

  // TODO can this be moved to the router module?
  return match(route)
    .with({ name: 'LedgerHistory' }, () => <InventoryLedgerPage />)
    .with({ name: 'ProductionArea' }, () => <ProductionArea />)
    .with({ name: 'ProcessArea' }, () => <ProcessArea />)
    .with({ name: 'FeedFlowGroupArea' }, () => <FeedFlowGroupArea />)
    .with({ name: 'SortSystemArea' }, () => <SortSystemArea />)
    .with({ name: 'RecoveryGoalArea' }, () => <RecoveryGoalArea />)
    .with({ name: 'RecoveryStrategyArea' }, () => <RecoveryStrategyArea />)
    .with({ name: 'RecoveryStrategySimulationArea' }, () => (
      <RecoveryStrategySimulationArea />
    ))
    .with({ name: 'ContainerArea' }, () => <ContainerArea />)
    .with({ name: 'TruckLoadArea' }, () => <TruckLoadArea />)
    .with({ name: 'InternalSourceArea' }, () => <InternalSourceArea />)
    .with({ name: 'InternalSinkArea' }, () => <InternalSinkArea />)
    .with({ name: 'VendorArea' }, () => <VendorArea />)
    .with({ name: 'CustomerArea' }, () => <CustomerArea />)
    .with({ name: 'CommodityArea' }, () => <CommodityArea />)
    .with({ name: 'MaterialClassArea' }, () => <MaterialClassArea />)
    .with(
      { name: 'MaterialClassSetConversionDetail' },
      ({ params: { sourceMaterialClassSetId, targetMaterialClassSetId } }) => (
        <MaterialClassSetConversionPage
          sourceMaterialClassSetId={sourceMaterialClassSetId}
          targetMaterialClassSetId={targetMaterialClassSetId}
        />
      ),
    )
    .with({ name: 'SampleArea' }, () => <SampleArea />)
    .with({ name: 'MaterialClassSetArea' }, () => <MaterialClassSetArea />)
    .with({ name: 'FacilityInsightsArea' }, () => <FacilityInsightsArea />)
    .with({ name: 'SamplingSessionArea' }, () => <SamplingSessionArea />)
    .with(undefined, () => <The404 />)
    .exhaustive();
}

function ValiSortApp() {
  const facility = useFacilityStore((s) => s.facility);
  return (
    <AppShell
      navbar={<AppNavbar />}
      header={<AppHeader />}
      // TODO: Use aside to show help/info
      styles={(theme) => ({
        main: { backgroundColor: theme.colors.gray[0] },
      })}
    >
      {facility ? (
        <ErrorBoundary fallback={AppMainErrorFallback}>
          <TopLevelArea />
        </ErrorBoundary>
      ) : (
        <AppPage>
          <AppPage.Section>
            <Skeleton visible className={cssClasses.loadingSkeleton} />
          </AppPage.Section>
        </AppPage>
      )}
    </AppShell>
  );
}

function assumeTenant(tenantId: number) {
  console.info(`Assuming tenant id ${tenantId}`);
  OpenAPI.HEADERS = {
    'Valis-Assume-Tenant-Id': `${tenantId}`,
  };
  void queryClient.resetQueries();
}

function AssumeTenantSelector() {
  const [tenantId, setTenantId] = useState<number | ''>('');

  return (
    <Stack>
      <NumberInput
        autoFocus
        value={tenantId}
        onChange={(e) => setTenantId(e)}
        label='VALIS Tenant Id'
      />
      <Button
        fullWidth
        disabled={tenantId === ''}
        onClick={() => {
          if (tenantId === '') return;
          assumeTenant(tenantId);
          modals.closeAll();
        }}
      >
        Assume Tenant
      </Button>
    </Stack>
  );
}

function TenantApp() {
  const tenant = useTenantContext();

  // TODO: If user is valis employee, enable keyboard shortcut to assume tenant
  const valisMsftTenantId = '974f33eb-4ac1-4bea-ad31-fb462bbde74a';
  const allMsalAccounts = msalInstance.getAllAccounts();
  const isValisEmployee = allMsalAccounts.some(
    (a) => a.tenantId === valisMsftTenantId,
  );

  useHotkeys([
    [
      'mod+alt+shift+t',
      () => {
        if (!isValisEmployee) return;
        modals.open({
          title: 'Assume Tenant',
          children: <AssumeTenantSelector />,
        });
      },
    ],
  ]);

  if (tenant.tenantId === 2) {
    return <SchupanApp />;
  }

  return <ValiSortApp />;
}

function MainApp() {
  return (
    <TenantContextProvider>
      <TenantApp />
    </TenantContextProvider>
  );
}

export default function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value ?? (colorScheme === 'dark' ? 'light' : 'dark'));

  const theme: MantineThemeOverride = {
    colorScheme,
    primaryColor: 'teal',
    components: {
      Container: {
        defaultProps: {
          sizes: {
            xl: 1550,
          },
        },
      },
    },
  };

  return (
    <React.StrictMode>
      <Providers
        theme={theme}
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <ErrorBoundary fallback={AppShellErrorFallback}>
          <AuthWrapper />
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </Providers>
    </React.StrictMode>
  );
}

function AuthWrapper() {
  return (
    <>
      <AuthenticatedTemplate>
        <MainApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}
