import { DndContext, DragOverlay } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import {
  ActionIcon,
  Button,
  Group,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconMenuOrder, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { AppPage } from '../App/AppPage';
import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { EditIcon, SaveIcon } from '../Icons';
import { MaterialClassName } from '../MaterialClass/MaterialClassName';
import {
  useMaterialClassSet,
  usePatchMaterialClassSet,
} from '../api/materialClassSet';
import { LabeledValue } from '../common';
import { MaterialClassDTO, MaterialClassSetDTO } from '../rest-client';
import { Router } from '../router';
import { DeleteMaterialClassSetButton } from './DeleteMaterialClassSetButton';
import {
  MaterialClassItem,
  SortableMaterialClassItemContainer,
} from './MaterialClassSetForm';

export function MaterialClassSetDetailPage(props: {
  materialClassSetId: string;
}) {
  const { materialClassSetId } = props;
  const {
    data: set,
    isLoadingError,
    error,
  } = useMaterialClassSet(materialClassSetId);

  if (isLoadingError) {
    throw error;
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          title: 'Material Classes',
          routeName: Router.MaterialClassList(),
        },
        {
          title: 'Sets',
          routeName: Router.MaterialClassList(),
        },
      ]}
      title={set?.name ?? null}
    >
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Material Class Set Details</Title>
            <DeleteMaterialClassSetButton
              materialClassSetId={materialClassSetId}
            />
          </Group>
          <LabeledValue label='Name'>
            {set ? <MaterialClassSetEditableName set={set} /> : 'Loading...'}
          </LabeledValue>
          <LabeledValue label='Included Material Classes'>
            {set ? (
              <InlineEditableMaterialClassSetClassList materialClassSet={set} />
            ) : (
              'Loading...'
            )}
          </LabeledValue>
        </Stack>
      </AppPage.Section>
      {/* TODO(2319): Show all sample analyses that used this material class set */}
    </AppPage>
  );
}

function MaterialClassSetClassListInlineEdit(props: {
  materialClassSet: MaterialClassSetDTO;
  onClose: () => void;
}) {
  const { materialClassSet, onClose } = props;
  const materialClassLookup = new Map(
    materialClassSet.materialClasses.map((mc) => [mc.id, mc]),
  );
  const [activeMaterialClass, setActiveMaterialClass] =
    useState<MaterialClassDTO | null>(null);

  const [newOrder, setNewOrder] = useState(
    materialClassSet.materialClasses.map((c) => c.id),
  );

  const patchMutation = usePatchMaterialClassSet();

  if (patchMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Updating Material Class Set Order'
        entityName='Material Class Set Order'
        mutation={patchMutation}
        formVariant='edit'
      />
    );
  }

  return (
    <Stack>
      <DndContext
        onDragStart={(s) =>
          setActiveMaterialClass(
            materialClassLookup.get(s.active.id as string) ?? null,
          )
        }
        onDragEnd={({ active, over }) => {
          if (!over) return;
          if (active.id !== over.id) {
            setNewOrder((ids) => {
              const oldIdx = ids.indexOf(active.id as string);
              const newIdx = ids.indexOf(over.id as string);
              return arrayMove(ids, oldIdx, newIdx);
            });
          }
        }}
      >
        <SortableMaterialClassItemContainer
          id='container' // doesn't matter because there is only one of these for the edit case
          materialClassIds={newOrder}
          materialClassLookup={materialClassLookup}
        />
        <DragOverlay>
          {activeMaterialClass ? (
            <MaterialClassItem
              materialClass={activeMaterialClass}
              shadow='xl'
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      <Group>
        <ActionIcon onClick={() => onClose()}>
          <IconX />
        </ActionIcon>
        <Button
          loading={patchMutation.isLoading}
          onClick={() => {
            patchMutation.mutate(
              {
                id: materialClassSet.id,
                patch: {
                  order: newOrder,
                },
              },
              {
                onSuccess() {
                  showNotification({
                    title: 'Material Class Order Updated',
                    message: `The material class order was successfuly updated.`,
                    color: 'green',
                    icon: <IconCheck />,
                  });
                  onClose();
                },
                onError: () => {
                  showNotification({
                    title: 'Error Updating Material Class Order',
                    message:
                      'An error occurred updating the material class order. You changes may have not applied.',
                    color: 'red',
                    icon: <IconX />,
                  });
                },
              },
            );
          }}
        >
          Save Order
        </Button>
      </Group>
    </Stack>
  );
}

function InlineEditableMaterialClassSetClassList(props: {
  materialClassSet: MaterialClassSetDTO;
}) {
  const { materialClassSet } = props;

  const [editing, setIsEditing] = useState(false);

  if (editing) {
    return (
      <MaterialClassSetClassListInlineEdit
        materialClassSet={materialClassSet}
        onClose={() => setIsEditing(false)}
      />
    );
  }

  return (
    <Group>
      {materialClassSet.materialClasses.map((mc) => (
        <MaterialClassName key={mc.id} id={mc.id} name={mc.name} />
      ))}
      <Button
        variant='outline'
        leftIcon={<IconMenuOrder />}
        color='blue'
        onClick={() => setIsEditing((e) => !e)}
      >
        Reorder
      </Button>
    </Group>
  );
}

function MaterialClassSetEditableName(props: { set: MaterialClassSetDTO }) {
  const { set } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(set.name);

  const patchMutation = usePatchMaterialClassSet();

  if (patchMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Updating Material Class Set Name'
        entityName='Material Class Set Name'
        mutation={patchMutation}
        formVariant='edit'
      />
    );
  }

  if (isEditing) {
    return (
      <div style={{ display: 'inline-flex' }}>
        <TextInput
          value={newName}
          onChange={(v) => setNewName(v.currentTarget.value)}
          placeholder='Name'
        />
        <ActionIcon
          disabled={!patchMutation.isIdle || newName === set.name}
          loading={patchMutation.isLoading}
        >
          <SaveIcon
            onClick={() => {
              patchMutation.mutate(
                {
                  id: set.id,
                  patch: {
                    name: newName,
                  },
                },
                {
                  onError() {
                    showNotification({
                      title: 'Error Updating Name',
                      message:
                        'An error occurred updating the material class set name.',
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'Name Updated',
                      message:
                        'The material class set name has been updated successfully.',
                      color: 'green',
                      icon: <IconCheck />,
                    });
                    patchMutation.reset();
                    setIsEditing(false);
                  },
                },
              );
            }}
          />
        </ActionIcon>
        <ActionIcon
          loading={!patchMutation.isIdle}
          onClick={() => {
            setIsEditing(false);
            setNewName(set.name);
          }}
        >
          <IconX />
        </ActionIcon>
      </div>
    );
  }

  return (
    <div style={{ display: 'inline-flex' }}>
      {set.name}
      <ActionIcon onClick={() => setIsEditing(true)}>
        <EditIcon />
      </ActionIcon>
    </div>
  );
}
