import { Alert, Flex, FlexProps } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { usePatchSamplingSuiteCapture } from '../../api/samplingSuite';
import {
  SamplingSuiteCaptureDTO,
  SamplingSuiteCapturePatchDTO,
} from '../../rest-client';
import { CaptureLightbox } from '../CaptureLightbox';
import { useCaptureLightboxStore } from '../CaptureLightboxStore';
import { CapturePreview } from '../SamplingSuiteCapture';
import { useSamplingSuiteAnalysisContext } from './SamplingSuiteAnalysisContext';

export function UnassignedCaptureTable(
  props: {
    captures: SamplingSuiteCaptureDTO[];
    activeMaterialClass: string | null;
  } & Omit<FlexProps, 'direction'>,
) {
  const { captures, activeMaterialClass, ...flexProps } = props;

  if (captures.length === 0) {
    return (
      <Alert color='teal' icon={<IconCheck />}>
        No unassigned captures.
      </Alert>
    );
  }

  return (
    <Flex direction={'column'} gap='sm' {...flexProps}>
      {captures.map((c) => (
        <UnassignedCaptureRow
          key={c.captureId}
          unassignedCapture={c}
          activeMaterialClass={activeMaterialClass}
        />
      ))}
    </Flex>
  );
}

function UnassignedCaptureRow(props: {
  unassignedCapture: SamplingSuiteCaptureDTO;
  activeMaterialClass: string | null;
}) {
  const { unassignedCapture, activeMaterialClass } = props;
  const { samplingSuiteSampleAnalysisId } = useSamplingSuiteAnalysisContext();

  const patchMutation = usePatchSamplingSuiteCapture();
  const openLightbox = useCaptureLightboxStore((s) => s.open);

  return (
    <CapturePreview
      // TODO: Class name
      // className={classes.linkedCapturePreview}
      capture={unassignedCapture}
      onMaximize={() => openLightbox('unassigned', unassignedCapture.captureId)}
      showWetDryToggle={false}
      leftButtonProps={
        activeMaterialClass === undefined
          ? undefined
          : {
              // TODO: Class name
              // className: classes.assignButton,
              color: 'blue',
              onClick: () => {
                const patch: SamplingSuiteCapturePatchDTO = {
                  sampleAnalysisLink: {
                    samplingSuiteSampleAnalysisId,
                    materialClassAssignment: {
                      materialClassId: activeMaterialClass,
                    },
                  },
                };
                patchMutation.mutate(
                  { capture: unassignedCapture, patch },
                  {
                    // TODO: error/success actions
                  },
                );
              },
              label: 'assign',
            }
      }
      rightButtonProps={{
        color: 'orange',
        onClick: () => {
          const patch: SamplingSuiteCapturePatchDTO = {
            sampleAnalysisLink: null,
          };
          patchMutation.mutate(
            { capture: unassignedCapture, patch },
            {
              // TODO: error/success actions
            },
          );
        },
        label: 'unlink',
      }}
    />
  );
}

export function UnassignedCapturesLightbox(props: {
  captures: SamplingSuiteCaptureDTO[];
}) {
  const { captures } = props;

  const opened = useCaptureLightboxStore((s) => s.opened);
  const select = useCaptureLightboxStore((s) => s.select);
  const onClose = useCaptureLightboxStore((s) => s.close);
  const captureId = useCaptureLightboxStore((s) => s.captureId);

  return (
    <CaptureLightbox
      captures={captures}
      selectedCaptureId={captureId}
      onCaptureSelect={select}
      titleFn={() => 'Staged Capture'}
      opened={opened === 'unassigned'}
      onClose={onClose}
    />
  );
}
