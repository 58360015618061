import { Loader, Table, Text, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { match, P } from 'ts-pattern';
import { LinkText } from '../Link';
import { TableEmptyBasicContent } from '../Table/TableEmptyBasicContent';
import { useCommodities } from '../api/commodity';
import { Router } from '../router';
import { Price } from '../util/Money';

export function CommodityTable() {
  const theme = useMantineTheme();
  const {
    data: commodities,
    isLoading,
    isLoadingError,
    error,
  } = useCommodities();

  if (isLoadingError) {
    throw error;
  }

  const isEmpty = commodities && commodities.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>No Commodities</TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
          <th style={{ textAlign: 'right' }}>Current Price</th>
          <th>Date Priced</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {commodities?.map(
          ({ id, name, currentCommoditySpotPrice, description }) => (
            <tr key={id}>
              <td>
                <LinkText to={Router.CommodityDetail({ commodityId: id })}>
                  {name}
                </LinkText>
              </td>
              <td style={{ textAlign: 'right' }}>
                {match(currentCommoditySpotPrice)
                  .with(null, () => <Text c='dimmed'>none</Text>)
                  .otherwise(({ usdPerUnitOfWeight, weightUnit }) => (
                    <Price
                      usdPerWeightUnit={usdPerUnitOfWeight}
                      weightUnit={weightUnit}
                    />
                  ))}
              </td>
              <td>
                {match(currentCommoditySpotPrice)
                  .with(null, () => <Text c='dimmed'>none</Text>)
                  .otherwise(({ pricedAt }) => (
                    <Text>{dayjs(pricedAt).format('L')}</Text>
                  ))}
              </td>
              <td>
                {match(description)
                  .with(null, '', () => <Text c='dimmed'>none</Text>)
                  .with(P.string, (desc) => <Text>{desc}</Text>)
                  .exhaustive()}
              </td>
            </tr>
          ),
        ) ?? (
          <tr>
            <td colSpan={1}>
              <Loader />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
