import { Box, Grid, Paper, Skeleton, Stack, Text, Title } from '@mantine/core';
import { ReactFlowProvider } from 'reactflow';
import { AppPage } from '../App/AppPage';
import { useFacilityContext } from '../Facility/FacilityContext';
import { AllFeedFlowGroupsTable } from '../FeedFlowGroup/FeedFlowGroupTable';
import { SortSystemName } from '../SortSystem/SortSystemName';
import { useDetailedProcesses } from '../api/process';
import { LabeledValue } from '../common';
import { ProcessName } from './ProcessName';
import { ProcessTopologyDiagram } from './ProcessTopologyDiagram';

export function ProcessesPage() {
  const facility = useFacilityContext();
  const { isLoading, data: processes } = useDetailedProcesses(facility.id);

  if (isLoading) {
    return (
      <Paper p='md' shadow='md'>
        <Skeleton visible h={600} />
      </Paper>
    );
  }

  return (
    <AppPage title='Processes'>
      {processes?.map((process) => (
        <AppPage.Section key={process.id}>
          <Title order={2}>
            <ProcessName process={process} />
          </Title>
          <Grid>
            <Grid.Col span={4}>
              <Stack>
                <Title order={3}>Process Details</Title>
                <LabeledValue
                  label={
                    process.sortSystems.length === 1
                      ? 'Sort System'
                      : 'Sort Systems'
                  }
                >
                  <Stack>
                    {process.sortSystems.map((ss) => (
                      <SortSystemName sortSystem={ss} key={ss.id} />
                    ))}
                  </Stack>
                </LabeledValue>

                <Stack spacing='xs'>
                  <Text c='dimmed'>Process Layout</Text>
                  <Paper withBorder p='md'>
                    <ReactFlowProvider>
                      <Skeleton visible={isLoading} height={250} width='100%'>
                        <ProcessTopologyDiagram process={process} />
                      </Skeleton>
                    </ReactFlowProvider>
                  </Paper>
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col span={8}>
              <Box h='100%'>
                <Stack h='100%'>
                  <Title order={3}>Process Runs</Title>
                  <AllFeedFlowGroupsTable
                    processId={process.id}
                    pageSize={5}
                    defaultOutputContainersFiltered
                  />
                </Stack>
              </Box>
            </Grid.Col>
          </Grid>
        </AppPage.Section>
      ))}
    </AppPage>
  );
}
