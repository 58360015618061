import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateMaterialClass } from '../api/materialClass';
import { MaterialClassCreationDTO } from '../rest-client';
import {
  MaterialClassFormFields,
  MaterialClassFormValues,
  useMaterialClassForm,
} from './MaterialClassForm';

export type AddMaterialClassDrawerFormProps = {
  onSuccess?: (materialClass: MaterialClassCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddMaterialClassDrawerForm(
  props: AddMaterialClassDrawerFormProps,
) {
  const { onSuccess, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateMaterialClass();
  const form = useMaterialClassForm();
  const [id, setId] = useState(uuidv4);

  const formValuesToDto = ({
    name,
    shortName,
    description,
  }: MaterialClassFormValues) => {
    const materialClass: MaterialClassCreationDTO = {
      id,
      name,
      shortName,
      description: description === '' ? null : description,
    };
    setId(uuidv4());
    return materialClass;
  };

  return (
    <DrawerForm
      entityName='Material Class'
      form={form}
      formVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      {...drawerFormDrawerProps}
    >
      <MaterialClassFormFields form={form} />
    </DrawerForm>
  );
}
