import {
  Group,
  Loader,
  Stack,
  Switch,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { useState } from 'react';
import { renderToString } from 'react-dom/server';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { CompositionChart } from '../CompositionChart';
import {
  CompositionComparisonChart,
  FormatterParams,
} from '../CompositionComparisonChart';
import { useFacilityContext } from '../Facility/FacilityContext';
import { LinkText } from '../Link';
import { SamplingSuiteCaptureThumbnail } from '../SamplingSuite/Analysis/SamplingSuiteCaptureOld';
import Temporal from '../Temporal/temporal';
import NetWeight from '../Weights/NetWeight';
import { useContainerWeighedAndSampledConstituents } from '../api/container';
import { LabeledValue } from '../common';
import {
  MaterialClassSetWeighedAndSampledConstituentsDTO,
  NetWeightDTO,
} from '../rest-client';
import { Router } from '../router';
import { composition } from '../util/mixture';

const percentageFormatter = (val: number | null) =>
  `${val === null ? 'unknown ' : (val * 100).toFixed(1)}%`;

function formatWeightedMaterialClassLong(
  sampleTime: string,
  tz: string,
): string {
  return Temporal.Instant.from(sampleTime)
    .toZonedDateTimeISO(tz)
    .toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
}

function formatWeightedMaterialClassShort(
  sampleTime: string,
  tz: string,
): string {
  return Temporal.Instant.from(sampleTime)
    .toZonedDateTimeISO(tz)
    .toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
}

export function ContainerWeighedAndSampledConstituents(props: {
  containerId: string;
  instant: Temporal.Instant | null;
}) {
  const { containerId, instant } = props;
  const query = useContainerWeighedAndSampledConstituents({
    containerId,
    instant,
  });

  if (query.data) {
    const result = query.data;
    if (result.status === 'empty') return null;
    if (result.status === 'ledger-error') return null;
    const { materialSetWeighedAndSampledConstituents } = result;

    const {
      unmeasuredConstituents,
      materialClassSetWeighedAndSampledConstituents,
      measuredAggregateWeight,
    } = materialSetWeighedAndSampledConstituents;

    if (materialClassSetWeighedAndSampledConstituents.length === 0) return null;

    const weightedCompositionCharts =
      materialClassSetWeighedAndSampledConstituents.map((wc) => (
        <WeightedCompositionChart
          key={wc.materialClassSet.id}
          weightedComposition={wc}
          measuredAggregateWeight={measuredAggregateWeight}
        />
      ));

    return (
      <AppPage.Section>
        <Stack>
          <Title order={2}>Composition</Title>
          <Group>
            <LabeledValue label='Sampled Constituents'>
              {materialClassSetWeighedAndSampledConstituents
                .map((c) => c.weighedAndSampledMaterialSets.length)
                .reduce((a, b) => a + b, 0)}
            </LabeledValue>
            <LabeledValue label='Unsampled Constituents'>
              {unmeasuredConstituents.length}
            </LabeledValue>
          </Group>
          {weightedCompositionCharts}
        </Stack>
      </AppPage.Section>
    );
  }

  if (query.isLoading) {
    return <Loader />;
  }

  throw query.error;
}

function CompositionChartTooltipContent({
  params,
  tz,
}: {
  params: FormatterParams[];
  tz: string;
}) {
  const listContent = params.map((param) => {
    return (
      <>
        <span>
          <span
            style={{
              verticalAlign: 'middle',
              display: 'inline-block',
              height: '10px',
              width: '10px',
              marginRight: '6px',
              borderRadius: '5px',
              backgroundColor: param.color,
            }}
          />
          {formatWeightedMaterialClassLong(param.seriesName, tz)}:{' '}
          <strong>{percentageFormatter(param.value)}</strong>
        </span>
        <br />
      </>
    );
  });

  return (
    <>
      <p>{params[0].name}</p>
      {listContent}
    </>
  );
}

export function WeightedCompositionChart(props: {
  weightedComposition: MaterialClassSetWeighedAndSampledConstituentsDTO;
  measuredAggregateWeight: NetWeightDTO;
}) {
  const { weightedComposition, measuredAggregateWeight } = props;
  const { timeZoneId: tz } = useFacilityContext();
  const [individual, setIndividual] = useState(false);

  const materialClassNameLookup = new Map(
    weightedComposition.materialClassSet.materialClasses.map((mc) => [
      mc.id,
      mc.name,
    ]),
  );

  const comp = composition(
    Object.entries(weightedComposition.weightedMaterialClassComposition).map(
      ([mcId, p]) => [materialClassNameLookup.get(mcId) ?? mcId, p] as const,
    ),
  );

  const individualComps = weightedComposition.weighedAndSampledMaterialSets.map(
    (wsms) => {
      return {
        name: wsms.sampleAnalysis.sampleTime,
        composition: composition(
          Object.entries(wsms.materialClassMassComposition).map(
            ([mcId, ratio]) =>
              [materialClassNameLookup.get(mcId) ?? mcId, ratio] as const,
          ),
        ),
      };
    },
  );

  return (
    <Stack>
      <Switch
        ml='auto'
        label='Compare Individual Samples'
        checked={individual}
        onChange={(e) => setIndividual(e.currentTarget.checked)}
      />
      {individual ? (
        <CompositionComparisonChart
          compositions={individualComps}
          seriesLabelEnabled={false}
          tooltipFormatter={(paramsArr) =>
            renderToString(
              <CompositionChartTooltipContent params={paramsArr} tz={tz} />,
            )
          }
          compositionNameChartFormatter={(seriesNameStr) =>
            formatWeightedMaterialClassShort(seriesNameStr, tz)
          }
        />
      ) : (
        <CompositionChart composition={comp} rotateXAxisLabels />
      )}
      <Table>
        <thead>
          <tr>
            {individual && (
              <>
                <th>Sample Taken</th>
                <th>Image</th>
              </>
            )}
            <th style={{ textAlign: 'right' }}>Weight</th>
            {weightedComposition.materialClassSet.materialClasses.map((mc) => (
              <th key={mc.id} style={{ textAlign: 'right' }}>
                {mc.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {individual &&
            weightedComposition.weighedAndSampledMaterialSets.map((wsms) => (
              <tr key={wsms.materialSet.hash}>
                <td>
                  <LinkText
                    to={Router.SampleDetail({
                      sampleId: wsms.sampleAnalysis.sampleId,
                    })}
                  >
                    {formatWeightedMaterialClassLong(
                      wsms.sampleAnalysis.sampleTime,
                      tz,
                    )}
                  </LinkText>
                </td>
                <td>
                  {match(wsms.sampleAnalysis)
                    .with({ type: 'manual' }, () => (
                      <Text c='dimmed'>manual</Text>
                    ))
                    .with({ type: 'sampling-suite' }, (sample) =>
                      sample.wholeSampleCaptures === null ||
                      sample.wholeSampleCaptures.captures.length === 0 ? (
                        <LinkText
                          to={Router.SampleDetail({
                            sampleId: wsms.sampleAnalysis.sampleId,
                          })}
                        >
                          multiple
                        </LinkText>
                      ) : (
                        <SamplingSuiteCaptureThumbnail
                          capture={sample.wholeSampleCaptures.captures[0]}
                        />
                      ),
                    )
                    .exhaustive()}
                </td>
                <td style={{ textAlign: 'right' }}>
                  <NetWeight
                    weight={wsms.netWeight}
                    sourceIconMode='icon-tooltip'
                  />
                </td>
                {weightedComposition.materialClassSet.materialClasses.map(
                  (mc) => (
                    <td key={mc.id} style={{ textAlign: 'right' }}>
                      {(100 * wsms.materialClassMassComposition[mc.id]).toFixed(
                        2,
                      )}
                      %
                    </td>
                  ),
                )}
              </tr>
            ))}
          <tr>
            {individual && (
              <>
                <th>Total</th>
                <td></td>
              </>
            )}
            <td
              style={{
                textAlign: 'right',
                fontWeight: individual ? 'bold' : undefined,
              }}
            >
              <NetWeight
                weight={measuredAggregateWeight}
                sourceIconMode='icon-tooltip'
              />
            </td>
            {weightedComposition.materialClassSet.materialClasses.map((mc) => (
              <td
                key={mc.id}
                style={{
                  textAlign: 'right',
                  fontWeight: individual ? 'bold' : undefined,
                }}
              >
                {(
                  100 *
                  weightedComposition.weightedMaterialClassComposition[mc.id]
                ).toFixed(2)}
                %
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </Stack>
  );
}
