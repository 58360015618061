import {
  ActionIcon,
  ActionIconProps,
  Alert,
  Flex,
  Group,
  Paper,
  SegmentedControl,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconArrowRight,
  IconDroplet,
  IconDropletX,
  IconMaximize,
} from '@tabler/icons-react';
import { ReactNode, useCallback } from 'react';
import { useCustomTrays } from '../api/customTray';
import { usePatchSamplingSuiteCapture } from '../api/samplingSuite';
import { LabeledValue } from '../common';
import { CustomTrayDTO, SamplingSuiteCaptureDTO } from '../rest-client';
import { useLowResCaptureObjectUrl } from '../samplingSuiteCaptureImageCache';
import GrossWeight from '../Weights/GrossWeight';
import NetWeight from '../Weights/NetWeight';
import TareWeight from '../Weights/TareWeight';
import classes from './SamplingSuiteCapture.module.css';
1;

const previewImageHeight = 150;
const previewImageWidth = 224.517;

interface CaptureButtonProps {
  className?: string;
  onClick: () => void;
  color?: ActionIconProps['color'];
  icon?: ReactNode;
  label?: string;
}

export function CapturePreview(props: {
  capture: SamplingSuiteCaptureDTO;
  onMaximize: () => void;
  leftButtonProps?: CaptureButtonProps;
  rightButtonProps?: CaptureButtonProps;
  showWetDryToggle: boolean;
  loading?: boolean;
  className?: string;
  showCustomTraySelect?: boolean;
}) {
  const {
    capture,
    onMaximize,
    leftButtonProps,
    rightButtonProps,
    showWetDryToggle,
    loading,
    className,
    showCustomTraySelect = false,
  } = props;

  const customTraysQuery = useCustomTrays();

  const previewObjectUrl = useLowResCaptureObjectUrl(capture.captureId);

  const { isDried } = capture;

  const patchCaptureMutation = usePatchSamplingSuiteCapture();
  const toggleIsDried = useCallback(() => {
    patchCaptureMutation.mutate({
      capture,
      patch: {
        isDried: !isDried,
      },
    });
  }, [patchCaptureMutation, isDried, capture]);

  const setCustomTray = useCallback(
    (customTray: CustomTrayDTO | null) => {
      patchCaptureMutation.mutate(
        {
          capture,
          patch: { customTrayId: customTray?.id ?? null },
        },
        {
          onError: () => {
            patchCaptureMutation.reset();
          },
        },
      );
    },
    [capture, patchCaptureMutation],
  );

  const preImputeWeightSource =
    capture.netWeight.kind === 'canonical' &&
    capture.netWeight.weightSource.kind === 'sampling-suite-imputed'
      ? capture.netWeight.weightSource
      : undefined;

  const preImputeWeight = preImputeWeightSource?.measuredNetWeight;

  const preImputeWeightGrams = preImputeWeightSource?.measuredNetWeightGrams;
  const outOfBoundsNegativeWeight =
    preImputeWeightGrams !== undefined && preImputeWeightGrams < -5; // TODO: Pull to constant

  return (
    <Flex direction='column' gap={1}>
      <div
        className={`${classes.previewImageContainer} ${isDried ? classes.isDried : ''} ${className ?? ''}`}
      >
        {previewObjectUrl ? (
          <img
            className={classes.previewImg}
            src={previewObjectUrl}
            height={previewImageHeight}
          />
        ) : (
          <CapturePreviewLoader />
        )}

        <Stack
          className={classes.previewImageControls}
          spacing={0}
          align='stretch'
          justify='space-between'
        >
          <Group
            sx={{
              flex: '1 1 50%',
            }}
            noWrap
            align='stretch'
            spacing={0}
            w='100%'
          >
            {leftButtonProps && (
              <ActionIcon
                className={leftButtonProps.className}
                h='100%'
                radius={0}
                variant='filled'
                {...leftButtonProps}
                loading={loading}
                sx={{
                  flexBasis: '30%',
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              >
                <Stack align='center'>
                  {leftButtonProps.icon || <IconArrowLeft />}

                  {leftButtonProps.label && (
                    <Text size='sm'>{leftButtonProps.label}</Text>
                  )}
                </Stack>
              </ActionIcon>
            )}

            <ActionIcon
              h='100%'
              radius={0}
              variant='filled'
              loading={loading}
              sx={{ flexBasis: '100%', flexShrink: 1 }}
              onClick={onMaximize}
            >
              <Stack align='center'>
                <IconMaximize />
                <Text size='sm'>maximize</Text>
              </Stack>
            </ActionIcon>

            {rightButtonProps && (
              <ActionIcon
                className={rightButtonProps.className}
                h='100%'
                radius={0}
                variant='filled'
                {...rightButtonProps}
                loading={loading}
                sx={{
                  flexBasis: '30%',
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              >
                <Stack align='center'>
                  {rightButtonProps.icon || <IconArrowRight />}
                  {rightButtonProps.label && (
                    <Text size='sm'>{rightButtonProps.label}</Text>
                  )}
                </Stack>
              </ActionIcon>
            )}
          </Group>
          {showWetDryToggle ? (
            <ActionIcon
              sx={{
                flex: '1 1 50%',
              }}
              h='100%'
              w='100%'
              color={isDried ? 'yellow' : 'indigo'}
              radius={0}
              variant='filled'
              loading={patchCaptureMutation.isLoading}
              onClick={toggleIsDried}
            >
              <Stack align='center'>
                {capture.isDried ? <IconDropletX /> : <IconDroplet />}
                <Text size='sm'>{capture.isDried ? 'dry' : 'wet'}</Text>
              </Stack>
            </ActionIcon>
          ) : null}
        </Stack>
      </div>
      {showCustomTraySelect &&
      customTraysQuery.data &&
      customTraysQuery.data.length > 0 ? (
        <>
          <SegmentedControl
            styles={(theme) => ({
              root: {
                backgroundColor:
                  capture.customTray === null
                    ? theme.colors['red'][3]
                    : undefined,
              },
            })}
            color={outOfBoundsNegativeWeight ? 'red' : 'blue'}
            fullWidth
            size='xs'
            value={
              patchCaptureMutation.variables?.patch.customTrayId !== undefined
                ? patchCaptureMutation.variables?.patch.customTrayId ?? ''
                : capture.customTray?.id ?? ''
            }
            onChange={(customTrayId) => {
              if (customTrayId === '') {
                setCustomTray(null);
                return;
              }

              const newCustomTray = customTraysQuery.data.find(
                (ct) => ct.id == customTrayId,
              );
              if (newCustomTray) {
                setCustomTray(newCustomTray);
              }
            }}
            data={[
              // { label: <Box c='red'>Tare</Box>, value: '' },
              ...customTraysQuery.data.map((ct) => ({
                label: ct.name,
                value: ct.id,
              })),
            ]}
          />

          {capture.customTray !== null && outOfBoundsNegativeWeight ? (
            <Alert color='red' title='Negative Weight' variant='outline'>
              {preImputeWeight ? (
                <LabeledValue label='Net Weight'>
                  <NetWeight weight={preImputeWeight} />
                </LabeledValue>
              ) : null}
              <LabeledValue label='Gross Weight'>
                <GrossWeight weight={capture.grossWeight} />
              </LabeledValue>
              {capture.customTray !== null ? (
                <LabeledValue label='Tray Tare'>
                  <TareWeight weight={capture.customTray.tareWeight} />
                </LabeledValue>
              ) : null}
            </Alert>
          ) : null}
        </>
      ) : null}
    </Flex>
  );
}

function CapturePreviewLoader() {
  return (
    <Skeleton
      width={`${previewImageWidth}px`}
      height={`${previewImageHeight}px`}
      animate
    />
  );
}

export function CapturePreviewPlaceholder(props: { active: boolean }) {
  const { active } = props;
  return (
    <Paper
      className={classes.capturePreviewPlaceholder}
      w={`${previewImageWidth}px`}
      h={`${previewImageHeight}px`}
      withBorder
    >
      <Stack align='center' justify='center' h='100%'>
        {active ? (
          <Text>awaiting capture</Text>
        ) : (
          <Text c='dimmed' size='sm'>
            capture required
          </Text>
        )}
      </Stack>
    </Paper>
  );
}
