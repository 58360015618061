import { Box, Group, Stack } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppPage } from '../../App/AppPage.tsx';
import { FormActionButton } from '../../Form/FormActionButton.tsx';
import { MutationErrorAlert } from '../../Form/MutationErrorAlert.tsx';
import { useFormNavBlocker } from '../../Form/useFormNavBlocker.ts';
import { SelectedContainerGenealogy } from '../../GenealogyExplorer/SelectedContainerGenealogy.tsx';
import { ShowDetailsToggleButton } from '../../ShowDetailsToggleButton.tsx';
import { useCreateSample } from '../../api/sample.ts';
import {
  ContainerSampleLinkCreationDTO,
  SampleCreationDTO,
} from '../../rest-client/index.ts';
import { Router } from '../../router.ts';
import {
  ContainerSampleFormAlerts,
  ContainerSampleFormFields,
  ContainerSampleFormProps,
  useContainerSampleForm,
} from './ContainerSampleForm.tsx';

export function CreateContainerSamplePage(props: ContainerSampleFormProps) {
  const { containerId, sampleTakenTimestamp } = props;

  const id = useMemo(() => uuidv4(), []);
  const form = useFormNavBlocker(
    useContainerSampleForm({ containerId, sampleTakenTimestamp }),
  );
  const addSampleMutation = useCreateSample();

  const [showContainerDetails, toggleShowContainerDetails] = useToggle();
  const [initialContainerId] = useState(containerId);
  const onCancel = () => {
    if (initialContainerId) {
      Router.push('ContainerDetail', { containerId: initialContainerId });
    } else {
      Router.push('SampleList');
    }
  };

  if (addSampleMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Creating Container Sample'
        entityName='Container Sample'
        mutation={addSampleMutation}
        formVariant='create'
      />
    );
  }

  const showDetailsToggleDisabled = form.values.containerId === null;

  return (
    <AppPage
      breadcrumbs={[{ routeName: Router.SampleList(), title: 'Samples' }]}
      title='Add Container Sample'
    >
      <AppPage.Section>
        <form
          onSubmit={form.onSubmit((values) => {
            if (values.containerId === null) {
              throw new Error('container id must be defined');
            }

            const link: ContainerSampleLinkCreationDTO = {
              type: 'container',
              containerId: values.containerId,
              sampleTakenTimestamp: values.sampleTakenTimestamp
                .utc()
                .toISOString(),
            };

            const sample: SampleCreationDTO = {
              id,
              link,
            };

            addSampleMutation.mutate(sample, {
              onError() {
                showNotification({
                  title: 'Error Creating Container Sample',
                  message: 'An error ocurred creating the container sample',
                  color: 'red',
                  icon: <IconX />,
                });
              },
              onSuccess() {
                Router.replace('SampleDetail', {
                  sampleId: id,
                });
                showNotification({
                  title: 'Container Sample Created',
                  message: `The container sample was successfully created`,
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            });
          })}
        >
          <Stack maw={500}>
            <ContainerSampleFormFields form={form} />
            {form.values.sampleTakenTimestamp !== null &&
            form.values.containerId !== null ? (
              <ContainerSampleFormAlerts
                containerId={form.values.containerId}
                timestamp={form.values.sampleTakenTimestamp}
              />
            ) : undefined}
            <ShowDetailsToggleButton
              value={showContainerDetails}
              onToggle={toggleShowContainerDetails}
              tooltip='View container material data at sample time'
              disabled={showDetailsToggleDisabled}
            />
          </Stack>

          <Group position='right' mt='xs'>
            <FormActionButton
              action='cancel'
              onClick={onCancel}
              loading={addSampleMutation.isLoading}
            />
            <FormActionButton
              type='submit'
              action='saveCreation'
              loading={addSampleMutation.isLoading}
            >
              Record Sample
            </FormActionButton>
          </Group>
        </form>
      </AppPage.Section>
      {form.values.containerId !== null &&
        form.values.sampleTakenTimestamp !== null && (
          <Box style={{ display: showContainerDetails ? 'inline' : 'none' }}>
            <SelectedContainerGenealogy
              containerId={form.values.containerId}
              timestamp={form.values.sampleTakenTimestamp}
            />
          </Box>
        )}
    </AppPage>
  );
}
