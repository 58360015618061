import {
  Box,
  BoxProps,
  HoverCard,
  Skeleton,
  Stack,
  Text,
  TextProps,
  Tooltip,
} from '@mantine/core';
import { IconInfoCircle, IconQuestionMark } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { ReactNode, Ref, forwardRef } from 'react';
import { useFacilityContext } from './Facility/FacilityContext';
import { InferredPropertyWrapper } from './InferredPropertyWrapper';
import cssClasses from './common.module.css';
import { Interval } from './rest-client';
import { WithUnit } from './util/WithUnit';

export function DurationTooltip({
  startIso8601,
  endIso8601,
}: {
  startIso8601: string;
  endIso8601: string | null;
}) {
  const facility = useFacilityContext();
  const timezone = facility.timeZoneId;
  const startTime = dayjs.utc(startIso8601).tz(timezone);

  if (endIso8601 === null) {
    return (
      <Tooltip
        label={<Text>{'Start: ' + startTime.format('LTS')}</Text>}
        openDelay={100}
        position='top-start'
      >
        <Text c='dimmed'>in-progress</Text>
      </Tooltip>
    );
  }
  const endTime = dayjs.utc(endIso8601).tz(timezone);
  const duration = dayjs.duration(endTime.diff(startTime));
  const hoverText = (
    <>
      <Text>{'Start: ' + startTime.format('LTS')}</Text>
      <Text>{'End: ' + endTime.format('LTS')}</Text>
    </>
  );
  return (
    <Tooltip label={hoverText} openDelay={100} position='top-start'>
      <Text>
        {duration.asDays() >= 1
          ? duration.format('D[d] HH[h] mm[m]')
          : duration.asHours() >= 1
            ? duration.format('HH[h] mm[m]')
            : duration.asMinutes() >= 1
              ? duration.format('mm[m] ss[s]')
              : duration.format('ss[s]')}
      </Text>
    </Tooltip>
  );
}

export function MultipleDurationsTooltip({
  intervals,
}: {
  intervals: Interval[];
}) {
  const { timeZoneId } = useFacilityContext();

  const duration = intervals
    .map((interval) =>
      dayjs.duration(
        dayjs
          .utc(interval.end)
          .tz(timeZoneId)
          .diff(dayjs.utc(interval.start).tz(timeZoneId)),
      ),
    )
    .reduce((sum, current) => sum.add(current), dayjs.duration(0));

  const hoverText = (
    <Stack spacing='xs'>
      {intervals.map((interval, i) => (
        <Text key={i}>
          {dayjs.utc(interval.start).tz(timeZoneId).format('LTS')}
          {' - '}
          {dayjs.utc(interval.end).tz(timeZoneId).format('LTS')}
        </Text>
      ))}
    </Stack>
  );

  return (
    <Tooltip label={hoverText} openDelay={100} position='top-start'>
      <Text>
        {duration.asDays() >= 1
          ? duration.format('D[d] HH[h] mm[m]')
          : duration.asHours() >= 1
            ? duration.format('HH[h] mm[m] ss[s]')
            : duration.format('mm[m] ss[s]')}
      </Text>
    </Tooltip>
  );
}

export function CalendarDateTime({ iso8601 }: { iso8601: string }) {
  const facility = useFacilityContext();
  const timezone = facility.timeZoneId;

  const time = dayjs.utc(iso8601).tz(timezone);
  const summarizedTime = time.format('lll');
  const fullTime = time.format('llll z');
  return (
    <Tooltip label={fullTime} openDelay={330}>
      <Text>{summarizedTime}</Text>
    </Tooltip>
  );
}

export function IntradayDateTime({ iso8601 }: { iso8601: string }) {
  const facility = useFacilityContext();
  const timezone = facility.timeZoneId;

  const time = dayjs.utc(iso8601).tz(timezone);
  const summarizedTime = time.format('LT');
  const fullTime = time.format('llll zzz');
  return (
    <Tooltip label={fullTime} openDelay={330}>
      <Text>{summarizedTime}</Text>
    </Tooltip>
  );
}

export type LabeledValueProps = BoxProps & {
  label: ReactNode;
  color?: TextProps['color'];
  infoIconContent?: ReactNode;
};

export const LabeledValue = forwardRef(function LabeledValue(
  props: LabeledValueProps,
  ref: Ref<HTMLDivElement>,
) {
  const {
    label,
    children,
    infoIconContent,
    color = 'dimmed',
    className: classNameProp = '',
    ...other
  } = props;

  const labelContent =
    typeof label === 'string' ? <Text color={color}>{label}</Text> : label;
  return (
    <Box
      ref={ref}
      className={`${classNameProp} ${cssClasses.labeledValueContainer}`}
      {...other}
    >
      <div style={{ display: 'inline-flex' }}>
        {labelContent}
        {infoIconContent && (
          <HoverCard width={280} withinPortal>
            <HoverCard.Target>
              <Text color={color} span ml='0.2ch'>
                <IconInfoCircle
                  size='1.25em'
                  style={{ marginBottom: '-0.25em' }}
                />
              </Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text>{infoIconContent}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </div>
      {children}
    </Box>
  );
});

export const PropertyText = ({
  value,
  unit,
  roundTo,
}: {
  value: number;
  unit: string;
  roundTo: number;
}) => {
  return (
    <WithUnit unitSuffix={unit}>
      <Text>{value.toFixed(roundTo)}</Text>
    </WithUnit>
  );
};

export function InferredPropertyText({
  value,
  unit,
  roundTo,
  isLoading,
  isError,
}: {
  value: number | undefined;
  unit: string;
  roundTo: number;
  isLoading: boolean;
  isError: boolean;
}) {
  if (isError) {
    return <InferredPropertyText.Error unit={unit} />;
  }
  if (isLoading) {
    return <InferredPropertyText.Skeleton unit={unit} />;
  }
  return (
    <InferredPropertyWrapper>
      <WithUnit unitSuffix={unit}>
        {value !== undefined ? (
          <Text>{value.toFixed(roundTo)}</Text>
        ) : (
          <Text color='dimmed'>?</Text>
        )}
      </WithUnit>
    </InferredPropertyWrapper>
  );
}
const InferredPropertySkeleton = ({ unit }: { unit: string }) => (
  <InferredPropertyWrapper>
    <WithUnit unitSuffix={unit}>
      <Skeleton visible>
        <Text>123,456</Text>
      </Skeleton>
    </WithUnit>
  </InferredPropertyWrapper>
);
const InferredPropertyError = ({ unit }: { unit: string }) => (
  <InferredPropertyWrapper>
    <WithUnit unitSuffix={unit}>
      <IconQuestionMark color='red' />
    </WithUnit>
  </InferredPropertyWrapper>
);
InferredPropertyText.Skeleton = InferredPropertySkeleton;
InferredPropertyText.Error = InferredPropertyError;
