import { Button } from '@mantine/core';
import { useDeleteEntityModal } from '../Form/DeleteEntity';
import { DeleteIcon } from '../Icons';
import { useDeleteContainerTransfer } from '../api/containerTransfer';
import { MaterialContainerTransferId } from '../rest-client';

export interface DeleteContainerTransferProps {
  transferId: MaterialContainerTransferId;
}

export function DeleteContainerTransferButton(
  props: DeleteContainerTransferProps,
) {
  const { transferId } = props;

  const deleteMutation = useDeleteContainerTransfer();
  const openDeleteModal = useDeleteEntityModal({
    mutationVariables: transferId,
    deleteMutation,
    entityName: 'Container Material Transfer',
  });

  return (
    <Button
      size='sm'
      compact
      variant='outline'
      leftIcon={<DeleteIcon />}
      color='red'
      onClick={openDeleteModal}
    >
      Delete
    </Button>
  );
}
