import { match } from 'ts-pattern';
import { Router } from '../router';
import { SamplingSuiteSessionPage } from './Analysis/SamplingSuiteSession';

export function SamplingSessionArea() {
  const route = Router.useRoute(['SamplingSessionSession']);
  if (route === undefined) throw new Error('unknown route');

  return match(route)
    .with({ name: 'SamplingSessionSession' }, ({ params: { sessionId } }) => (
      <SamplingSuiteSessionPage sessionId={sessionId} />
    ))
    .exhaustive();
}
