import { TareWeightDTO } from '../rest-client';
import WeightUnitExpectedTotals from './WeightUnitExpectedTotals';

export interface TareWeightProps {
  weight: TareWeightDTO;
}

export default function TareWeight(props: TareWeightProps) {
  const { weight } = props;

  return (
    <WeightUnitExpectedTotals
      expectedUnitTotals={weight.unitExpectedTotals}
      totalIsNegative={weight.totalIsNegative}
    />
  );
}
