import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import {
  ConvertedSampleAnalysisDTO,
  FlattenedSampleDTO,
  FlattenedSampleManualAnalysisDTO,
  FlattenedSampleMetadataDTO,
  FlattenedSampleSuiteAnalysisDTO,
  MaterialClassDTO,
  MaterialClassId,
  MaterialClassSetId,
  ProductTypeDTO,
  Radius3DSParametersDTO,
  SampleNumericId,
} from '../../rest-client';
import Temporal from '../../Temporal/temporal';
import { matrixMean } from '../../util/math';

export type BaseSampleTableStore = {
  filteredRows: SampleTableRow[] | undefined;
  setFilteredRows: (rows: SampleTableRow[]) => void;
  materialClassSetId: MaterialClassSetId | null;
  setMaterialClassSetId: (
    materialClassSetId: MaterialClassSetId | null,
  ) => void;
};

export type SampleStatus = 'complete' | 'in-progress' | 'unanalyzed';

export type SampleTableRowMetadata = Omit<
  FlattenedSampleMetadataDTO,
  | 'sampleNumericId'
  | 'productType'
  | 'isProcessed'
  | 'radius3DSSampleParameters'
> & {
  sampleNumericId: SampleNumericId | null;
  productType: ProductTypeDTO | null;
  isProcessed: boolean | null;
  sampleTakenDate: string;
  status: SampleStatus;
};

export type SampleTableRowAnalysis =
  | (FlattenedSampleSuiteAnalysisDTO & {
      analysisDate: string;
    })
  | (FlattenedSampleManualAnalysisDTO & {
      analysisDate: string;
    });

export type SampleTableRow = {
  metadata: SampleTableRowMetadata;
  analysis: SampleTableRowAnalysis;
  radius3DSParameters: Radius3DSParametersDTO | null;
};

export type SampleTableCommodityGroupRow = {
  commodityName: string;
  materialClassToProportionMap: Record<MaterialClassId, number>;
};

// factored out to use for UnanalyzedSampleTable
export function flattenedSampleMetadataDtoToSampleRowMetadata(
  metadata: FlattenedSampleMetadataDTO,
  timeZoneId: string,
  dateStringFormat: string,
  sampleStatus: SampleStatus,
): SampleTableRowMetadata {
  const row: SampleTableRowMetadata = {
    type: metadata.type,
    sampleId: metadata.sampleId,
    sampleNumericId: metadata.sampleNumericId,
    sampleName: metadata.sampleName,
    sampleTakenTime: metadata.sampleTakenTime,
    sampleTakenDate: Temporal.Instant.from(metadata.sampleTakenTime)
      .toZonedDateTimeISO(timeZoneId)
      .toPlainDate()
      .toString(),
    productType: metadata.productType,
    isProcessed: metadata.isProcessed,
    upstreamSource: metadata.upstreamSource,
    upstreamSourceCommodity: metadata.upstreamSourceCommodity,
    exportDestination: metadata.exportDestination,
    employedRecoveryStrategy: metadata.employedRecoveryStrategy,
    producingProcess: metadata.producingProcess,
    status: sampleStatus,
  };
  return row;
}

const dateStringFormat = 'YYYY-MM-DD';

export function convertedSampleAnalysisToSampleTableRow(
  {
    metadata,
    analysis,
    radius3DSParameters,
    materialClassToNetWeightGramsMap,
    materialClassToProportionMap,
    accumulatedNetWeightGrams,
  }: ConvertedSampleAnalysisDTO,
  timeZoneId: string,
): SampleTableRow {
  const row: SampleTableRow = {
    metadata: flattenedSampleMetadataDtoToSampleRowMetadata(
      metadata,
      timeZoneId,
      dateStringFormat,
      'complete',
    ),
    analysis: {
      type: analysis.type,
      analysisTime: analysis.analysisTime,
      analysisDate: dayjs
        .utc(analysis.analysisTime)
        .tz(timeZoneId)
        .format(dateStringFormat),
      isComplete: true,
      accumulatedNetWeightGrams,
      explicitNetWeightGrams: analysis.explicitNetWeightGrams,
      netWeightDifferenceGrams: analysis.netWeightDifferenceGrams,
      netWeightDifferenceProportion: analysis.netWeightDifferenceProportion,
      materialClassToNetWeightGramsMap,
      materialClassToProportionMap,
      materialClassToCaptureIdsMap: match(analysis)
        .with({ type: 'Manual' }, () => null)
        .with(
          { type: 'SamplingSuite' },
          (suiteAnalysis) => suiteAnalysis.materialClassToCaptureIdsMap,
        )
        .exhaustive(),
      wholeSampleCaptureIds: match(analysis)
        .with({ type: 'Manual' }, () => null)
        .with(
          { type: 'SamplingSuite' },
          (suiteAnalysis) => suiteAnalysis.wholeSampleCaptureIds,
        )
        .exhaustive(),
      moistureNetWeightGrams: analysis.moistureNetWeightGrams,
      moistureProportion: analysis.moistureProportion,
      materialClassToMoistureProportionMap: null,
      materialClassToMoistureNetWeightGramsMap: null,
    },
    radius3DSParameters,
  };
  return row;
}

export function flattenedSampleDtoToSampleTableRow(
  dto: FlattenedSampleDTO,
  timeZoneId: string,
): SampleTableRow {
  const { metadata, analysis, radius3DSParameters } = dto;

  return {
    metadata: flattenedSampleMetadataDtoToSampleRowMetadata(
      metadata,
      timeZoneId,
      dateStringFormat,
      'complete',
    ),
    analysis: {
      type: analysis.type,
      analysisTime: analysis.analysisTime,
      analysisDate: dayjs
        .utc(analysis.analysisTime)
        .tz(timeZoneId)
        .format(dateStringFormat),
      isComplete: analysis.isComplete,
      accumulatedNetWeightGrams: analysis.accumulatedNetWeightGrams,
      explicitNetWeightGrams: analysis.explicitNetWeightGrams,
      netWeightDifferenceGrams: analysis.netWeightDifferenceGrams,
      netWeightDifferenceProportion: analysis.netWeightDifferenceProportion,
      materialClassToNetWeightGramsMap:
        analysis.materialClassToNetWeightGramsMap,
      materialClassToProportionMap: analysis.materialClassToProportionMap,
      materialClassToCaptureIdsMap: match(analysis)
        .with({ type: 'Manual' }, () => null)
        .with(
          { type: 'SamplingSuite' },
          (suiteAnalysis) => suiteAnalysis.materialClassToCaptureIdsMap,
        )
        .exhaustive(),
      wholeSampleCaptureIds: match(analysis)
        .with({ type: 'Manual' }, () => null)
        .with(
          { type: 'SamplingSuite' },
          (suiteAnalysis) => suiteAnalysis.wholeSampleCaptureIds,
        )
        .exhaustive(),
      moistureNetWeightGrams: analysis.moistureNetWeightGrams,
      moistureProportion: analysis.moistureProportion,
      materialClassToMoistureProportionMap:
        analysis.materialClassToMoistureProportionMap,
      materialClassToMoistureNetWeightGramsMap:
        analysis.materialClassToMoistureNetWeightGramsMap,
    },
    radius3DSParameters: radius3DSParameters,
  };
}

// TODO: use aggregateRows for sample aggregation graphs; otherwise, this is currently unused
export const aggregateRows = (
  rows: SampleTableRow[] | undefined,
  materialClasses: MaterialClassDTO[],
) => {
  const emptyMaterialClassMap = Object.fromEntries(
    new Map(materialClasses.map((materialClass) => [materialClass.id, 0])),
  );
  if (rows === undefined) {
    const emptyAggregateRow: SampleTableRow = {
      metadata: {
        type: 'Notional',
        sampleId: '',
        sampleNumericId: null,
        sampleName: '',
        sampleTakenTime: '',
        sampleTakenDate: '',
        productType: null,
        isProcessed: null,
        upstreamSource: null,
        upstreamSourceCommodity: null,
        exportDestination: null,
        employedRecoveryStrategy: null,
        producingProcess: null,
        status: 'complete',
      },
      analysis: {
        type: 'SamplingSuite',
        analysisTime: '',
        analysisDate: '',
        isComplete: false,
        accumulatedNetWeightGrams: 0,
        explicitNetWeightGrams: null,
        netWeightDifferenceGrams: null,
        netWeightDifferenceProportion: null,
        materialClassToNetWeightGramsMap: emptyMaterialClassMap,
        materialClassToProportionMap: emptyMaterialClassMap,
        materialClassToCaptureIdsMap: null,
        wholeSampleCaptureIds: null,
        moistureNetWeightGrams: 0,
        moistureProportion: null,
        materialClassToMoistureProportionMap: null,
        materialClassToMoistureNetWeightGramsMap: null,
      },
      radius3DSParameters: null,
    };
    return emptyAggregateRow;
  }

  const rowCount = rows.length;

  // commodity
  const uniqueCommodities = [
    ...new Set(rows.map((row) => row.metadata.productType)),
  ];
  const commodity =
    uniqueCommodities.length === 1 ? uniqueCommodities[0] : null;

  // material processed
  const uniqueIsProcessedBooleans = [
    ...new Set(rows.map((row) => row.metadata.isProcessed)),
  ];
  const isProcessed =
    uniqueIsProcessedBooleans.length === 1
      ? uniqueIsProcessedBooleans[0]
      : null;

  // upstream material source
  const uniqueInternalMaterialSources = [
    ...new Set(rows.map((row) => row.metadata.upstreamSource)),
  ];
  const internalMaterialSource =
    uniqueInternalMaterialSources.length === 1
      ? uniqueInternalMaterialSources[0]
      : null;

  // upstream material source commodity
  const uniqueSourceCommodities = [
    ...new Set(rows.map((row) => row.metadata.upstreamSourceCommodity)),
  ];
  const sourceCommodity =
    uniqueSourceCommodities.length === 1 ? uniqueSourceCommodities[0] : null;

  // material export destination
  const uniqueInternalMaterialSinks = [
    ...new Set(rows.map((row) => row.metadata.exportDestination)),
  ];
  const internalMaterialSink =
    uniqueInternalMaterialSinks.length === 1
      ? uniqueInternalMaterialSinks[0]
      : null;

  // employed recovery strategy
  const uniqueRecoveryStrategies = [
    ...new Set(rows.map((row) => row.metadata.employedRecoveryStrategy)),
  ];
  const recoveryStrategy =
    uniqueRecoveryStrategies.length === 1 ? uniqueRecoveryStrategies[0] : null;

  // producing process
  const uniqueProcesses = [
    ...new Set(rows.map((row) => row.metadata.producingProcess)),
  ];
  const process = uniqueProcesses.length === 1 ? uniqueProcesses[0] : null;

  // sample completeness
  const allSamplesAreComplete = rows.every((row) => row.analysis.isComplete);

  // mean sample explicit weight
  const explicitWeightsGrams: number[] = rows
    .map((row) => row.analysis.explicitNetWeightGrams)
    .filter((weight): weight is number => !!weight);
  const meanExplicitWeightGrams =
    explicitWeightsGrams.length === 0
      ? null
      : explicitWeightsGrams.reduce((acc, weight) => acc + weight, 0) /
        explicitWeightsGrams.length;

  // mean sample weight difference
  const weightDifferencesGrams: number[] = rows
    .map((row) => row.analysis.netWeightDifferenceGrams)
    .filter((weight): weight is number => !!weight);
  const meanWeightDifferenceGrams =
    weightDifferencesGrams.length === 0
      ? null
      : weightDifferencesGrams.reduce((acc, weight) => acc + weight, 0) /
        weightDifferencesGrams.length;

  // mean sample weight difference proportion
  const weightDifferencesProportions: number[] = rows
    .map((row) => row.analysis.netWeightDifferenceProportion)
    .filter((proportion): proportion is number => !!proportion);
  const meanWeightDifferenceProportion =
    weightDifferencesProportions.length === 0
      ? null
      : weightDifferencesProportions.reduce(
          (acc, proportion) => acc + proportion,
          0,
        ) / weightDifferencesProportions.length;

  // mean sample accumulated weight
  const meanAccumulatedWeightGrams =
    rowCount === 0
      ? 0
      : rows.reduce(
          (acc, row) => acc + row.analysis.accumulatedNetWeightGrams,
          0,
        ) / rowCount;

  // mean material class proportions
  const meanMaterialClassProportions = matrixMean(
    rows.map((row) =>
      materialClasses.map(
        (materialClass) =>
          row.analysis.materialClassToProportionMap[materialClass.id] ?? 0,
      ),
    ),
    0,
  );
  const materialClassToMeanProportionMap: Record<string, number> =
    Object.fromEntries(
      new Map(
        materialClasses.map((materialClass, idx) => [
          materialClass.id,
          meanMaterialClassProportions[idx],
        ]),
      ),
    );

  // mean material class weights
  const meanMaterialClassWeightGrams = matrixMean(
    rows.map((row) =>
      materialClasses.map(
        (materialClass) =>
          row.analysis.materialClassToNetWeightGramsMap[materialClass.id] ?? 0,
      ),
    ),
    0,
  );
  const materialClassToMeanWeightGramsMap: Record<string, number> =
    Object.fromEntries(
      new Map(
        materialClasses.map((materialClass, idx) => [
          materialClass.id,
          meanMaterialClassWeightGrams[idx],
        ]),
      ),
    );

  // mean sample moisture proportion
  const moistureProportions: number[] = rows
    .map((row) => row.analysis.moistureProportion)
    .filter((weight): weight is number => !!weight);
  const meanMoistureProportion =
    moistureProportions.length === 0
      ? null
      : moistureProportions.reduce((acc, proportion) => acc + proportion, 0) /
        moistureProportions.length;

  // mean sample moisture weight
  const moistureWeights: number[] = rows
    .map((row) => row.analysis.moistureNetWeightGrams)
    .filter((weight): weight is number => !!weight);
  const meanMoistureWeight =
    moistureWeights.length === 0
      ? null
      : moistureWeights.reduce((acc, proportion) => acc + proportion, 0) /
        moistureWeights.length;

  // mean material class moisture weights
  const materialClassToMoistureWeightMaps = rows
    .map((row) => row.analysis.materialClassToNetWeightGramsMap)
    .filter(
      (
        materialClassMoistureWeightMap,
      ): materialClassMoistureWeightMap is Record<string, number> =>
        !!materialClassMoistureWeightMap,
    );
  const materialClassIdsWithMoistureWeights: MaterialClassId[] = [
    ...new Set(
      materialClassToMoistureWeightMaps
        .map((materialClassMoistureWeightMap) =>
          Object.keys(materialClassMoistureWeightMap),
        )
        .flat(),
    ),
  ];
  const meanMaterialClassMoistureWeightValues =
    materialClassToMoistureWeightMaps.length === 0
      ? null
      : matrixMean(
          materialClassToMoistureWeightMaps.map(
            (materialClassMoistureWeightMap) =>
              materialClassIdsWithMoistureWeights.map(
                (materialClassId) =>
                  materialClassMoistureWeightMap[materialClassId] ?? 0,
              ),
          ),
          0,
        );
  const meanMaterialClassMoistureWeightMap =
    meanMaterialClassMoistureWeightValues !== null
      ? Object.fromEntries(
          materialClassIdsWithMoistureWeights.map((materialClassId, idx) => [
            materialClassId,
            meanMaterialClassMoistureWeightValues[idx],
          ]),
        )
      : null;

  // mean material class moisture proportions
  const materialClassToMoistureProportionMaps = rows
    .map((row) => row.analysis.materialClassToMoistureProportionMap)
    .filter(
      (
        materialClassMoistureProportionMap,
      ): materialClassMoistureProportionMap is Record<string, number> =>
        !!materialClassMoistureProportionMap,
    );
  const materialClassIdsWithMoistureProportions: MaterialClassId[] = [
    ...new Set(
      materialClassToMoistureProportionMaps
        .map((materialClassMoistureMap) =>
          Object.keys(materialClassMoistureMap),
        )
        .flat(),
    ),
  ];
  const meanMaterialClassMoistureProportionValues =
    materialClassToMoistureProportionMaps.length === 0
      ? null
      : matrixMean(
          materialClassToMoistureProportionMaps.map(
            (materialClassMoistureProportionMap) =>
              materialClassIdsWithMoistureProportions.map(
                (materialClassId) =>
                  materialClassMoistureProportionMap[materialClassId] ?? 0,
              ),
          ),
          0,
        );
  const meanMaterialClassMoistureProportionMap =
    meanMaterialClassMoistureProportionValues !== null
      ? Object.fromEntries(
          materialClassIdsWithMoistureProportions.map(
            (materialClassId, idx) => [
              materialClassId,
              meanMaterialClassMoistureProportionValues[idx],
            ],
          ),
        )
      : null;

  // mean Radius 3DS process parameters
  const radius3DSParameters: Radius3DSParametersDTO[] = rows
    .map((row) => row.radius3DSParameters)
    .filter(
      (radiusParams): radiusParams is Radius3DSParametersDTO => !!radiusParams,
    );
  const meanRelativeDensity =
    radius3DSParameters.length === 0
      ? null
      : radius3DSParameters.reduce(
          (acc, radiusParams) => acc + radiusParams.relativeDensity,
          0,
        ) / moistureWeights.length;
  const meanShortTonsPerHour =
    radius3DSParameters.length === 0
      ? null
      : radius3DSParameters.reduce(
          (acc, radiusParams) => acc + radiusParams.shortTonsPerHour,
          0,
        ) / moistureWeights.length;

  const newAggregateRow: SampleTableRow = {
    metadata: {
      type: 'Notional',
      sampleId: '',
      sampleNumericId: rowCount,
      sampleName: '',
      sampleTakenDate: '',
      sampleTakenTime: '',
      productType: commodity,
      isProcessed: isProcessed,
      upstreamSource: internalMaterialSource,
      upstreamSourceCommodity: sourceCommodity,
      exportDestination: internalMaterialSink,
      employedRecoveryStrategy: recoveryStrategy,
      producingProcess: process,
      status: 'complete',
    },
    analysis: {
      type: 'SamplingSuite',
      analysisDate: '',
      analysisTime: '',
      isComplete: allSamplesAreComplete,
      accumulatedNetWeightGrams: meanAccumulatedWeightGrams,
      explicitNetWeightGrams: meanExplicitWeightGrams,
      netWeightDifferenceGrams: meanWeightDifferenceGrams,
      netWeightDifferenceProportion: meanWeightDifferenceProportion,
      materialClassToNetWeightGramsMap: materialClassToMeanWeightGramsMap,
      materialClassToProportionMap: materialClassToMeanProportionMap,
      materialClassToCaptureIdsMap: null,
      wholeSampleCaptureIds: null,
      moistureProportion: meanMoistureProportion,
      moistureNetWeightGrams: meanMoistureWeight,
      materialClassToMoistureProportionMap:
        meanMaterialClassMoistureProportionMap,
      materialClassToMoistureNetWeightGramsMap:
        meanMaterialClassMoistureWeightMap,
    },
    radius3DSParameters:
      meanRelativeDensity !== null && meanShortTonsPerHour !== null
        ? {
            relativeDensity: meanRelativeDensity,
            shortTonsPerHour: meanShortTonsPerHour,
          }
        : null,
  };
  return newAggregateRow;
};
