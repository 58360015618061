import { Center, Flex, Loader, Text } from '@mantine/core';

export function TableLoader(props: { height?: string; failureCount: number }) {
  const { height = '300px', failureCount } = props;
  return (
    <Center h={height}>
      <Flex direction='column' justify='center' align='center' gap='xl'>
        <Loader
          variant='bars'
          size='xl'
          color={failureCount > 0 ? 'orange' : undefined}
        />
        <Text size='sm' color='dimmed'>
          Loading samples...
        </Text>
      </Flex>
    </Center>
  );
}
