import {
  Alert,
  Button,
  Group,
  Navbar,
  NavLink,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import { useLinkProps } from '@swan-io/chicane';
import { Icon, IconCalendar, IconLogout } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { create } from 'zustand';
import { useSamplingSuiteSessions } from '../api/samplingSuiteSession';
import {
  CommodityIcon,
  ContainerIcon,
  InsightsIcon,
  InternalMaterialSinkIcon,
  InternalMaterialSourceIcon,
  MaterialClassIcon,
  ProcessIcon,
  ProductionIcon,
  RecoveryStrategyIcon,
  SamplingIcon,
  TransactionLedgerIcon,
} from '../Icons';
import { LinkButton } from '../Link';
import { msalInstance } from '../Microsoft/msalConfig';
import { SamplingSuiteSessionDTO } from '../rest-client';
import { Router } from '../router';

interface NavbarStore {
  opened: boolean;
  toggleOpen: () => void;
}

export const useNavbarStore = create<NavbarStore>()((set) => ({
  opened: false,
  toggleOpen: () => {
    set(({ opened }) => ({ opened: !opened }));
  },
}));

interface NavItemLink {
  id: string;
  label: string;
  href: string;
  icon: Icon;
  /// show active state if any of these routes are populated
  activeRotues: Routes;
}

type Routes = Parameters<typeof Router.useRoute>[0];

const navItems: NavItemLink[] = [
  {
    id: 'insights-dashboard',
    label: 'Facility Insights',
    href: Router.FacilityInsightsDashboard(),
    icon: InsightsIcon,
    activeRotues: ['FacilityInsightsArea'],
  },
  {
    id: 'samples',
    label: 'Samples',
    href: Router.SampleList(),
    icon: SamplingIcon,
    activeRotues: ['SampleArea'],
  },
  {
    id: 'production-history',
    label: 'Production History',
    href: Router.ProductionHistory(),
    icon: ProductionIcon,
    activeRotues: ['ProductionHistory'],
  },
  {
    id: 'daily-production',
    label: 'Daily Production',
    href: Router.ProductionDaily(),
    icon: IconCalendar,
    activeRotues: ['ProductionDaily'],
  },
  {
    id: 'sorting-processes',
    label: 'Sorting Processes',
    href: Router.ProcessList(),
    icon: ProcessIcon,
    activeRotues: ['ProcessArea'],
  },
  {
    id: 'recovery-strategies',
    label: 'Recovery Strategies',
    href: Router.RecoveryStrategyList(),
    icon: RecoveryStrategyIcon,
    activeRotues: ['RecoveryStrategyArea'],
  },
  {
    id: 'containers',
    label: 'Container Inventory',
    href: Router.ContainerList(),
    icon: ContainerIcon,
    activeRotues: ['ContainerArea'],
  },
  {
    id: 'material-sourcing',
    label: 'Material Sourcing',
    href: Router.InternalSourceList(),
    icon: InternalMaterialSourceIcon,
    activeRotues: ['InternalSourceArea'],
  },
  {
    id: 'material-exports',
    label: 'Material Exports',
    href: Router.InternalSinkList(),
    icon: InternalMaterialSinkIcon,
    activeRotues: ['InternalSinkArea'],
  },
  {
    id: 'commodities',
    label: 'Commodities',
    href: Router.CommodityList(),
    icon: CommodityIcon,
    activeRotues: ['CommodityArea'],
  },
  {
    id: 'material-classes',
    label: 'Material Classes',
    href: Router.MaterialClassList(),
    icon: MaterialClassIcon,
    activeRotues: ['MaterialClassArea'],
  },
  {
    id: 'event-ledger',
    label: 'Event Ledger',
    href: Router.LedgerHistory(),
    icon: TransactionLedgerIcon,
    activeRotues: ['LedgerHistory'],
  },
];

function NavItemLink(props: { navItem: NavItemLink }) {
  const { navItem } = props;
  const { onClick } = useLinkProps({ href: navItem.href });
  const activeRoute = Router.useRoute(navItem.activeRotues);

  return (
    <NavLink
      component='a'
      active={!!activeRoute}
      href={navItem.href}
      onClick={onClick}
      label={navItem.label}
      icon={<navItem.icon stroke={1.5} />}
    />
  );
}

export function AppNavbar() {
  const opened = useNavbarStore((s) => s.opened);

  return (
    <Navbar
      p='sm'
      hiddenBreakpoint='sm'
      hidden={!opened}
      width={{ sm: 220, lg: 220 }}
    >
      <OpenSamplingSuiteSessionsHelper />

      <Navbar.Section grow component={ScrollArea} mx='-xs' px='xs'>
        <Stack>
          {navItems.map((navItem, i) => (
            <NavItemLink key={i} navItem={navItem} />
          ))}
        </Stack>
      </Navbar.Section>
      <Navbar.Section>
        <Group w='100%'>
          <LogoutButton />
        </Group>
      </Navbar.Section>
    </Navbar>
  );
}

function LogoutButton() {
  return (
    <Button
      variant='outline'
      ml='auto'
      leftIcon={<IconLogout />}
      onClick={() =>
        void msalInstance.logoutPopup().catch((e) => {
          console.error(e);
        })
      }
    >
      Logout
    </Button>
  );
}

function OpenSamplingSuiteSessionsHelper() {
  const route = Router.useRoute(['SamplingSessionSession']);

  const openOwnedSamplingSessionsQuery = useSamplingSuiteSessions({
    isOpen: true,
    isOwned: true,
  });

  if (route !== undefined) {
    // only show if we aren't on the session page
    return null;
  }

  if (openOwnedSamplingSessionsQuery.data === undefined) {
    return null;
  }

  const sessions = openOwnedSamplingSessionsQuery.data;
  if (sessions.length === 0) {
    return null;
  }

  let warningContent: ReactNode;
  if (sessions.length === 1) {
    const [session] = sessions;
    warningContent = (
      <Stack>
        <Text>You currently have an open VALI-Sample session.</Text>
        <ReturnToSessionButton session={session}>
          Return to Session
        </ReturnToSessionButton>
      </Stack>
    );
  } else {
    const warningText = (
      <Text>You currently have multiple open VALI-Sample sessions.</Text>
    );
    const sessionLinks = sessions.map((session) => (
      <ReturnToSessionButton key={session.id} session={session}>
        Return to Session
        {/* TODO: Show analysis + sampling suite names */}
      </ReturnToSessionButton>
    ));

    warningContent = (
      <>
        {warningText}
        {sessionLinks}
      </>
    );
  }

  return (
    <Navbar.Section mb='xl'>
      <Alert
        title={`Open VALI-Sample ${sessions.length > 1 ? 'Sessions' : 'Session'}`}
        color='pink'
      >
        {warningContent}
      </Alert>
    </Navbar.Section>
  );
}

function ReturnToSessionButton(props: {
  session: SamplingSuiteSessionDTO;
  children: ReactNode;
}) {
  const { session, children } = props;
  return (
    <LinkButton
      to={Router.SamplingSessionSession({ sessionId: session.id })}
      color='pink'
    >
      {children}
    </LinkButton>
  );
}
