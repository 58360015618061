import { WithUnit } from './WithUnit';

export const PCTFORMAT = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 1,
});

export function formatPercentage(v: number | null) {
  if (v === null) {
    return '?%';
  }
  return PCTFORMAT.format(v);
}

export function formatPercentWithMinThreshold(
  value: number,
  fractionDigits?: number,
) {
  const maximumFractionDigits = fractionDigits ?? 1;

  const threshold = (1 / 10) ** maximumFractionDigits;
  const pct = 100.0 * value;

  if (0 < pct && pct < threshold) {
    return (
      <WithUnit unitPrefix='<' unitSuffix='%'>
        {threshold.toFixed(maximumFractionDigits)}
      </WithUnit>
    );
  } else if (-threshold < pct && pct < 0) {
    return (
      <WithUnit unitPrefix='>' unitSuffix='%'>
        -{threshold.toFixed(maximumFractionDigits)}
      </WithUnit>
    );
  }
  return (
    <WithUnit unitSuffix='%'>{pct.toFixed(maximumFractionDigits)}</WithUnit>
  );
}
