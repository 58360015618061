import { create } from 'zustand';

type LightboxArea = 'assigned' | 'unassigned' | 'unlinked';

interface CaptureLightboxStore {
  opened: false | LightboxArea;
  captureId: string | undefined;
  select: (captureId: string | undefined) => void;
  open: (area: LightboxArea, captureId?: string) => void;
  close: () => void;
}

export const useCaptureLightboxStore = create<CaptureLightboxStore>()(
  (set) => ({
    opened: false,
    captureId: undefined,
    select: (captureId) => {
      set({ captureId });
    },
    open: (area, captureId) => {
      set(({ captureId: prevCaptureId }) => ({
        opened: area,
        captureId: captureId ?? prevCaptureId,
      }));
    },
    close: () => {
      set(() => ({
        opened: false,
      }));
    },
  }),
);
