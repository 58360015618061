import {
  Alert,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
  Timeline,
} from '@mantine/core';
import dayjs from 'dayjs';
import { P, match } from 'ts-pattern';
import { useFacilityContext } from '../Facility/FacilityContext';

import { DeleteContainerTransferButton } from '../ContainerTransfer/DeleteContainerTransferButton';
import {
  ContainerIcon,
  EmptyContainerIcon,
  FullContainerIcon,
  IncomingTransferIcon,
  InternalMaterialSinkTransferIcon,
  InternallySourcedMaterialPartitionTransferIcon,
  OutgoingTransferIcon,
  PartiallyFullContainerIcon,
  ProcessBufferTransferIcon,
  PurchaseIcon,
  ScaleIcon,
  TruckLoadIcon,
} from '../Icons';
import { DeleteInternalSinkContainerTransferButton } from '../InternalSinkContainerTransfer/DeleteInternalSinkContainerTransferButton';
import { MassClaimText } from '../Mass/MassClaimText';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { OutputContainerChangeName } from '../Process/OutputContainerChangeName';
import { ProcessIdName } from '../Process/ProcessName';
import {
  ContainerName,
  InternalMaterialSinkName,
  InternallySourcedMaterialName,
  PurchasedMaterialName,
  TruckLoadName,
} from '../Repository/RepositoryName';
import { DeleteScaleReadingButton } from '../Scale/DeleteScaleReadingButton';
import { useContainerHistory } from '../api/container';
import {
  ContainerHistoryEventDTO,
  ContainerIncomingTransferEventDTO,
  ContainerOutgoingTransferEventDTO,
  ContainerWeighingEventDTO,
  MaterialContainerId,
  OccupiedContainerStateDTO,
  OutputContainerChangeEventDTO,
  ProcessBufferTransferEventDTO,
  SinkContainerTransferEventDTO,
  TransferContainerStatesResultDTO,
} from '../rest-client';
import { ContainerIdName } from './ContainerIdName';

export function ContainerHistory({ containerId }: { containerId: string }) {
  const historyQuery = useContainerHistory(containerId);

  if (historyQuery.isLoadingError) {
    throw historyQuery.error;
  }

  if (historyQuery.isLoading) {
    return <Loader />;
  }

  const reverseChronologicalTimelineItems = historyQuery.data;
  if (reverseChronologicalTimelineItems.length === 0) {
    return (
      <Alert color='blue' title='Unused Container'>
        This container has never been used.
      </Alert>
    );
  }

  return (
    <ScrollArea scrollbarSize='1rem' h={400} w='fit-content' type='auto'>
      <Timeline ml='1rem'>
        {reverseChronologicalTimelineItems.map((event) => (
          <Timeline.Item
            key={`${event.kind}-${event.timestamp}`}
            title={match(event.kind)
              .with('Weighing', () => 'Weighed')
              .with(
                'IncomingContainerTransfer',
                () => 'Incoming Container Material Transfer',
              )
              .with(
                'OutgoingContainerTransfer',
                () => 'Outgoing Container Material Transfer',
              )
              .with('IncomingTruckLoad', () => 'Incoming Truck Load')
              .with('OutgoingTruckLoad', () => 'Outgoing Truck Load')
              .with(
                'InternallySourcedMaterialPartitionDestination',
                () => 'Incoming Upstream Sourced Material',
              )
              .with(
                'PurchasedMaterialPartitionDestination',
                () => 'Incoming Purchased Material',
              )
              .with('InternalSinkContainerTransfer', () => 'Material Export')
              .with('BufferTransfer', () => 'Feedstock Buffer Transfer')
              .with('OutputContainerChange', () => 'Output Container Change')
              .exhaustive()}
            bullet={match(event.kind)
              .with('Weighing', () => <ScaleIcon />)
              .with('IncomingContainerTransfer', () => <IncomingTransferIcon />)
              .with('OutgoingContainerTransfer', () => <OutgoingTransferIcon />)
              .with('IncomingTruckLoad', () => <TruckLoadIcon />)
              .with('OutgoingTruckLoad', () => <TruckLoadIcon />)
              .with('InternallySourcedMaterialPartitionDestination', () => (
                <InternallySourcedMaterialPartitionTransferIcon />
              ))
              .with('PurchasedMaterialPartitionDestination', () => (
                <PurchaseIcon />
              ))
              .with('InternalSinkContainerTransfer', () => (
                <InternalMaterialSinkTransferIcon />
              ))
              .with('BufferTransfer', () => <ProcessBufferTransferIcon />)
              .with('OutputContainerChange', () => <ContainerIcon />)
              .exhaustive()}
            bulletSize={32}
          >
            <HistoryTimelineItemContent
              event={event}
              containerId={containerId}
            />
          </Timeline.Item>
        ))}
      </Timeline>
    </ScrollArea>
  );
}

function ScaleReadingMassClaimTimelineItem({
  weighingEvent,
}: {
  weighingEvent: ContainerWeighingEventDTO;
}) {
  return (
    <Group noWrap spacing={0}>
      <Text size='sm' span pr='.5ch'>
        This container was weighed with a net weight of
      </Text>
      {weighingEvent.containerScaleReadingMassClaim !== null ? (
        <MassClaimText
          massClaim={weighingEvent.containerScaleReadingMassClaim}
        />
      ) : (
        <Text color='dimmed' style={{ display: 'inline-flex' }}>
          Unknown
        </Text>
      )}
      <Text>.</Text>
      {weighingEvent.containerScaleReadingMassClaim && (
        <DeleteScaleReadingButton
          scaleReadingId={
            weighingEvent.containerScaleReadingMassClaim.scaleReadingId
          }
          ml='md'
        />
      )}
    </Group>
  );
}

function ContainerOutputChangeTimelineItem(props: {
  outputContainerChangeEvent: OutputContainerChangeEventDTO;
}) {
  const { outputContainerChangeEvent: containerOutputChangeEvent } = props;

  return (
    <Text size='sm'>
      This container became the output container for{' '}
      <OutputContainerChangeName
        outputContainerChange={containerOutputChangeEvent.outputContainerChange}
      />
      .
    </Text>
  );
}

function InternalSinkContainerTransferTimelineItem(props: {
  sinkTransferEvent: SinkContainerTransferEventDTO;
}) {
  const { sinkTransferEvent } = props;
  return (
    <Group>
      <Text size='sm'>
        {sinkTransferEvent.sourceEmptied ? 'All' : 'Some'} of this container was
        removed from inventory into{' '}
        <InternalMaterialSinkName
          internalMaterialSink={sinkTransferEvent.destinationSink}
        />
        .
      </Text>
      <DeleteInternalSinkContainerTransferButton
        sinkTransferId={sinkTransferEvent.sinkContainerTransferId}
        size='sm'
        compact
        variant='outline'
      />
    </Group>
  );
}

function TransferPrePostMassTable(props: {
  transferContainerStates: TransferContainerStatesResultDTO;
  timestamp: string;
  sourceContainerId: MaterialContainerId;
  destinationContainerId: MaterialContainerId;
}) {
  const {
    transferContainerStates,
    timestamp,
    sourceContainerId,
    destinationContainerId,
  } = props;

  function TransferStateMass(props: {
    occupiedContainerState: OccupiedContainerStateDTO | null;
  }) {
    const { occupiedContainerState } = props;
    return (
      <Group position='right'>
        {match(occupiedContainerState)
          .with(P.nullish, () => (
            <>
              <EmptyContainerIcon size='1rem' />
              <Text c='dimmed'>Empty</Text>
            </>
          ))
          .with(P.not(P.nullish), (transferState) => (
            <>
              {transferState.full ? (
                <FullContainerIcon size='1rem' />
              ) : (
                <PartiallyFullContainerIcon size='1rem' />
              )}
              <MaterialSetMass materialSet={transferState.materialSet} />
            </>
          ))
          .exhaustive()}
      </Group>
    );
  }

  return (
    <Table
      verticalSpacing={0}
      horizontalSpacing='xs'
      fontSize='xs'
      maw={400}
      withBorder
    >
      <thead>
        <tr>
          <th>Net</th>
          <th style={{ textAlign: 'right' }}>
            {
              <ContainerIdName
                containerId={sourceContainerId}
                time={dayjs.utc(timestamp)}
              />
            }
          </th>
          <th style={{ textAlign: 'right' }}>
            <ContainerIdName
              containerId={destinationContainerId}
              time={dayjs.utc(timestamp)}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Text fw={700}>Pre</Text>
          </td>
          <td style={{ textAlign: 'right', alignItems: 'right' }}>
            {match(transferContainerStates)
              .with({ status: 'ledger-error' }, () => (
                <Text color='red' size='xs'>
                  Ledger Error
                </Text>
              ))
              .with({ status: 'success' }, (tcs) => (
                <TransferStateMass
                  occupiedContainerState={
                    tcs.sourceContainerPrePostState.preTransactionState
                  }
                />
              ))
              .exhaustive()}
          </td>
          <td style={{ textAlign: 'right' }}>
            {match(transferContainerStates)
              .with({ status: 'ledger-error' }, () => (
                <Text color='red' size='xs'>
                  Ledger Error
                </Text>
              ))
              .with({ status: 'success' }, (tcs) => (
                <TransferStateMass
                  occupiedContainerState={
                    tcs.destinationContainerPrePostState.preTransactionState
                  }
                />
              ))
              .exhaustive()}
          </td>
        </tr>
        <tr>
          <td>
            <Text fw={700}>Post</Text>
          </td>
          <td style={{ textAlign: 'right' }}>
            {match(transferContainerStates)
              .with({ status: 'ledger-error' }, () => (
                <Text color='red' size='xs'>
                  Ledger Error
                </Text>
              ))
              .with({ status: 'success' }, (tcs) => (
                <TransferStateMass
                  occupiedContainerState={
                    tcs.sourceContainerPrePostState.postTransactionState
                  }
                />
              ))
              .exhaustive()}
          </td>
          <td style={{ textAlign: 'right' }}>
            {match(transferContainerStates)
              .with({ status: 'ledger-error' }, () => (
                <Text color='red' size='xs'>
                  Ledger Error
                </Text>
              ))
              .with({ status: 'success' }, (tcs) => (
                <TransferStateMass
                  occupiedContainerState={
                    tcs.destinationContainerPrePostState.postTransactionState
                  }
                />
              ))
              .exhaustive()}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

function IncomingTransferTimelineItem(props: {
  transferEvent: ContainerIncomingTransferEventDTO;
  containerId: MaterialContainerId;
}) {
  const { transferEvent, containerId } = props;
  const { sourceContainer, sourceEmptied, timestamp, transferContainerStates } =
    transferEvent;

  return (
    <Stack spacing='xs'>
      <Group>
        <Text size='sm'>
          {sourceEmptied ? 'All ' : 'Some '} of{' '}
          <ContainerName
            container={sourceContainer}
            time={dayjs.utc(timestamp)}
          />{' '}
          was transferred into this container.
        </Text>
        <DeleteContainerTransferButton
          transferId={transferEvent.containerTransferId}
        />
      </Group>
      <TransferPrePostMassTable
        transferContainerStates={transferContainerStates}
        timestamp={timestamp}
        sourceContainerId={sourceContainer.id}
        destinationContainerId={containerId}
      />
    </Stack>
  );
}

function OutgoingTransferTimelineItem(props: {
  transferEvent: ContainerOutgoingTransferEventDTO;
  containerId: MaterialContainerId;
}) {
  const { transferEvent, containerId } = props;
  const {
    destinationContainer,
    sourceEmptied,
    timestamp,
    transferContainerStates,
  } = transferEvent;

  return (
    <Stack spacing='xs'>
      <Group>
        <Text size='sm'>
          {sourceEmptied ? 'All ' : 'Some '} of this container was transferred
          into{' '}
          <ContainerName
            container={destinationContainer}
            time={dayjs.utc(timestamp)}
          />
          .
        </Text>
        <DeleteContainerTransferButton
          transferId={transferEvent.containerTransferId}
        />
      </Group>
      <TransferPrePostMassTable
        transferContainerStates={transferContainerStates}
        timestamp={timestamp}
        sourceContainerId={containerId}
        destinationContainerId={destinationContainer.id}
      />
    </Stack>
  );
}

function BufferTransferTimelineItem(props: {
  bufferTransferEvent: ProcessBufferTransferEventDTO;
}) {
  const { bufferTransferEvent } = props;
  return (
    <Text size='sm'>
      The contents of this container were transferred into the buffer for
      process{' '}
      <ProcessIdName processId={bufferTransferEvent.bufferTransfer.processId} />
      .
    </Text>
  );
}

interface HistoryTimelineItemContentProps {
  event: ContainerHistoryEventDTO;
  containerId: MaterialContainerId;
}
function HistoryTimelineItemContent(props: HistoryTimelineItemContentProps) {
  const { event, containerId } = props;

  const { timeZoneId } = useFacilityContext();

  const timestamp = dayjs.utc(event.timestamp).tz(timeZoneId);
  const body = match(event)
    .with(
      { kind: 'InternallySourcedMaterialPartitionDestination' },
      ({ internallySourcedMaterial }) => (
        <Text size='sm'>
          <InternallySourcedMaterialName
            internallySourcedMaterial={internallySourcedMaterial}
          />{' '}
          was transferred into this container.
        </Text>
      ),
    )
    .with(
      { kind: 'PurchasedMaterialPartitionDestination' },
      ({ purchasedMaterial }) => (
        <Text size='sm'>
          <PurchasedMaterialName purchasedMaterial={purchasedMaterial} /> was
          transferred into this container.
        </Text>
      ),
    )
    .with({ kind: 'Weighing' }, (weighingEvent) => (
      <ScaleReadingMassClaimTimelineItem weighingEvent={weighingEvent} />
    ))
    .with({ kind: 'IncomingContainerTransfer' }, (transferEvent) => (
      <IncomingTransferTimelineItem
        transferEvent={transferEvent}
        containerId={containerId}
      />
    ))
    .with({ kind: 'OutgoingContainerTransfer' }, (transferEvent) => (
      <OutgoingTransferTimelineItem
        transferEvent={transferEvent}
        containerId={containerId}
      />
    ))
    .with({ kind: 'OutgoingTruckLoad' }, ({ truckLoad, sourceEmptied }) => (
      <Text size='sm'>
        {sourceEmptied ? 'All' : 'Some'} of this container was loaded into{' '}
        <TruckLoadName truckLoad={truckLoad} />.
      </Text>
    ))
    .with({ kind: 'IncomingTruckLoad' }, ({ truckLoad, sourceEmptied }) => (
      <Text size='sm'>
        {sourceEmptied ? 'All' : 'Some'} of{' '}
        <TruckLoadName truckLoad={truckLoad} /> was unloaded into this
        container.
      </Text>
    ))
    .with({ kind: 'InternalSinkContainerTransfer' }, (sinkTransferEvent) => (
      <InternalSinkContainerTransferTimelineItem
        sinkTransferEvent={sinkTransferEvent}
      />
    ))
    .with({ kind: 'BufferTransfer' }, (bufferTransferEvent) => (
      <BufferTransferTimelineItem bufferTransferEvent={bufferTransferEvent} />
    ))
    .with({ kind: 'OutputContainerChange' }, (containerOutputChangeEvent) => (
      <ContainerOutputChangeTimelineItem
        outputContainerChangeEvent={containerOutputChangeEvent}
      />
    ))
    .exhaustive();
  return (
    <>
      {body}
      <Text size='xs' mt={4}>
        {timestamp.fromNow()} {'\u{00B7}'} {timestamp.format('lll')}
      </Text>
    </>
  );
}
