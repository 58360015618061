import {
  Loader,
  Pagination,
  Skeleton,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { InternalMaterialSourceIdName } from '../InternalMaterialSource/InternalMaterialSourceName.tsx';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { InternallySourcedMaterialName } from '../Repository/RepositoryName';
import { TableEmptyBasicContent } from '../Table/TableEmptyBasicContent.tsx';
import {
  useInternallySourcedMaterialSets,
  useInternallySourcedMaterials,
} from '../api/internallySourcedMaterial';
import { InternalMaterialSourceId } from '../rest-client';
import cssClasses from './InternallySourcedMaterialTable.module.css';

export function InternallySourcedMaterialTable(props: {
  internalMaterialSourceId?: InternalMaterialSourceId;
  paginate?: boolean;
  pageSize?: number;
}) {
  const { internalMaterialSourceId, paginate = true, pageSize = 10 } = props;

  const theme = useMantineTheme();
  const internallySourcedMaterialsQuery = useInternallySourcedMaterials(
    internalMaterialSourceId,
  );
  const internallySourcedMaterialSetsQuery = useInternallySourcedMaterialSets(
    internalMaterialSourceId,
  );

  if (internallySourcedMaterialsQuery.isLoadingError) {
    throw internallySourcedMaterialsQuery.error;
  }
  if (internallySourcedMaterialSetsQuery.isLoadingError) {
    throw internallySourcedMaterialSetsQuery.error;
  }

  const ismList = internallySourcedMaterialsQuery.data ?? [];

  const getMaterialSet = (ismId: string) => {
    if (internallySourcedMaterialSetsQuery.isLoading) return undefined;
    if (internallySourcedMaterialSetsQuery.data.status === 'ledger-error')
      return null;
    return internallySourcedMaterialSetsQuery.data
      .internallySourcedMaterialSets[ismId];
  };
  const hideSource = internalMaterialSourceId !== undefined;

  const isLoading =
    internallySourcedMaterialSetsQuery.isLoading ||
    internallySourcedMaterialsQuery.isLoading;
  const isEmpty = ismList.length === 0;

  const pages = Math.ceil(ismList.length / pageSize);
  const [page, setPage] = useState(1);
  const from = (page - 1) * pageSize;
  const to = from + pageSize;
  const ismPage = paginate ? ismList.slice(from, to) : ismList;

  return (
    <Stack>
      <Table>
        {(isLoading || isEmpty) && (
          <caption
            style={{
              captionSide: 'bottom',
              textAlign: 'center',
              padding: theme.spacing.md,
            }}
          >
            {isLoading && <Loader />}
            {!isLoading && isEmpty && (
              <TableEmptyBasicContent>
                No Upstream Sourced Materials
              </TableEmptyBasicContent>
            )}
          </caption>
        )}
        <thead>
          <tr>
            <th>Name</th>
            {!hideSource && <th>Upstream Material Source</th>}
            <th>Date Sourced</th>
            <th>Commodity</th>
            <th style={{ textAlign: 'right' }}>Mass</th>
          </tr>
        </thead>
        <tbody>
          {ismPage.map((ism) => {
            const materialSet = getMaterialSet(ism.id);
            return (
              <tr key={ism.id}>
                <td>
                  <InternallySourcedMaterialName
                    internallySourcedMaterial={ism}
                  />
                </td>
                {!hideSource && (
                  <td>
                    <InternalMaterialSourceIdName
                      internalMaterialSourceId={ism.internalMaterialSourceId}
                    />
                  </td>
                )}
                <td>{dayjs(ism.creationTime).format('L')}</td>

                {match(materialSet)
                  .with(undefined, () => (
                    <>
                      <td>
                        <Skeleton>Loading...</Skeleton>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <Skeleton>Loading...</Skeleton>
                      </td>
                    </>
                  ))
                  .with(null, () => (
                    <>
                      <td>
                        <Text color='orange'>Ledger Error</Text>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <Text color='orange'>Ledger Error</Text>
                      </td>
                    </>
                  ))
                  .otherwise((m) => (
                    <>
                      <td>
                        <MaterialSetCommodity materialSet={m} />
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <MaterialSetMass materialSet={m} />
                      </td>
                    </>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {paginate && (
        <Pagination
          value={page}
          onChange={setPage}
          total={pages}
          className={cssClasses.pagination}
        />
      )}
    </Stack>
  );
}
