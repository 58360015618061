import { Table } from '@mantine/core';
import {
  IconAlertHexagonFilled,
  IconCheck,
  IconMathSymbols,
} from '@tabler/icons-react';
import { match, P } from 'ts-pattern';
import { useMaterialClassSetConversionGraph } from '../api/materialClassSetConversion';
import { AddIcon } from '../Icons';
import { LinkText, LinkTextProps } from '../Link';
import { MaterialClassSetConversionGraphDTO } from '../rest-client';
import { Router } from '../router';

export function MaterialClassSetConversionTables() {
  const {
    data: graph,
    isLoadingError,
    error,
  } = useMaterialClassSetConversionGraph();

  if (isLoadingError) throw error;

  return <>{graph && <ConversionGraphTable graph={graph} />}</>;
}

function ConversionGraphTable(props: {
  graph: MaterialClassSetConversionGraphDTO;
}) {
  const { graph } = props;

  return (
    <Table
      withBorder
      style={{ tableLayout: 'fixed' }}
      highlightOnHover
      withColumnBorders
    >
      <thead>
        <tr>
          <th></th>
          <th
            colSpan={graph.materialClassSets.length}
            style={{ textAlign: 'center' }}
          >
            Target
          </th>
        </tr>
        <tr>
          <th>Source</th>
          {graph.materialClassSets.map((targetMcs) => (
            <th key={targetMcs.id} style={{ textAlign: 'center' }}>
              {targetMcs.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody style={{ textAlign: 'center' }}>
        {graph.materialClassSets.map((sourceMcs, u) => (
          <tr key={sourceMcs.id}>
            <th>{sourceMcs.name}</th>
            {graph.materialClassSets.map((targetMcs, v) => {
              if (u === v) return <td key={targetMcs.id}>-</td>;

              const invalidConversion = graph.invalidConversions.find(
                (c) =>
                  c.sourceMaterialClassSet.id === sourceMcs.id &&
                  c.targetMaterialClassSet.id === targetMcs.id,
              );
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              const path = graph.pathMatrix[u][v] as
                | (typeof graph.pathMatrix)[number][number]
                | null;

              const props: Omit<LinkTextProps, 'to'> = invalidConversion
                ? { color: 'red', children: <IconAlertHexagonFilled /> }
                : match(path)
                    .with(null, () => ({
                      color: 'blue',
                      children: <AddIcon />,
                    }))
                    .with({ conversions: [P.select()] }, () => ({
                      color: 'teal',
                      children: <IconCheck />,
                    }))
                    .otherwise(() => ({
                      color: 'pink',
                      children: <IconMathSymbols />,
                    }));
              return (
                <td key={targetMcs.id}>
                  <LinkText
                    to={Router.MaterialClassSetConversionDetail({
                      sourceMaterialClassSetId: sourceMcs.id,
                      targetMaterialClassSetId: targetMcs.id,
                    })}
                    {...props}
                  />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
