import { useMutation, useQuery } from '@tanstack/react-query';
import { SampleCreationDTO, SampleService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchSamples(ctx: QueryFunctionContexts['sample']['list']) {
  const [{ containerId }] = ctx.queryKey;
  return await SampleService.getSamples(containerId);
}

export function useSamples(args: { containerId?: string }) {
  return useQuery({
    queryKey: queryKeys.sample.list(args),
    queryFn: fetchSamples,
  });
}

async function fetchSample(ctx: QueryFunctionContexts['sample']['detail']) {
  const [{ sampleId }] = ctx.queryKey;
  return await SampleService.getSampleById(sampleId);
}

export function useSample(sampleId: string) {
  return useQuery({
    queryKey: queryKeys.sample.detail(sampleId),
    queryFn: fetchSample,
  });
}

async function createSample(dto: SampleCreationDTO) {
  await SampleService.createSample(dto);
}

export function useCreateSample() {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: createSample,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.sample.lists(),
        queryKeys.materialClassSetsSampleAnalyses.list(),
        queryKeys.sample.flat.lists(),
        queryKeys.internalSource.list(), // can cause reordering
      );
    },
  });
}

async function deleteSample(id: string) {
  await SampleService.deleteSampleById(id);
}

export function useDeleteSample() {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: deleteSample,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.sample.lists(),
        queryKeys.materialClassSetsSampleAnalyses.list(),
        queryKeys.sample.flat.lists(),
      );
    },
  });
}

async function fetchFlattenedSamples(
  ctx: QueryFunctionContexts['sample']['flat']['list'],
) {
  const [{ commodityId }] = ctx.queryKey;
  return await SampleService.getFlattenedSamples(commodityId);
}

export function useFlattenedSamples(args: { commodityId?: string }) {
  return useQuery({
    queryKey: queryKeys.sample.flat.list(args),
    queryFn: fetchFlattenedSamples,
  });
}
