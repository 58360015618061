import {
  ActionIcon,
  ActionIconProps,
  Alert,
  Button,
  ButtonProps,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { UseMutationResult } from '@tanstack/react-query';
import { DeleteIcon } from '../Icons';
import classes from './DeleteEntity.module.css';

export type DeleteEntityProps<TMutationVariables> = {
  mutationVariables: TMutationVariables;
  deleteMutation: UseMutationResult<void, unknown, TMutationVariables>;
  entityName: string;
  onSuccess?: () => void;
};

// TODO(3193): replace Delete[entityName]Button implementations with DeleteEntityButton
export function DeleteEntityButton<TMutationVariables>(
  props: DeleteEntityProps<TMutationVariables> & Omit<ButtonProps, 'onClick'>,
) {
  const {
    mutationVariables,
    deleteMutation,
    entityName,
    onSuccess,
    color = 'red',
    leftIcon = <DeleteIcon />,
    children = 'Delete',
    ...buttonProps
  } = props;

  const openDeleteModal = useDeleteEntityModal({
    mutationVariables,
    deleteMutation,
    entityName,
    onSuccess,
  });

  return (
    <Button
      leftIcon={leftIcon}
      color={color}
      onClick={openDeleteModal}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

export function DeleteEntityActionIcon<TMutationVariables>(
  props: DeleteEntityProps<TMutationVariables> &
    Omit<ActionIconProps, 'onClick' | 'className'> & { isHidden?: boolean },
) {
  const {
    mutationVariables,
    deleteMutation,
    entityName,
    onSuccess,
    color = 'red',
    children = <DeleteIcon />,
    isHidden,
    ...actionIconProps
  } = props;

  const openDeleteModal = useDeleteEntityModal({
    mutationVariables,
    deleteMutation,
    entityName,
    onSuccess,
  });

  return (
    <ActionIcon
      className={isHidden ? classes.isHidden : classes.isVisible}
      color={color}
      onClick={openDeleteModal}
      {...actionIconProps}
    >
      {children}
    </ActionIcon>
  );
}

export function useDeleteEntityModal<TMutationVariables>(
  props: DeleteEntityProps<TMutationVariables>,
) {
  const { mutationVariables, deleteMutation, entityName, onSuccess } = props;
  const openDeleteModal = openDeleteEntityConfirmModal(`${entityName}`, () => {
    deleteMutation.mutate(mutationVariables, {
      onError() {
        showNotification({
          title: `Error Deleting ${entityName}`,
          message: `An error occurred deleting the ${entityName.toLowerCase()}.`,
          color: 'red',
          icon: <IconX />,
        });
      },
      onSuccess() {
        showNotification({
          title: `${entityName} Successfuly Deleted`,
          message: `The ${entityName.toLowerCase()} has successfully been deleted.`,
          color: 'green',
          icon: <IconCheck />,
        });
        onSuccess?.(); // equivalent to if (onSuccess !== undefined) { onSuccess(); }
      },
      onSettled() {
        deleteMutation.reset();
      },
    });
  });

  return openDeleteModal;
}

export function openDeleteEntityConfirmModal(
  entityName: string,
  onConfirm: () => void,
) {
  return () =>
    openConfirmModal({
      title: `Confirm ${entityName} Deletion`,
      centered: true,
      labels: { confirm: `Delete ${entityName}`, cancel: 'Back to Safety' },
      confirmProps: { color: 'red' },
      onConfirm,
      children: (
        <Alert color='red' variant='filled' title='Warning!'>
          Deleting this {entityName.toLowerCase()} is irreversible! Are you
          absolutely sure you wish to continue?
        </Alert>
      ),
    });
}
