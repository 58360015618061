import { useMutation } from '@tanstack/react-query';
import {
  ManualSampleAnalysisCreationDTO,
  ManualSampleAnalysisPatchDTO,
  ManualSampleAnalysisService,
} from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function createManualSampleAnalysis(
  dto: ManualSampleAnalysisCreationDTO,
) {
  await ManualSampleAnalysisService.createManualSampleAnalysis(dto);
}

export function useAddManualSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createManualSampleAnalysis,
    onSettled() {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKeys(
        queryKeys.sampleAnalysis.lists(),
        queryKeys.materialClassSetsSampleAnalyses.list(),
        queryKeys.sample.flat.lists(),
        queryKeys.materialClassSet.list(), // can cause reordering
      );
    },
  });
}

async function patchManualSampleAnalysis(args: {
  id: string;
  patch: ManualSampleAnalysisPatchDTO;
}) {
  await ManualSampleAnalysisService.patchManualSampleAnalysis(
    args.id,
    args.patch,
  );
}

export function usePatchManualSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchManualSampleAnalysis,
    onSettled() {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKeys(
        queryKeys.sampleAnalysis.lists(),
        queryKeys.materialClassSetsSampleAnalyses.list(),
        queryKeys.sample.flat.lists(),
      );
    },
  });
}

async function deleteManualSampleAnalysis(id: string) {
  await ManualSampleAnalysisService.deleteManualSampleAnalysisById(id);
}

export function useDeleteManualSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteManualSampleAnalysis,
    onSettled() {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKeys(
        queryKeys.sampleAnalysis.lists(),
        queryKeys.materialClassSetsSampleAnalyses.list(),
        queryKeys.sample.flat.lists(),
      );
    },
  });
}
