import { Alert } from '@mantine/core';
import { match } from 'ts-pattern';
import { useCommodityInternalMaterialSourceCompositions } from '../api/commodity';
import { InternalMaterialSourceCompositionsByCommodity } from '../InternalMaterialSource/InternalMaterialSourceCompositionsByCommodityChart';
import { CommodityId } from '../rest-client';
import { TableLoader } from '../Table/TableLoading';

export function CommodityInternalMaterialSourceCompositions(props: {
  commodityId: CommodityId;
}) {
  const { commodityId } = props;

  const {
    data: commoditImsComp,
    isLoading,
    isLoadingError,
    error,
    failureCount,
  } = useCommodityInternalMaterialSourceCompositions({
    commodityId,
    intervalStart: undefined,
    intervalEnd: undefined,
  });

  if (isLoadingError) {
    throw error;
  }

  if (isLoading) {
    return <TableLoader height='450px' failureCount={failureCount} />;
  }

  return match(commoditImsComp)
    .with({ status: 'NoSources' }, () => (
      <Alert color='blue' title='No Upstream Material Sources'>
        There are no upstream material sources whose default commodity matches
        this commodity, nor that have any upstream sourced materials with
        commodity assisngments matching this commodity.
      </Alert>
    ))
    .with({ status: 'SourcesPresent' }, (result) => (
      <InternalMaterialSourceCompositionsByCommodity
        commodityMcsImsComps={
          result.commodityInternalMaterialSourceAggregateCompositions
        }
      />
    ))
    .exhaustive();
}
