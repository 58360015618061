import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';

const MaterialClassSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  shortName: z.string().nullable(),
  description: z.string().nullable(),
});
export type MaterialClassFormValues = z.infer<typeof MaterialClassSchema>;

export function useMaterialClassForm() {
  return useForm<MaterialClassFormValues>({
    initialValues: {
      name: '',
      shortName: '',
      description: '',
    },
    validate: zodResolver(MaterialClassSchema),
  });
}

export function MaterialClassFormFields(props: {
  form: UseFormReturnType<MaterialClassFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <TextInput
        label='Material Class Name'
        placeholder='Material class name'
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      <TextInput
        label='Short Name'
        placeholder='Short name'
        {...form.getInputProps('shortName')}
      />
      <TextInput
        label='Description'
        placeholder='Description'
        {...form.getInputProps('description')}
      />
    </>
  );
}
