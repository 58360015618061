import { Query, QueryClient, useQueryClient } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import Temporal from '../Temporal/temporal.ts';
import {
  ChutecStream,
  FacilityId,
  MaterialTransactionDTO,
  RedWaveSortProgramNumber,
  SortSystemDTO,
} from '../rest-client';
import { QueryKeyFactoryKey } from './queryKeyTypeUtils';

const materialStateDependent = {
  dependsOnMaterialState: true,
} as const;

const q = {
  schupanRimasSnapshot: {
    all: [{ entity: 'schupan-rimas-snapshot' }] as const,
    list: () => [{ ...q.schupanRimasSnapshot.all[0], shape: 'list' }] as const,
  },
  schupanRimasSnapshotAnalysis: {
    all: [{ entity: 'schupan-rimas-snapshot-analysis' }] as const,
    curent: () =>
      [{ ...q.schupanRimasSnapshotAnalysis.all[0], shape: 'current' }] as const,
  },
  tenant: {
    all: [{ entity: 'tenant' }],
    current: () => [{ ...q.tenant.all[0], kind: 'current' }] as const,
  },
  feedFlowGroup: {
    all: [{ entity: 'feed-flow-group', ...materialStateDependent }],
    forTimeRange: ({
      before,
      after,
      processId,
      systemId,
    }: {
      before?: Temporal.Instant;
      after?: Temporal.Instant;
      processId?: string;
      systemId?: string;
    }) => [
      {
        ...q.feedFlowGroup.all[0],
        shape: 'forTimeRange',
        before,
        after,
        processId,
        systemId,
      },
    ],
    byId: (id: string) =>
      [{ ...q.feedFlowGroup.all[0], shape: 'detail', id }] as const,
    feedstockGenealogy: ({ feedFlowGroupId }: { feedFlowGroupId: string }) =>
      [
        {
          ...q.feedFlowGroup.all[0],
          shape: 'feedstockGenealogy',
          feedFlowGroupId,
        },
      ] as const,
    classificationPerformance: ({
      feedFlowGroupId,
    }: {
      feedFlowGroupId: string;
    }) => [
      {
        ...q.feedFlowGroup.all[0],
        shape: 'classificationPerformance',
        feedFlowGroupId,
      },
    ],
  },
  feedFlowGroupMaterialSets: {
    all: [
      { entity: 'feed-flow-group-material-sets', ...materialStateDependent },
    ] as const,
    byId: (args: { inferenceHash: string; feedFlowGroupId: string }) =>
      [
        {
          ...q.feedFlowGroupMaterialSets.all[0],
          ...args,
        },
      ] as const,
  } as const,
  materialStateStatus: [
    { entity: 'material-state-status', ...materialStateDependent },
  ] as const,
  materialSet: {
    all: [{ entity: 'material-set', ...materialStateDependent }] as const,
    byHashes: (stateHash: string, materialSetHash: string) =>
      [
        {
          ...q.materialSet.all[0],
          stateHash,
          materialSetHash,
        },
      ] as const,
    container: (containerId: string, timestamp: Dayjs | undefined) =>
      [
        {
          ...q.materialSet.all[0],
          containerId,
          timestamp,
        },
      ] as const,
    purchasedMaterials: (vendorId: string | undefined) =>
      [
        {
          ...q.materialSet.all[0],
          vendorId,
        },
      ] as const,
    soldMaterial: (soldMaterialId: string) =>
      [
        {
          ...q.materialSet.all[0],
          soldMaterialId,
        },
      ] as const,
    internallySourcedMaterial: (internallySourcedMaterialId: string) =>
      [
        {
          ...q.materialSet.all[0],
          internallySourcedMaterialId,
        },
      ] as const,
    internallySourcedMaterials: (
      internalMaterialSourceId: string | undefined,
    ) =>
      [
        {
          ...q.materialSet.all[0],
          internalMaterialSourceId,
        },
      ] as const,
    internallySinkedMaterials: (
      facilityId: string | undefined,
      internalMaterialSinkId: string | undefined,
    ) =>
      [
        {
          ...q.materialSet.all[0],
          facilityId,
          internalMaterialSinkId,
        },
      ] as const,
  },
  genealogy: {
    all: [{ entity: 'genealogy', ...materialStateDependent }] as const,
    feedstock: (processRunId: string) =>
      [
        {
          ...q.genealogy.all[0],
          processRunId,
        },
      ] as const,
    container: (containerId: string, timestamp: Dayjs | undefined) =>
      [
        {
          ...q.genealogy.all[0],
          containerId,
          timestamp,
        },
      ] as const,
    truckLoad: (truckLoadId: string, timestamp: Dayjs | undefined) =>
      [
        {
          ...q.genealogy.all[0],
          truckLoadId,
          timestamp,
        },
      ] as const,
    purchasedMaterial: (purchasedMaterialId: string) =>
      [
        {
          ...q.genealogy.all[0],
          purchasedMaterialId,
        },
      ] as const,
    internallySourcedMaterial: (internallySourcedMaterialId: string) =>
      [
        {
          ...q.genealogy.all[0],
          internallySourcedMaterialId,
        },
      ] as const,
    soldMaterial: (soldMaterialId: string) =>
      [
        {
          ...q.genealogy.all[0],
          soldMaterialId,
        },
      ] as const,
    internalSink: (internalSinkId: string) =>
      [
        {
          ...q.genealogy.all[0],
          internalSinkId,
        },
      ] as const,
  } as const,
  inventory: {
    ledger: {
      status: [
        { entity: 'inventory-ledger-status', ...materialStateDependent },
      ] as const,
      events: {
        all: [{ entity: 'inventory-ledger-event' }] as const,
        lists: () =>
          [
            {
              ...q.inventory.ledger.events.all[0],
              shape: 'list',
            },
          ] as const,
        history: (startTime: Dayjs | null, endTime: Dayjs | null) =>
          [
            {
              ...q.inventory.ledger.events.lists()[0],
              startTime,
              endTime,
            },
          ] as const,
        // TODO(765): this will need to accept a month argument when it's used for the calendar
        calendar: () => [
          {
            ...q.inventory.ledger.events.all[0],
            shape: 'calendar',
          },
        ],
      } as const,
    } as const,
    facilityRecoveryGoalPathBehaviorTotals: (args: {
      facilityId: string;
      time: Dayjs | null;
    }) =>
      [
        {
          ...materialStateDependent,
          entity: 'recovery-goal-path-behavior-aggregated-inventory',
          ...args,
        },
      ] as const,
    facilityMassTotals: (facilityId: string, time?: Dayjs) =>
      [
        {
          ...materialStateDependent,
          entity: 'commodity-aggregated-inventory',
          facilityId,
          time,
        },
      ] as const,
  } as const,
  inferredProperties: {
    all: [{ entity: 'inferred-properties' }] as const,
    forHash: (materialStateHash: string) =>
      [
        {
          ...q.inferredProperties.all[0],
          materialStateHash,
        },
      ] as const,
    forMaterialSet: (materialStateHash: string, materialSetHash: string) =>
      [
        {
          ...q.inferredProperties.forHash(materialStateHash)[0],
          materialSetHash,
        },
      ] as const,
  } as const,
  facility: {
    all: [{ entity: 'facility' }] as const,
    list: () =>
      [
        {
          ...q.facility.all[0],
          shape: 'list',
        },
      ] as const,
    detail: (facilityId: FacilityId) =>
      [
        {
          ...q.facility.all[0],
          shape: 'detail',
          facilityId,
        },
      ] as const,
  } as const,
  facilityInsights: {
    all: [{ entity: 'facilityInsights' }] as const,
    metricQuery: (queryObj: { metricName: string; facet?: string }) =>
      [
        { ...q.facilityInsights.all[0], shape: 'metricQuery', queryObj },
      ] as const,
  } as const,
  scale: {
    all: [{ entity: 'scale' }] as const,
    lists: () =>
      [
        {
          ...q.scale.all[0],
          shape: 'list',
        },
      ] as const,
    list: (args: {
      facilityId?: string;
      scaleTypeId?: string;
      containerTypeId?: string;
      truckLoadCapable?: boolean;
      sampleCapable?: boolean;
    }) =>
      [
        {
          ...q.scale.lists()[0],
          ...args,
        },
      ] as const,
    detail: (scaleId: string | undefined) =>
      [
        {
          ...q.scale.all[0],
          shape: 'detail',
          scaleId,
        },
      ] as const,
    type: {
      all: [{ entity: 'scale-type' }] as const,
      list: () =>
        [
          {
            ...q.scale.type.all[0],
            shape: 'list',
          },
        ] as const,
      detail: (scaleTypeId: string) =>
        [
          {
            ...q.scale.type.all[0],
            shape: 'detail',
            scaleTypeId,
          },
        ] as const,
    } as const,
    reading: {
      all: [{ entity: 'scale-reading' }] as const,
      lists: () =>
        [
          {
            ...q.scale.reading.all[0],
            shape: 'list',
          },
        ] as const,
      list: (scaleId: string) =>
        [
          {
            ...q.scale.reading.all[0],
            shape: 'list',
            scaleId,
          },
        ] as const,
      detail: (scaleReadingId: string) =>
        [
          {
            ...q.scale.reading.all[0],
            shape: 'detail',
            scaleReadingId,
          },
        ] as const,
    } as const,
  } as const,
  process: {
    all: [{ entity: 'process' }] as const,
    list: (facilityId: string, detailed: boolean) =>
      [
        {
          ...q.process.all[0],
          shape: 'list',
          facilityId,
          detailed,
        },
      ] as const,
    detail: (processId: string | undefined, detailed: boolean) =>
      [
        {
          ...q.process.all[0],
          shape: 'detail',
          processId,
          detailed,
        },
      ] as const,
  } as const,
  processSystemConfigChanges: {
    all: [{ entity: 'process-system-hard-config-changes' }] as const,
    forProcess: (args: {
      processId: string;
      after: Dayjs;
      before: Dayjs | null;
    }) =>
      [
        {
          ...q.processSystemConfigChanges.all[0],
          ...args,
        },
      ] as const,
  } as const,
  processSystemObjectQuantities: {
    all: [{ entity: 'process-system-object-quantities' }] as const,
    forProcess: (args: { processId: string; after: Dayjs; before: Dayjs }) =>
      [
        {
          ...q.processSystemObjectQuantities.all[0],
          ...args,
        },
      ] as const,
  } as const,
  recommendedProcessRun: {
    all: [{ entity: 'recommended-process-run' }] as const,
    forFacility: (facilityId: string) =>
      [
        {
          ...q.recommendedProcessRun.all[0],
          shape: 'list',
          facilityId,
        },
      ] as const,
    forProcess: (processId: string) =>
      [
        {
          ...q.recommendedProcessRun.all[0],
          shape: 'list',
          processId,
        },
      ] as const,
  } as const,
  processStopReason: {
    all: [{ entity: 'process-stop-reason' }] as const,
    list: () =>
      [
        {
          ...q.processStopReason.all[0],
          shape: 'list',
        },
      ] as const,
  } as const,
  processPlannedProductionInterval: {
    all: [{ entity: 'process-planned-production-interval' }] as const,
    lists: () =>
      [
        {
          ...q.processPlannedProductionInterval.all[0],
          shape: 'list',
        },
      ] as const,
    forFacility: (facilityId: string) =>
      [
        {
          ...q.processPlannedProductionInterval.lists()[0],
          facilityId,
        },
      ] as const,
    forProcessDay: (
      facilityId: string,
      processId: string | undefined,
      date: Dayjs | undefined,
    ) =>
      [
        {
          ...q.processPlannedProductionInterval.forFacility(facilityId)[0],
          processId,
          date,
        },
      ] as const,
    detail: (id: string) =>
      [
        {
          ...q.processPlannedProductionInterval.all[0],
          shape: 'detail',
          id,
        },
      ] as const,
    temporalStats: {
      all: [{ entity: 'process-planned-production-temporal-stats' }] as const,
      byId: (id: string) =>
        [
          {
            ...q.processPlannedProductionInterval.temporalStats.all[0], // TODO(765): Invalidate status on detail mutation
            id,
            shape: 'single',
          },
        ] as const,
      byProcess: (args: {
        processId: string;
        after: Temporal.Instant | null;
        before: Temporal.Instant | null;
      }) =>
        [
          {
            ...q.processPlannedProductionInterval.temporalStats.all[0],
            shape: 'list',
            ...args,
          },
        ] as const,
    } as const,
    massStats: {
      all: [
        { entity: 'process-planned-production-interval-mass-stats' },
      ] as const,
      byId: (id: string) =>
        [
          {
            ...q.processPlannedProductionInterval.massStats.all[0],
            id,
            shape: 'single',
            ...materialStateDependent,
          },
        ] as const,
      byProcess: (args: {
        processId: string;
        after: Temporal.Instant | null;
        before: Temporal.Instant | null;
      }) =>
        [
          {
            ...q.processPlannedProductionInterval.massStats.all[0],
            shape: 'list',
            ...materialStateDependent,
            ...args,
          },
        ] as const,
    } as const,
  } as const,
  productionAggregateTemporalMetrics: {
    all: [
      { entity: 'production-agg-temporal-metrics', ...materialStateDependent },
    ] as const,
    detail: (args: {
      facilityId: string | null;
      after: Temporal.Instant;
      before?: Temporal.Instant;
    }) =>
      [{ ...q.productionAggregateTemporalMetrics.all[0], ...args }] as const,
  } as const,
  systemOperationalStateHistory: {
    all: [{ entity: 'system-operational-state-history' }] as const,
    processInterval: (args: {
      processId: string;
      after: Dayjs;
      before: Dayjs;
    }) =>
      [
        {
          ...q.systemOperationalStateHistory.all[0],
          ...args,
        },
      ] as const,
  } as const,
  transaction: {
    ofKind: <K extends MaterialTransactionDTO['kind']>(transactionType: K) =>
      [
        {
          transactionKind: transactionType,
        },
      ] as const,
    processBufferTransfer: {
      detail: (processBufferTransferId?: string) =>
        [
          {
            ...q.transaction.ofKind('BufferTransfer')[0],
            processBufferTransferId,
            shape: 'detail',
          },
        ] as const,
    } as const,
    internalSinkContainerTransfer: {
      detail: (internalSinkContainerTransferId: string) =>
        [
          {
            ...q.transaction.ofKind('InternalSinkContainerTransfer')[0],
            shape: 'detail',
            internalSinkContainerTransferId,
          },
        ] as const,
      lists: () =>
        [
          {
            ...q.transaction.ofKind('InternalSinkContainerTransfer')[0],
            shape: 'list',
          },
        ] as const,
      list: (facilityId: string) =>
        [
          {
            ...q.transaction.internalSinkContainerTransfer.lists()[0],
            facilityId,
          },
        ] as const,
    } as const,
    internallySourcedMaterialPartition: {
      status: (internallySourcedMaterialId: string) =>
        [
          {
            ...q.transaction.ofKind('InternallySourcedMaterialPartition')[0],
            internallySourcedMaterialId,
          },
        ] as const,
    } as const,
    purchasedMaterialPartition: {
      status: (purchasedMaterialId: string) =>
        [
          {
            ...q.transaction.ofKind('PurchasedMaterialPartition')[0],
            purchasedMaterialId,
          },
        ] as const,
    } as const,
    containerTransfer: {
      detail: (containerTransferId: string) => [
        {
          ...q.transaction.ofKind('ContainerTransfer')[0],
          shape: 'detail',
          containerTransferId,
        },
      ],
      lists: () => [
        {
          ...q.transaction.ofKind('ContainerTransfer')[0],
          shape: 'list',
        },
      ],
      list: (facilityId: string) => [
        {
          ...q.transaction.containerTransfer.lists()[0],
          facilityId,
        },
      ],
    } as const,
    truckLoadTransfer: {} as const,
    containerSale: {} as const,
    truckLoadSale: {} as const,
  } as const,
  processBufferDepletion: {
    all: [{ entity: 'process-buffer-depletion' }],
    detail: (processBufferDepletionId?: string) =>
      [
        {
          ...q.processBufferDepletion.all[0],
          processBufferDepletionId,
          shape: 'detail',
        },
      ] as const,
  } as const,
  processBufferRestoration: {
    all: [{ entity: 'process-buffer-restoration' }],
    detail: (processBufferRestorationId?: string) =>
      [
        {
          ...q.processBufferRestoration.all[0],
          processBufferRestorationId,
          shape: 'detail',
        },
      ] as const,
  } as const,
  outputContainerChange: {
    all: [{ entity: 'output-container-change' }],
    detail: (outputContainerChangeId?: string) =>
      [
        {
          ...q.outputContainerChange.all[0],
          outputContainerChangeId,
          shape: 'detail',
        },
      ] as const,
    detailByProcessOutputPort: (args: {
      processId: string | undefined;
      outputPortId: string | undefined;
      beforeTime: Dayjs | undefined;
    }) => [
      {
        ...q.outputContainerChange.all[0],
        ...args,
        shape: 'detail',
      },
    ],
  } as const,
  sortSystem: {
    all: [{ entity: 'sort-system' }],
    list: ({
      facilityId,
      systemType,
    }: {
      facilityId?: string | undefined;
      systemType?: SortSystemDTO['kind'];
    }) =>
      [
        {
          ...q.sortSystem.all[0],
          shape: 'list',
          facilityId,
          systemType,
        },
      ] as const,
    detail: (sortSystemId: string) =>
      [
        {
          ...q.sortSystem.all[0],
          shape: 'detail',
          sortSystemId,
        },
      ] as const,
    redWave: {
      metrics: {
        all: [{ entity: 'red-wave-system-metrics' }] as const,
        lists: () =>
          [
            {
              ...q.sortSystem.redWave.metrics.all[0],
              shape: 'list',
            },
          ] as const,
        list: (sortSystemId: string, start: Dayjs, end: Dayjs | null) =>
          [
            {
              ...q.sortSystem.redWave.metrics.lists()[0],
              sortSystemId,
              start,
              end,
            },
          ] as const,
        latest: (sortSystemId: string) =>
          [
            {
              ...q.sortSystem.redWave.metrics.all[0],
              shape: 'latest',
              sortSystemId,
            },
          ] as const,
      } as const,
      telemetry: {
        all: [{ entity: 'red-wave-telemetry' }] as const,
        lists: () =>
          [
            {
              ...q.sortSystem.redWave.telemetry.all[0],
              shape: 'list',
            },
          ] as const,
        list: (sortSystemId: string, start: Dayjs, end: Dayjs | undefined) =>
          [
            {
              ...q.sortSystem.redWave.telemetry.lists()[0],
              sortSystemId,
              start,
              end,
            },
          ] as const,
      } as const,
      configRevision: {
        all: [{ entity: 'red-wave-sort-program-config-revision' }] as const,
      } as const,
      profileRevision: {
        all: [{ entity: 'red-wave-sort-program-profile-revision' }] as const,
        detail: (profileRevisionId: string) =>
          [
            {
              ...q.sortSystem.redWave.profileRevision.all[0],
              shape: 'detail',
              profileRevisionId,
            },
          ] as const,
      } as const,
      materialClassMetadata: {
        all: [{ entity: 'red-wave-material-class-metadata' }] as const,
        detail: (materialClassMetadataId: string) =>
          [
            {
              ...q.sortSystem.redWave.materialClassMetadata.all[0],
              materialClassMetadataId,
            },
          ] as const,
      } as const,
      sortProgramSnapshot: {
        all: [{ entity: 'red-wave-sort-program-snapshot' }] as const,
        forSystem: <const T extends string | undefined>(sortSystemId: T) => [
          {
            ...q.sortSystem.redWave.sortProgramSnapshot.all[0],
            sortSystemId,
          },
        ],
        currentSnapshots: (sortSystemId: string) =>
          [
            {
              ...q.sortSystem.redWave.sortProgramSnapshot.forSystem(
                sortSystemId,
              )[0],
              shape: 'list',
            },
          ] as const,
        active: (sortSystemId: string, timestamp: Dayjs | undefined) =>
          [
            {
              ...q.sortSystem.redWave.sortProgramSnapshot.forSystem(
                sortSystemId,
              )[0],
              sortSystemId,
              active: true,
              shape: 'detail',
              timestamp,
            },
          ] as const,
        detail: (
          sortSystemId: string,
          sortProgramNumber: RedWaveSortProgramNumber,
          timestamp: Dayjs | undefined,
        ) =>
          [
            {
              ...q.sortSystem.redWave.sortProgramSnapshot.forSystem(
                sortSystemId,
              )[0],
              shape: 'detail',
              sortProgramNumber,
              timestamp,
            },
          ] as const,
      },
    } as const,
    chutec: {
      sortProgram: {
        all: [{ entity: 'chutec-sort-program' }] as const,
        lists: () =>
          [
            {
              ...q.sortSystem.chutec.sortProgram.all[0],
              shape: 'list',
            },
          ] as const,
        list: (sortSystemId?: string) =>
          [
            {
              ...q.sortSystem.chutec.sortProgram.lists()[0],
              sortSystemId,
            },
          ] as const,
      } as const,
      materialClass: {
        all: [{ entity: 'chutec-material-class' }] as const,
        detail: (chutecMaterialClassId: string) =>
          [
            {
              ...q.sortSystem.chutec.materialClass.all[0],
              shape: 'detail',
              chutecMaterialClassId,
            },
          ] as const,
      } as const,
      telemetry: {
        materialClassAreaWindow: {
          all: [{ entity: 'chutec-material-class-area-window' }] as const,
          lists: () =>
            [
              {
                ...q.sortSystem.chutec.telemetry.materialClassAreaWindow.all[0],
                shape: 'list',
              },
            ] as const,
          list: (
            systemId: string,
            stream: ChutecStream,
            start: Dayjs,
            end: Dayjs | undefined,
          ) =>
            [
              {
                ...q.sortSystem.chutec.telemetry.materialClassAreaWindow.lists()[0],
                systemId,
                stream,
                start,
                end,
              },
            ] as const,
        } as const,
      } as const,
    } as const,
  } as const,
  recoveryGoal: {
    all: [{ entity: 'recovery-goal' }],
    list: () => [{ ...q.recoveryGoal.all[0], shape: 'list' }] as const,
    detail: (recoveryGoalId: string) =>
      [{ ...q.recoveryGoal.all[0], recoveryGoalId, shape: 'detail' }] as const,
    forProcessRunSystems: (processRunId: string) =>
      [
        {
          ...q.recoveryGoal.all[0],
          shape: 'by-system',
          processRunId,
        },
      ] as const,
    classificationPerformance: ({
      before,
      after,
      facilityId,
    }: {
      before?: Temporal.Instant;
      after: Temporal.Instant;
      facilityId: string;
    }) => [
      {
        ...q.recoveryGoal.all[0],
        shape: 'classification-performance',
        before,
        after,
        facilityId,
      },
    ],
  } as const,
  recoveryStrategy: {
    all: [{ entity: 'recovery-strategy' }] as const,
    list: () => [{ ...q.recoveryStrategy.all[0], shape: 'list' }] as const,
    detail: (recoveryStrategyId: string) =>
      [
        {
          ...q.recoveryStrategy.all[0],
          shape: 'detail',
          recoveryStrategyId,
        },
      ] as const,
  } as const,
  recoveryStrategySimulation: {
    all: [{ entity: 'recovery-strategy-simulation' }] as const,
    list: (args: { strategyId?: string }) =>
      [
        { ...q.recoveryStrategySimulation.all[0], shape: 'list', ...args },
      ] as const,
    detail: (simulationId: string) =>
      [
        {
          ...q.recoveryStrategySimulation.all[0],
          shape: 'detail',
          simulationId,
        },
      ] as const,
  },
  recoveryGoalPath: {
    all: [{ entity: 'recovery-goal-path' }],
    forProcessRun: (processRunId: string) =>
      [
        {
          ...q.recoveryGoalPath.all[0],
          processRunId,
        },
      ] as const,
    forProcessAtTime: (processId: string, instant: Dayjs | null) =>
      [
        {
          ...q.recoveryGoalPath.all[0],
          processId,
          instant,
        },
      ] as const,
  } as const,
  processRunContainerStates: {
    all: [
      { entity: 'process-run-container-states', ...materialStateDependent },
    ] as const,
    forProcessRun: (processRunId: string) =>
      [
        {
          ...q.processRunContainerStates.all[0],
          processRunId,
        },
      ] as const,
  } as const,
  processRunMassThroughput: {
    all: [
      { entity: 'process-run-mass-throughput', ...materialStateDependent },
    ] as const,
    forProcessRun: (processRunId: string) =>
      [
        {
          ...q.processRunMassThroughput.all[0],
          processRunId,
        },
      ] as const,
    list: (args: {
      processId: string | undefined;
      recoveryGoalId: string | undefined;
      plannedProductionIntervalId: string | undefined;
      redWaveProfileRevisionId: string | undefined;
      redWaveConfigRevisionId: string | undefined;
    }) =>
      [
        {
          ...q.processRunMassThroughput.all[0],
          ...args,
        },
      ] as const,
  } as const,
  processRunClassificationPerformance: {
    all: [
      {
        entity: 'process-run-classification-performance',
        ...materialStateDependent,
      },
    ],
    forProcessRun: (processRunId: string) =>
      [
        {
          ...q.processRunClassificationPerformance.all[0],
          processRunId,
        },
      ] as const,
  } as const,
  vendor: {
    all: [{ entity: 'vendors' }],
    list: () => [{ ...q.vendor.all[0], shape: 'list' }] as const,
    detail: (vendorId: string | undefined) =>
      [{ ...q.vendor.all[0], shape: 'detail', vendorId }] as const,
  } as const,
  customer: {
    all: [{ entity: 'customer' }],
    list: () => [{ ...q.customer.all[0], shape: 'list' }] as const,
    detail: (customerId: string | undefined) =>
      [{ ...q.customer.all[0], shape: 'detail', customerId }] as const,
  } as const,
  internalSource: {
    all: [{ entity: 'internal-material-source' }] as const,
    list: () => [{ ...q.internalSource.all[0], shape: 'list' }] as const,
    detail: (internalMaterialSourceId: string | undefined) =>
      [
        {
          ...q.internalSource.all[0],
          shape: 'detail',
          internalMaterialSourceId,
        },
      ] as const,
    analysis: {
      compositionByCommodity: {
        all: [
          {
            entity: 'internal-material-source-composition-by-commodity',
            ...materialStateDependent,
          },
        ] as const,
        list: (
          intervalStart: string | undefined,
          intervalEnd: string | undefined,
        ) =>
          [
            {
              ...q.internalSource.analysis.compositionByCommodity.all[0],
              shape: 'list',
              intervalStart,
              intervalEnd,
            },
          ] as const,
        detail: (args: {
          commodityId: string;
          intervalStart: string | undefined;
          intervalEnd: string | undefined;
        }) =>
          [
            {
              ...q.internalSource.analysis.compositionByCommodity.all[0],
              shape: 'detail',
              ...args,
            },
          ] as const,
      },
      sourcedMass: {
        all: [
          {
            entity: 'internal-material-source-sourced-mass',
            ...materialStateDependent,
          },
        ] as const,
        list: (instant: Temporal.Instant | undefined) =>
          [
            {
              ...q.internalSource.analysis.sourcedMass.all[0],
              shape: 'list',
              instant,
            },
          ] as const,
        detail: (
          internalMaterialSourceId: string,
          instant: Temporal.Instant | undefined,
        ) =>
          [
            {
              ...q.internalSource.analysis.sourcedMass.all[0],
              shape: 'detail',
              internalMaterialSourceId,
              instant,
            },
          ] as const,
      },
      composition: {
        all: [
          {
            entity: 'internal-material-source-composition',
            ...materialStateDependent,
          },
        ] as const,
        detail: (args: {
          internalMaterialSourceId: string | null;
          intervalStart: string | undefined;
          intervalEnd: string | undefined;
        }) =>
          [
            {
              ...q.internalSource.analysis.composition.all[0],
              shape: 'detail',
              ...args,
            },
          ] as const,
      },
      compositionComparison: {
        all: [
          {
            entity: 'internal-material-source-composition-comparison-analysis',
            ...materialStateDependent,
          },
        ] as const,
        list: () =>
          [
            {
              ...q.internalSource.analysis.compositionComparison.all[0],
              shape: 'list',
            },
          ] as const,
        detail: (analysisId: string) =>
          [
            {
              ...q.internalSource.analysis.compositionComparison.all[0],
              shape: 'detail',
              analysisId,
            },
          ] as const,
        result: (analysisId: string) =>
          [
            {
              ...q.internalSource.analysis.compositionComparison.all[0],
              shape: 'result',
              analysisId,
            },
          ] as const,
      } as const,
    } as const,
  } as const,
  materialClass: {
    all: [
      {
        entity: 'material-class',
      },
    ] as const,
    list: () =>
      [
        {
          ...q.materialClass.all[0],
          shape: 'list',
        },
      ] as const,
    detail: (materialClassId: string) =>
      [
        {
          ...q.materialClass.all[0],
          shape: 'detail',
          materialClassId,
        },
      ] as const,
  } as const,
  materialClassSetComposition: {
    all: [{ entity: 'material-class-set-composition' }] as const,
    list: () =>
      [{ ...q.materialClassSetComposition.all[0], shape: 'list' }] as const,
    detail: (compositionId: string) =>
      [
        {
          ...q.materialClassSetComposition.all[0],
          shape: 'detail',
          compositionId,
        },
      ] as const,
  } as const,
  commoditySpotPrice: {
    all: [{ entity: 'commoditySpotPrice' }] as const,
    byCommodityId: (commodityId: string) =>
      [{ ...q.commoditySpotPrice.all[0], commodityId }] as const,
    detail: (
      commodityId: string | undefined,
      commoditySpotPriceId: string | undefined,
    ) =>
      [
        {
          ...q.commoditySpotPrice.all[0],
          shape: 'detail',
          commodityId,
          commoditySpotPriceId,
        },
      ] as const,
  } as const,
  customTray: {
    all: [{ entity: 'custom-tray' }] as const,
    list: () => [{ ...q.customTray.all[0], shape: 'list' }] as const,
  } as const,
  commodity: {
    all: [{ entity: 'commodity' }] as const,
    list: () => [{ ...q.commodity.all[0], shape: 'list' }] as const,
    detail: (commodityId: string | undefined) =>
      [{ ...q.commodity.all[0], shape: 'detail', commodityId }] as const,
    purities: (
      facilityId: string,
      after: Temporal.Instant,
      before?: Temporal.Instant,
    ) =>
      [
        { ...q.commodity.all[0], shape: 'purities', facilityId, after, before },
      ] as const,
    masses: (args: {
      after: Temporal.Instant;
      before: Temporal.Instant;
      facilityId: string;
      includeNegativeMasses: boolean;
    }) => [{ ...q.commodity.all[0], shape: 'masses', ...args }] as const,
  } as const,
  defaultCommodityAssignment: {
    all: [{ entity: 'default-commodity-assignment' }] as const,
    list: () =>
      [{ ...q.defaultCommodityAssignment.all[0], shape: 'list' }] as const,
    detail: (id: string) =>
      [
        {
          ...q.defaultCommodityAssignment.all[0],
          shape: 'detail',
          id,
        },
      ] as const,
  } as const,
  defaultCommodityAssignmentRecoveryTree: {
    all: [{ entity: 'default-commodity-assignment-recovery-tree' }] as const,
    detail: (recoveryStrategyId: string, internalMaterialSourceId: string) =>
      [
        {
          ...q.defaultCommodityAssignmentRecoveryTree.all[0],
          shape: 'detail',
          recoveryStrategyId,
          internalMaterialSourceId,
        },
      ] as const,
  } as const,
  unassignedCommodityPath: {
    all: [
      { entity: 'unassigned-commodity-path', ...materialStateDependent },
    ] as const,
  } as const,
  containerType: {
    all: [{ entity: 'material-container-type' }] as const,
    list: () =>
      [
        {
          ...q.containerType.all[0],
          shape: 'list',
        },
      ] as const,
  } as const,
  container: {
    all: [{ entity: 'material-container' }] as const,
    detail: (containerId: string | undefined) =>
      [
        {
          ...q.container.all[0],
          shape: 'detail',
          containerId,
        },
      ] as const,
    lists: () =>
      [
        {
          ...q.container.all[0],
          shape: 'list',
        },
      ] as const,
    list: (facilityId: string) =>
      [
        {
          ...q.container.lists()[0],
          facilityId,
        },
      ] as const,
    history: (containerId: string) =>
      [
        {
          ...q.container.all[0],
          shape: 'history',
          containerId,
          ...materialStateDependent,
        },
      ] as const,
    occupiedStates: (facilityId: string, timestamp: Dayjs | undefined) =>
      [
        {
          ...q.container.all[0],
          shape: 'occupied-states',
          facilityId,
          timestamp,
          ...materialStateDependent,
        },
      ] as const,
    weighedAndSampledConstituents: (args: {
      containerId: string;
      instant: Temporal.Instant | null;
    }) =>
      [
        {
          ...q.container.all[0],
          shape: 'weighed-and-sampled-constituents',
          ...materialStateDependent,
          ...args,
        },
      ] as const,
  } as const,
  sample: {
    all: [{ entity: 'sample' }] as const,
    lists: () => [{ ...q.sample.all[0], shape: 'list' }] as const,
    list: (args: { containerId?: string }) =>
      [
        {
          ...q.sample.lists()[0],
          ...args,
        },
      ] as const,
    detail: (sampleId: string) =>
      [
        {
          ...q.sample.all[0],
          shape: 'detail',
          sampleId,
        },
      ] as const,
    flat: {
      all: [{ entity: 'flattened-sample' }] as const,
      lists: () => [{ ...q.sample.flat.all[0], shape: 'list' }] as const,
      list: (args: { commodityId?: string }) =>
        [
          {
            ...q.sample.flat.lists()[0],
            ...args,
          },
        ] as const,
    },
  },
  materialClassSet: {
    all: [{ entity: 'material-class-set' }] as const,
    list: () =>
      [
        {
          ...q.materialClassSet.all[0],
          shape: 'list',
        },
      ] as const,
    detail: (materialClassSetId: string) =>
      [
        {
          ...q.materialClassSet.all[0],
          shape: 'detail',
          materialClassSetId,
        },
      ] as const,
  } as const,
  materialClassSetConversion: {
    graph: [{ entity: 'material-class-set-conversion-graph' }] as const,
    all: [{ entity: 'material-class-set-conversion' }] as const,
    lists: () =>
      [{ ...q.materialClassSetConversion.all[0], shape: 'list' }] as const,
    list: (args: {
      sourceMaterialClassSetId: string | undefined;
      targetMaterialClassSetId: string | undefined;
    }) =>
      [
        {
          ...q.materialClassSetConversion.lists()[0],
          ...args,
        },
      ] as const,
    details: () =>
      [
        {
          ...q.materialClassSetConversion.all[0],
          shape: 'detail',
        },
      ] as const,
    detail: (conversionId: string) =>
      [{ ...q.materialClassSetConversion.details()[0], conversionId }] as const,
  },
  materialClassSetsSampleAnalyses: {
    all: [{ entity: 'material-class-set-sample-analyses' }] as const,
    list: () =>
      [
        {
          ...q.materialClassSetsSampleAnalyses.all[0],
          shape: 'list',
        },
      ] as const,
  } as const,
  sampleAnalysis: {
    all: [{ entity: 'sample-analysis' }] as const,
    lists: () => [{ ...q.sampleAnalysis.all[0], shape: 'list' }] as const,
    list: (args: { sampleId?: string }) =>
      [{ ...q.sampleAnalysis.lists()[0], ...args }] as const,
    samplingSuite: {
      all: () =>
        [{ ...q.sampleAnalysis.all[0], kind: 'sampling-suite' }] as const,
      detail: (samplingSuiteSampleAnalysisId: string) =>
        [
          {
            ...q.sampleAnalysis.samplingSuite.all()[0],
            shape: 'detail',
            samplingSuiteSampleAnalysisId,
          },
        ] as const,
    } as const,
    manual: {
      all: () => [{ ...q.sampleAnalysis.all[0], kind: 'manual' }] as const,
    } as const,
  } as const,
  samplingSuite: {
    all: [{ entity: 'sampling-suite' }] as const,
    list: () => [{ ...q.samplingSuite.all[0], shape: 'list' }],
  } as const,
  samplingSession: {
    all: [{ entity: 'sampling-session' }] as const,
    lists: () => [{ ...q.samplingSession.all[0], shape: 'list' }] as const,
    list: (args: {
      samplingSuiteSampleAnalysisId?: string;
      samplingSuiteId?: string;
      isOwned?: boolean;
      isOpen?: boolean;
    }) => [{ ...q.samplingSession.lists()[0], ...args }] as const,
    details: () => [{ ...q.samplingSession.all[0], shape: 'detail' }] as const,
    detail: (sessionId: string) =>
      [{ ...q.samplingSession.details()[0], sessionId }] as const,
  },
  samplingSuiteCapture: {
    all: [{ entity: 'sampling-suite-capture' }] as const,
    lists: () =>
      [
        {
          ...q.samplingSuiteCapture.all[0],
          shape: 'list',
        },
      ] as const,
    list: (args: {
      unlinked: boolean | null;
      suiteId: string | null;
      archived: boolean | null;
    }) =>
      [
        {
          ...q.samplingSuiteCapture.lists()[0],
          ...args,
        },
      ] as const,

    segmentation: (captureId: string) =>
      [
        { ...q.samplingSuiteCapture.all[0], captureId, shape: 'segmentation' },
      ] as const,
  } as const,
  samplingSuiteCaptureImage: {
    all: [{ entity: 'sampling-suite-capture-image' }] as const,
    previews: () =>
      [{ ...q.samplingSuiteCaptureImage.all[0], shape: 'preview' }] as const,
    preview: (captureId: string) =>
      [{ ...q.samplingSuiteCaptureImage.previews()[0], captureId }] as const,
    fulls: () =>
      [{ ...q.samplingSuiteCaptureImage.all[0], shape: 'full' }] as const,
    full: (captureId: string) =>
      [{ ...q.samplingSuiteCaptureImage.fulls()[0], captureId }] as const,
  },
  truckLoad: {
    all: [{ entity: 'material-truck-load' }] as const,
    detail: (truckLoadId: string) =>
      [
        {
          ...q.truckLoad.all[0],
          shape: 'detail',
          truckLoadId,
        },
      ] as const,
    list: () =>
      [
        {
          ...q.truckLoad.all[0],
          shape: 'list',
        },
      ] as const,
    occupiedStates: (timestamp: Dayjs | undefined) =>
      [
        {
          ...q.truckLoad.all[0],
          shape: 'occupied-states',
          timestamp,
          ...materialStateDependent,
        },
      ] as const,
  } as const,
  purchasedMaterial: {
    all: [{ entity: 'purchased-material' }] as const,
    detail: (purchasedMaterialId: string) =>
      [
        {
          ...q.purchasedMaterial.all[0],
          shape: 'detail',
          purchasedMaterialId,
        },
      ] as const,
    lists: () =>
      [
        {
          ...q.purchasedMaterial.all[0],
          shape: 'list',
        },
      ] as const,
    list: (vendorId?: string) =>
      [
        {
          ...q.purchasedMaterial.lists()[0],
          vendorId,
        },
      ] as const,
  } as const,
  internallySourcedMaterial: {
    all: [{ entity: 'internally-sourced-material' }] as const,
    detail: (internallySourcedMaterialId: string) =>
      [
        {
          ...q.internallySourcedMaterial.all[0],
          shape: 'detail',
          internallySourcedMaterialId,
        },
      ] as const,
    lists: () =>
      [
        {
          ...q.internallySourcedMaterial.all,
          shape: 'list',
        },
      ] as const,
    list: (internalMaterialSourceId?: string) =>
      [
        {
          ...q.internallySourcedMaterial.lists()[0],
          internalMaterialSourceId,
        },
      ] as const,
  } as const,
  internallySinkedMaterial: {
    detail: (args: { internalSinkContainerTransferId: string }) => [
      {
        ...q.transaction.ofKind('InternalSinkContainerTransfer')[0],
        shape: 'detail',
        ...args,
        ...materialStateDependent,
      },
    ],
  },
  soldMaterial: {
    all: [{ entity: 'sold-material' }] as const,
    detail: (soldMaterialId: string) =>
      [
        {
          ...q.soldMaterial.all[0],
          shape: 'detail',
          soldMaterialId,
        },
      ] as const,
    lists: () =>
      [
        {
          ...q.soldMaterial.all[0],
          shape: 'list',
        },
      ] as const,
    list: (customerId?: string) =>
      [
        {
          ...q.soldMaterial.lists()[0],
          customerId,
        },
      ] as const,
  } as const,
  internalMaterialSink: {
    all: [{ entity: 'internal-material-sink' }] as const,
    detail: (internalMaterialSinkId: string) =>
      [
        {
          ...q.internalMaterialSink.all[0],
          shape: 'detail',
          internalMaterialSinkId,
        },
      ] as const,
    lists: () =>
      [
        {
          ...q.internalMaterialSink.all[0],
          shape: 'list',
        },
      ] as const,
    list: (facilityId: string) =>
      [
        {
          ...q.internalMaterialSink.lists()[0],
          facilityId,
        },
      ] as const,
  } as const,
} as const;

export const queryKeys = q;

export class QueryKeyInvalidator {
  private readonly queryClient: QueryClient;

  constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public invalidateMaterialState() {
    void this.queryClient.invalidateQueries([materialStateDependent]);
  }

  public invalidateInventoryLedger() {
    this.invalidateKeys(
      queryKeys.inventory.ledger.events.all,
      queryKeys.inventory.ledger.status,
    );
    this.invalidateMaterialState();
  }

  public invalidateKeys(...queryKeys: QueryKeyFactoryKey[]) {
    for (const queryKey of queryKeys) {
      this.invalidateKey(queryKey);
    }
  }

  public invalidateKey(
    queryKey: QueryKeyFactoryKey,
    predicate?: (query: Query) => boolean,
  ) {
    void this.queryClient.invalidateQueries({
      queryKey,
      predicate,
    });
  }

  public removeQueries(queryKey: QueryKeyFactoryKey) {
    this.queryClient.removeQueries({ queryKey });
  }

  public removeKey(queryKey: QueryKeyFactoryKey) {
    this.queryClient.removeQueries({ queryKey });
  }

  public removeKeys(...queryKeys: QueryKeyFactoryKey[]) {
    for (const queryKey of queryKeys) {
      this.removeKey(queryKey);
    }
  }

  public resetKey(queryKey: QueryKeyFactoryKey) {
    void this.queryClient.resetQueries({ queryKey });
  }
}

export function useQueryKeyInvalidator() {
  const queryClient = useQueryClient();
  return new QueryKeyInvalidator(queryClient);
}
