import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { EditContainerTransferDrawerForm } from '../../ContainerTransfer/EditContainerTransferDrawerForm';
import { useDeleteEntityModal } from '../../Form/DeleteEntity';
import { useDeleteContainerTransfer } from '../../api/containerTransfer';
import { CalendarDateTime } from '../../common';
import { MaterialContainerTransferDTO } from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem';
import { EditMenuItem } from '../EditMenuItem';
import { TransferTypeCell } from '../InventoryLedgerEventTypeCell';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { TransactionStatus } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu';

export function ContainerTransferRow(props: {
  id: string;
  transfer: MaterialContainerTransferDTO;
  status: TransactionStatus;
}) {
  const { transfer, status, id } = props;

  const deleteMutation = useDeleteContainerTransfer();
  const openDeleteModal = useDeleteEntityModal({
    mutationVariables: transfer.id,
    deleteMutation,
    entityName: 'Container Material Transfer',
  });
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <InventoryLedgerRowTemplate
        id={id}
        entryStatus={status}
        date={<CalendarDateTime iso8601={transfer.effectiveTimestamp} />}
        eventType={<TransferTypeCell partial={!transfer.sourceEmptied} />}
        source={
          <ContainerIdName
            containerId={transfer.sourceContainerId}
            variant='icon-name-link'
            time={dayjs.utc(transfer.effectiveTimestamp)}
          />
        }
        destination={
          <ContainerIdName
            containerId={transfer.destinationContainerId}
            variant='icon-name-link'
            time={dayjs.utc(transfer.effectiveTimestamp)}
          />
        }
        actions={
          <RowActionsMenu>
            <EditMenuItem onClick={openDrawer} />
            <DeleteMenuItem onClick={openDeleteModal} />
          </RowActionsMenu>
        }
      />
      <EditContainerTransferDrawerForm
        transferId={transfer.id}
        opened={drawerOpened}
        onClose={closeDrawer}
      />
    </>
  );
}
