import { NumberInput } from '@mantine/core';
import { useForm, UseFormReturnType, zodResolver } from '@mantine/form';
import { z } from 'zod';
import {
  useCreateRadius3dsSampleParameters,
  usePatchRadius3dsSampleParameters,
} from '../../api/radius3dsSampleParameters';
import { DrawerForm, DrawerFormDrawerProps } from '../../Form/DrawerForm';
import {
  Radius3DSSampleParametersCreationDTO,
  Radius3DSSampleParametersPatchDTO,
  SampleDTO,
  SampleId,
} from '../../rest-client';

const Radius3DSSampleParametersFormValues = z.object({
  relativeDensity: z.number().positive(),
  throughput: z.number().positive(),
});
type Radius3DSSampleParametersFormValues = z.infer<
  typeof Radius3DSSampleParametersFormValues
>;

function Radius3DSSampleParametersFormFields(props: {
  form: UseFormReturnType<Radius3DSSampleParametersFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <NumberInput
        precision={2}
        label='Relative Density'
        withAsterisk
        {...form.getInputProps('relativeDensity')}
      />
      <NumberInput
        label='Throughput (tons/hour)'
        withAsterisk
        {...form.getInputProps('throughput')}
      />
    </>
  );
}

function useRadius3DSSampleParametersForm(args?: {
  initialValues?: Radius3DSSampleParametersFormValues;
}) {
  return useForm<Radius3DSSampleParametersFormValues>({
    validate: zodResolver(Radius3DSSampleParametersFormValues),
    initialValues: args?.initialValues,
  });
}

export function AddRadius3DSSampleParametersDrawerForm(
  props: DrawerFormDrawerProps & { sampleId: SampleId },
) {
  const { sampleId, ...drawerFormProps } = props;
  const createMutation = useCreateRadius3dsSampleParameters();
  const form = useRadius3DSSampleParametersForm();

  const formValuesToDto = ({
    relativeDensity,
    throughput,
  }: Radius3DSSampleParametersFormValues) => {
    const dto: Radius3DSSampleParametersCreationDTO = {
      relativeDensity,
      shortTonsPerHour: throughput,
    };
    return { sampleId, dto };
  };

  return (
    <DrawerForm
      entityName='3DS Parameters'
      form={form}
      formVariant='create'
      mutation={createMutation}
      formValuesToDto={formValuesToDto}
      {...drawerFormProps}
    >
      <Radius3DSSampleParametersFormFields form={form} />
    </DrawerForm>
  );
}

export function EditRadius3DSSampleParametersDrawerForm(
  props: DrawerFormDrawerProps & { sample: SampleDTO },
) {
  const { sample, ...drawerProps } = props;

  const patchMutation = usePatchRadius3dsSampleParameters();
  const form = useRadius3DSSampleParametersForm({
    initialValues:
      sample.radius3DSSampleParameters === null
        ? undefined
        : {
            relativeDensity: sample.radius3DSSampleParameters.relativeDensity,
            throughput: sample.radius3DSSampleParameters.shortTonsPerHour,
          },
  });

  const formValuesToDto = ({
    relativeDensity,
    throughput,
  }: Radius3DSSampleParametersFormValues) => {
    const patch: Radius3DSSampleParametersPatchDTO = {
      ...(form.isDirty('relativeDensity') && { relativeDensity }),
      ...(form.isDirty('throughput') && { shortTonsPerHour: throughput }),
    };
    return {
      sampleId: sample.id,
      patch,
    };
  };

  return (
    <DrawerForm
      entityName='3DS Parameters'
      form={form}
      formVariant='edit'
      mutation={patchMutation}
      formValuesToDto={formValuesToDto}
      closeOnClickOutside
      {...drawerProps}
    >
      <Radius3DSSampleParametersFormFields form={form} />
    </DrawerForm>
  );
}
