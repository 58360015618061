import { useMutation } from '@tanstack/react-query';
import {
  Radius3DSSampleParametersCreationDTO,
  Radius3DSSampleParametersPatchDTO,
  SampleService,
} from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function createRadius3dsSampleParameters(args: {
  sampleId: string;
  dto: Radius3DSSampleParametersCreationDTO;
}) {
  await SampleService.createRadius3DsSampleParameters(args.sampleId, args.dto);
}

export function useCreateRadius3dsSampleParameters() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createRadius3dsSampleParameters,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.sample.all,
        queryKeys.sample.flat.all,
        queryKeys.materialClassSetsSampleAnalyses.all,
        queryKeys.sampleAnalysis.lists(),
      );
    },
  });
}

async function patchRadius3dsSampleParameters(args: {
  sampleId: string;
  patch: Radius3DSSampleParametersPatchDTO;
}) {
  await SampleService.patchRadius3DsSampleParameters(args.sampleId, args.patch);
}

export function usePatchRadius3dsSampleParameters() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchRadius3dsSampleParameters,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.sample.all,
        queryKeys.sample.flat.all,
        queryKeys.materialClassSetsSampleAnalyses.all,
        queryKeys.sampleAnalysis.lists(),
      );
    },
  });
}

async function deleteRadius3dsSampleParameters(sampleId: string) {
  await SampleService.deleteRadius3DsSampleParameters(sampleId);
}

export function useDeleteRadius3dsSampleParameters() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteRadius3dsSampleParameters,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.sample.all,
        queryKeys.sample.flat.all,
        queryKeys.materialClassSetsSampleAnalyses.all,
        queryKeys.sampleAnalysis.lists(),
      );
    },
  });
}
