import { useAllSamplesTableStore } from './AllSamplesTableState';
import { SampleTable, SampleTableBaseProps } from './SampleTable';
import {
  SampleTableCommodityGroupRow,
  SampleTableRow,
} from './SampleTableState';

export type AllSamplesTableVariant = {
  type: 'allSamples';
  setFilteredRows: (rows: SampleTableRow[]) => void;
  groupedByCommodity: boolean;
  setGroupedByCommodity: (isGrouped: boolean) => void;
  setCommodityGroupRows: (groupRows: SampleTableCommodityGroupRow[]) => void;
};

export function AllSamplesTable(props: SampleTableBaseProps) {
  const { materialClassSetSamples } = props;

  const {
    setFilteredRows,
    groupedByCommodity,
    setGroupedByCommodity,
    setCommodityGroupRows,
  } = useAllSamplesTableStore();

  const variant: AllSamplesTableVariant = {
    type: 'allSamples',
    setFilteredRows,
    groupedByCommodity,
    setGroupedByCommodity,
    setCommodityGroupRows,
  };

  return (
    <SampleTable
      materialClassSetSamples={materialClassSetSamples}
      variant={variant}
    />
  );
}
