import { create } from 'zustand';
import { MaterialClassSetId } from '../../rest-client';
import {
  BaseSampleTableStore,
  SampleTableCommodityGroupRow,
  SampleTableRow,
} from './SampleTableState';

export type AllSamplesTableStore = BaseSampleTableStore & {
  groupedByCommodity: boolean;
  setGroupedByCommodity: (isGrouped: boolean) => void;
  commodityGroupRows: SampleTableCommodityGroupRow[];
  setCommodityGroupRows: (groupRows: SampleTableCommodityGroupRow[]) => void;
};

export const useAllSamplesTableStore = create<AllSamplesTableStore>()(
  (set) => ({
    filteredRows: undefined,
    setFilteredRows: (rows: SampleTableRow[]) => {
      set(() => ({ filteredRows: rows }));
    },
    materialClassSetId: null,
    setMaterialClassSetId: (id: MaterialClassSetId | null) => {
      set(() => ({
        materialClassSetId: id,
        filteredRows: undefined,
        commodityGroupRows: [],
        groupedByCommodity: false,
      }));
    },
    groupedByCommodity: false,
    setGroupedByCommodity: (isGrouped: boolean) => {
      set(() => ({ groupedByCommodity: isGrouped }));
    },
    commodityGroupRows: [],
    setCommodityGroupRows: (rows: SampleTableCommodityGroupRow[]) => {
      set(() => ({ commodityGroupRows: rows }));
    },
  }),
);
