import { Group, MantineColor, Skeleton, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { useBasicProcess } from '../api/process';
import { ProcessIcon } from '../Icons';
import { LinkText } from '../Link';
import { ProcessDTO } from '../rest-client';
import { Router } from '../router';

export interface ProcessNameProps {
  process: ProcessDTO;
  noLink?: boolean;
  withIcon?: boolean;
  color?: 'dimmed' | MantineColor;
}
export function ProcessName(props: ProcessNameProps) {
  const { process, noLink, withIcon, color } = props;

  const IconWrapper = ({ children }: { children: ReactNode }) => {
    if (withIcon) {
      return (
        <Group spacing='xs'>
          <ProcessIcon />
          {children}
        </Group>
      );
    } else {
      return <>{children}</>;
    }
  };

  if (noLink) {
    return (
      <IconWrapper>
        <Text color={color}>{process.name}</Text>
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <LinkText to={Router.ProcessDetail({ processId: process.id })}>
        {process.name}
      </LinkText>
    </IconWrapper>
  );
}

export type ProcessIdNameProps = Omit<ProcessNameProps, 'process'> & {
  processId: string;
};

export function ProcessIdName(props: ProcessIdNameProps) {
  const { processId, ...rest } = props;
  const { data: process, isError } = useBasicProcess(processId);

  if (process) {
    return <ProcessName process={process} {...rest} />;
  }

  if (isError) {
    return <Text color='red'>Error getting name</Text>;
  }

  return (
    <Skeleton visible>
      <Text>Loading name...</Text>
    </Skeleton>
  );
}
