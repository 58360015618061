import { Alert, Pagination, Stack } from '@mantine/core';
import { IconArchive } from '@tabler/icons-react';
import { ReactNode, useState } from 'react';
import {
  usePatchSamplingSuiteCapture,
  useSamplingSuiteCaptures,
} from '../../api/samplingSuite';
import { SamplingSuiteCaptureDTO } from '../../rest-client';
import { CaptureLightbox } from '../CaptureLightbox';
import { useCaptureLightboxStore } from '../CaptureLightboxStore';
import { CapturePreview } from '../SamplingSuiteCapture';
import { useOptionalSampleSuiteAnalysisContext } from './SamplingSuiteAnalysisContext';

export function UnlinkedCaptures() {
  const unlinkedCapturesQuery = useSamplingSuiteCaptures({
    unlinked: true,
    suiteId: null,
    archived: false,
  });

  let body: ReactNode = null;
  if (unlinkedCapturesQuery.data) {
    const unlinkedCaptures = unlinkedCapturesQuery.data;
    if (unlinkedCaptures.length === 0) {
      return null;
    }

    body = (
      <>
        <UnlinkedCaptureTable unlinkedCaptures={unlinkedCaptures} />
        <UnlinkedCapturesLightbox unlinkedCaptures={unlinkedCaptures} />
      </>
    );
  } else if (unlinkedCapturesQuery.isError) {
    body = (
      <Alert color='red' title='Error'>
        An error ocurred trying to load unlinked captures.
      </Alert>
    );
  }

  return body;
}

function UnlinkedCaptureTable(props: {
  unlinkedCaptures: SamplingSuiteCaptureDTO[];
}) {
  const { unlinkedCaptures } = props;

  const pageSize = 5;
  const [activePage, setPage] = useState(1);

  const totalPages = Math.ceil(unlinkedCaptures.length / pageSize);
  const paginatedCaptures = unlinkedCaptures.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );
  return (
    <Stack align='center'>
      {paginatedCaptures.map((capture) => (
        <UnlinkedCaptureRow key={capture.captureId} unlinkedCapture={capture} />
      ))}
      {totalPages > 1 ? (
        <Pagination value={activePage} onChange={setPage} total={totalPages} />
      ) : null}
    </Stack>
  );
}

function UnlinkedCaptureRow(props: {
  unlinkedCapture: SamplingSuiteCaptureDTO;
}) {
  const { unlinkedCapture } = props;

  const patchMutation = usePatchSamplingSuiteCapture();
  const openLightbox = useCaptureLightboxStore((s) => s.open);

  const maybeAnalysisCtx = useOptionalSampleSuiteAnalysisContext();

  return (
    <CapturePreview
      capture={unlinkedCapture}
      onMaximize={() => openLightbox('unlinked', unlinkedCapture.captureId)}
      showWetDryToggle={false}
      leftButtonProps={
        maybeAnalysisCtx
          ? {
              color: 'blue',
              label: 'link',
              onClick: () => {
                if (!patchMutation.isIdle) return;
                patchMutation.mutate({
                  capture: unlinkedCapture,
                  patch: {
                    sampleAnalysisLink: {
                      samplingSuiteSampleAnalysisId:
                        maybeAnalysisCtx.samplingSuiteSampleAnalysisId,
                      materialClassAssignment: null,
                    },
                  },
                });
              },
            }
          : undefined
      }
      rightButtonProps={{
        color: 'red',
        onClick: () => {
          if (!patchMutation.isIdle) return;
          patchMutation.mutate({
            capture: unlinkedCapture,
            patch: {
              archived: true,
            },
          });
        },
        label: 'archive',
        icon: <IconArchive />,
      }}
    />
  );
}

export function UnlinkedCapturesLightbox(props: {
  unlinkedCaptures: SamplingSuiteCaptureDTO[];
}) {
  const { unlinkedCaptures } = props;

  const opened = useCaptureLightboxStore((s) => s.opened);
  const select = useCaptureLightboxStore((s) => s.select);
  const onClose = useCaptureLightboxStore((s) => s.close);
  const captureId = useCaptureLightboxStore((s) => s.captureId);

  return (
    <CaptureLightbox
      captures={unlinkedCaptures}
      selectedCaptureId={captureId}
      onCaptureSelect={select}
      titleFn={() => 'Unlinked Capture'}
      opened={opened === 'unlinked'}
      onClose={onClose}
    />
  );
}
