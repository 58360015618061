import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MaterialClassSetConversionCreationDTO,
  MaterialClassSetConversionPatchDTO,
  MaterialClassSetConversionService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchConversions(
  ctx: QueryFunctionContexts['materialClassSetConversion']['list'],
) {
  const [{ sourceMaterialClassSetId, targetMaterialClassSetId }] = ctx.queryKey;
  return await MaterialClassSetConversionService.getMaterialClassSetConversions(
    sourceMaterialClassSetId,
    targetMaterialClassSetId,
  );
}

export function useMaterialClassSetConversions(args: {
  sourceMaterialClassSetId: string | undefined;
  targetMaterialClassSetId: string | undefined;
}) {
  return useQuery({
    queryKey: queryKeys.materialClassSetConversion.list(args),
    queryFn: fetchConversions,
  });
}

async function fetchConversion(
  ctx: QueryFunctionContexts['materialClassSetConversion']['detail'],
) {
  const [{ conversionId }] = ctx.queryKey;

  return await MaterialClassSetConversionService.getMaterialClassSetConversion(
    conversionId,
  );
}

export function useMaterialClassSetConversion(id: string) {
  return useQuery({
    queryKey: queryKeys.materialClassSetConversion.detail(id),
    queryFn: fetchConversion,
  });
}

async function createMaterialClassSetConversion(
  dto: MaterialClassSetConversionCreationDTO,
) {
  await MaterialClassSetConversionService.createMaterialClassSetConversion(dto);
}

export function useCreateMaterialClassSetConversion() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createMaterialClassSetConversion,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.materialClassSetConversion.lists(),
        queryKeys.materialClassSetConversion.graph,
      );
    },
  });
}

async function patchMaterialClassSetConversion(args: {
  conversionId: string;
  patch: MaterialClassSetConversionPatchDTO;
}) {
  await MaterialClassSetConversionService.patchMaterialClassSetConversion(
    args.conversionId,
    args.patch,
  );
}

export function usePatchMaterialClassSetConversion(conversionId: string) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: MaterialClassSetConversionPatchDTO) =>
      await patchMaterialClassSetConversion({ conversionId, patch }),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.materialClassSetConversion.lists(),
        queryKeys.materialClassSetConversion.graph,
        queryKeys.materialClassSetConversion.detail(conversionId),
      );
    },
  });
}

async function deleteMaterialClassSetConversion(conversionId: string) {
  await MaterialClassSetConversionService.deleteMaterialClassSetConversion(
    conversionId,
  );
}

export function useDeleteMaterialClassSetConversion() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteMaterialClassSetConversion,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.materialClassSetConversion.lists(),
        queryKeys.materialClassSetConversion.details(),
        queryKeys.materialClassSetConversion.graph,
      );
    },
  });
}

async function fetchMaterialClassSetConversionGraph() {
  return await MaterialClassSetConversionService.getMaterialClassSetConversionGraph();
}

export function useMaterialClassSetConversionGraph() {
  return useQuery({
    queryKey: queryKeys.materialClassSetConversion.graph,
    queryFn: fetchMaterialClassSetConversionGraph,
  });
}
