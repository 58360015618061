import { Button, ButtonProps } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { openDeleteEntityConfirmModal } from '../Form/DeleteEntity';
import { DeleteIcon } from '../Icons';
import { useDeleteScaleReading, useScaleReading } from '../api/scale';
import { ScaleReadingDTO, ScaleReadingId } from '../rest-client';

export type DeleteScaleReadingButtonProps = Omit<
  ButtonProps,
  'onClick' | 'disabled'
> & {
  scaleReadingId: ScaleReadingId;
};

export function DeleteScaleReadingButton(props: DeleteScaleReadingButtonProps) {
  const {
    scaleReadingId,
    size = 'sm',
    compact = true,
    variant = 'outline',
    leftIcon = <DeleteIcon />,
    color = 'red',
    ...buttonProps
  } = props;

  const scaleReadingQuery = useScaleReading(scaleReadingId);
  const deleteScaleReadingMutation = useDeleteScaleReading();

  const scaleReading = scaleReadingQuery.data;

  if (scaleReadingQuery.isLoadingError) {
    throw scaleReadingQuery.error;
  }

  const openDeleteScaleReadingModal = (dto: ScaleReadingDTO) =>
    openDeleteEntityConfirmModal('Scale Reading', () => {
      deleteScaleReadingMutation.mutate(dto, {
        onError() {
          showNotification({
            title: 'Error Deleting Scale Reading',
            message: 'An error occurred deleting this scale reading',
            color: 'red',
            icon: <IconX />,
          });
        },
        onSuccess() {
          showNotification({
            title: 'Scale Reading Deleted',
            message: 'The scale reading was successfully deleted.',
            color: 'green',
            icon: <IconCheck />,
          });
        },
        onSettled() {
          deleteScaleReadingMutation.reset();
        },
      });
    });

  return (
    <Button
      size={size}
      compact={compact}
      variant={variant}
      leftIcon={leftIcon}
      color={color}
      disabled={
        scaleReadingQuery.isLoading || !deleteScaleReadingMutation.isIdle
      }
      onClick={scaleReading && openDeleteScaleReadingModal(scaleReading)}
      {...buttonProps}
    >
      Delete
    </Button>
  );
}
