import { Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { openDeleteEntityConfirmModal } from '../../Form/DeleteEntity';
import { ScaleIcon } from '../../Icons';
import { ScaleName } from '../../Scale/ScaleName';
import { EditScaleReadingDrawerForm } from '../../ScaleReading/EditScaleReadingDrawerForm';
import { TruckLoadIdName } from '../../TruckLoad/TruckLoadIdName';
import { getWeightUnitAbbreviation } from '../../Weights/units';
import { useDeleteScaleReading } from '../../api/scale';
import { CalendarDateTime } from '../../common';
import { ScaleReadingDTO } from '../../rest-client';
import { WithUnit } from '../../util/WithUnit';
import { DeleteMenuItem } from '../DeleteMenuItem';
import { EditMenuItem } from '../EditMenuItem';
import { EventTypeCellTemplate } from '../InventoryLedgerEventTypeCell';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu';
import { ContainerClaimStatusExplanationRow } from './ContainerClaimErrorAndWarningExplanationRow';

export function ScaleReadingRow(props: { scaleReading: ScaleReadingDTO }) {
  const { scaleReading } = props;

  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.scaleReadingStatus(scaleReading);
  const [editDrawerOpened, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);
  const deleteMutation = useDeleteScaleReading();

  const destinationCell = match(scaleReading.repositoryAssociation)
    .with({ kind: 'Container' }, ({ containerId }) => (
      <ContainerIdName
        containerId={containerId}
        variant='icon-name-link'
        time={dayjs.utc(scaleReading.timestamp)}
      />
    ))
    .with({ kind: 'TruckLoad' }, ({ truckLoadId }) => (
      <TruckLoadIdName truckLoadId={truckLoadId} variant='icon-name-link' />
    ))
    .with(null, () => (
      // TODO(1809): Action to associate this reading with a container/truck load
      <Text color='orange' weight={500}>
        unassociated
      </Text>
    ))
    .exhaustive();

  const openDeleteModal = openDeleteEntityConfirmModal('Scale Reading', () => {
    deleteMutation.mutate(scaleReading, {
      onError() {
        showNotification({
          title: 'Error Deleting Scale Reading',
          message: 'An error occurred deleting this scale reading',
          color: 'red',
          icon: <IconX />,
        });
      },
      onSuccess() {
        showNotification({
          title: 'Scale Reading Deleted',
          message: 'The scale reading was successfully deleted.',
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onSettled() {
        deleteMutation.reset();
      },
    });
  });

  const errorExplanationContent = (
    <ContainerClaimStatusExplanationRow status={status} />
  );

  const scale = scaleReading.scale;

  return (
    <>
      <EditScaleReadingDrawerForm
        opened={editDrawerOpened}
        onClose={closeEditDrawer}
        scaleReadingId={scaleReading.id}
      />
      <InventoryLedgerRowTemplate
        entryStatus={status}
        date={<CalendarDateTime iso8601={scaleReading.timestamp} />}
        eventType={
          <EventTypeCellTemplate
            icon={<ScaleIcon />}
            color='blue'
            name='Scale Reading'
          />
        }
        source={destinationCell}
        destination={
          <Group>
            {scale && (
              <WithUnit unitSuffix={getWeightUnitAbbreviation(scale.unit)}>
                {scaleReading.displayDivisions * scale.displayDivision}
              </WithUnit>
            )}
            <ScaleName scale={scale} withIcon />
          </Group>
        }
        actions={
          <RowActionsMenu>
            <EditMenuItem onClick={openEditDrawer} />
            <DeleteMenuItem onClick={openDeleteModal} />
          </RowActionsMenu>
        }
      />
      {errorExplanationContent}
    </>
  );
}
