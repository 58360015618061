import { match } from 'ts-pattern';
import { ProcessTopologyNode } from '../../Process/ProcessTopologyDiagram.tsx';
import Temporal from '../../Temporal/temporal.ts';
import { DetailedProcessDTO } from '../../rest-client';
import { FeedstockNodeMetrics } from './FeedstockNodeMetrics';
import { OutputPortNodeMetrics } from './OutputPortNodeMetrics';
import { SystemNodeMetrics } from './SystemNodeMetrics';

export function ProcessNodeMetrics({
  node,
  process,
  startTime,
  endTime,
}: {
  node: ProcessTopologyNode;
  process: DetailedProcessDTO;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  return match(node.data)
    .with({ type: 'feedstock' }, () => (
      <FeedstockNodeMetrics
        process={process}
        startTime={startTime}
        endTime={endTime}
      />
    ))
    .with({ type: 'system' }, (systemData) => (
      <SystemNodeMetrics
        system={systemData.sortSystem}
        process={process}
        startTime={startTime}
        endTime={endTime}
      />
    ))
    .with({ type: 'output' }, ({ outputPort }) => (
      <OutputPortNodeMetrics
        outputPort={outputPort}
        startTime={startTime}
        endTime={endTime}
      />
    ))
    .exhaustive();
}
