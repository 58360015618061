import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  ButtonProps,
  Card,
  Center,
  Flex,
  Group,
  HoverCard,
  Loader,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertCircle,
  IconCheck,
  IconPlugConnected,
  IconX,
} from '@tabler/icons-react';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { CompositionChart } from '../CompositionChart';
import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { DeleteIcon, EditIcon } from '../Icons';
import { LinkButton } from '../Link';
import { CaptureArchiveDrawerButton } from '../SamplingSuite/Analysis/ArchivedCaptures';
import {
  AssignedCapturesContainer,
  AssignedCapturesLightbox,
  BottomRow,
  MaterialClassRow,
} from '../SamplingSuite/Analysis/AssignedCaptureTable';
import { SamplingSuiteAnalysisContextProvider } from '../SamplingSuite/Analysis/SamplingSuiteAnalysisContext';
import { SamplingSuiteCaptureThumbnail } from '../SamplingSuite/Analysis/SamplingSuiteCaptureOld';
import {
  UnassignedCapturesLightbox,
  UnassignedCaptureTable,
} from '../SamplingSuite/Analysis/UnassignedCaptures';
import { UnlinkedCaptures } from '../SamplingSuite/Analysis/UnlinkedCaptures';
import {
  ParticleSizeDistributionChart,
  ParticleSizeDistributionChartProps,
} from '../SamplingSuite/ParticleSizeDistributionChart';
import NetWeight from '../Weights/NetWeight';
import { useSamplingSuites } from '../api/samplingSuite';
import {
  useDeleteSamplingSuiteSampleAnalysis,
  usePatchSamplingSuiteSampleAnalysis,
} from '../api/samplingSuiteSampleAnalysis';
import {
  useCreateSamplingSuiteSession,
  useSamplingSuiteSessions,
} from '../api/samplingSuiteSession';
import {
  CompleteSamplingSuiteCaptureSegmentationDTO,
  FailedSamplingSuiteCaptureSegmentationDTO,
  PendingSamplingSuiteCaptureSegmentationDTO,
  SamplingSuiteCaptureDTO,
  SamplingSuiteCaptureSegmentationDTO,
  SamplingSuiteSampleAnalysisDTO,
  SamplingSuiteSessionDTO,
} from '../rest-client';
import { Router } from '../router';
import { WithUnit } from '../util/WithUnit';
import { composition } from '../util/mixture';
import classes from './SamplingSuiteAnalysisDetail.module.css';
import { SEGMENTATION_ENABLED } from './flags';

export function SamplingSuiteAnalysisDetail(props: {
  analysis: SamplingSuiteSampleAnalysisDTO;
  isFetching: boolean;
  isError: boolean;
}) {
  const { analysis, isFetching, isError } = props;

  const sessionsQuery = useSamplingSuiteSessions({
    samplingSuiteSampleAnalysisId: analysis.id,
  });

  const deleteMutation = useDeleteSamplingSuiteSampleAnalysis();
  const patchMutation = usePatchSamplingSuiteSampleAnalysis();

  return (
    <Stack>
      <Group position='apart'>
        <Group align='baseline'>
          <Title order={3}>VALI-Sample Analysis</Title>
          {isFetching ? <Loader variant='dots' /> : null}
          {isError ? (
            <HoverCard>
              <HoverCard.Target>
                <Text c='red'>
                  <IconAlertCircle />
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text>Refresh error</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          ) : null}
          {analysis.isComplete ? (
            <Group>
              <Text color='green' weight='bold'>
                Complete
              </Text>
              <ActionIcon
                variant='default'
                loading={patchMutation.isLoading}
                onClick={() => {
                  patchMutation.mutate(
                    {
                      analysisId: analysis.id,
                      patch: {
                        completed: false,
                      },
                    },
                    {
                      onSettled: () => {
                        patchMutation.reset();
                      },
                    },
                  );
                }}
              >
                <EditIcon />
              </ActionIcon>
            </Group>
          ) : (
            <Text color='orange' weight='bold'>
              Incomplete
            </Text>
          )}
        </Group>

        <Button
          variant='outline'
          leftIcon={<DeleteIcon />}
          color='red'
          onClick={() => {
            modals.openConfirmModal({
              title: 'Delete sample analysis forever',
              centered: true,
              children: (
                <Text size='sm'>
                  Are you sure you want to delete this analysis? This action is
                  irreversible and the analysis will be deleted forever. All
                  linked captures will become unlinked.
                </Text>
              ),
              labels: {
                confirm: 'Delete Analysis Forever',
                cancel: 'Back to Safety',
              },
              confirmProps: { color: 'red' },
              onConfirm: () => {
                deleteMutation.mutate(analysis.id, {
                  onError() {
                    showNotification({
                      title: 'Error Deleting Analysis',
                      message: `An error occurred the analysis.`,
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'Analysis Deleted',
                      message: `The analysis was successfully deleted`,
                      color: 'green',
                      icon: <IconCheck />,
                    });
                  },
                  onSettled() {
                    deleteMutation.reset();
                  },
                });
              },
            });
          }}
          loading={deleteMutation.isLoading}
        >
          Delete Analysis
        </Button>
      </Group>

      {sessionsQuery.data && (
        <SamplingSuiteAnalysisContextProvider
          samplingSuiteSampleAnalysisId={analysis.id}
        >
          <SamplingSuiteAnalysisData
            analysis={analysis}
            sessions={sessionsQuery.data}
          />
        </SamplingSuiteAnalysisContextProvider>
      )}
    </Stack>
  );
}

function StartSessionButton(
  props: ButtonProps & {
    analysis: SamplingSuiteSampleAnalysisDTO;
  },
) {
  const { analysis, ...buttonProps } = props;

  const samplingSuitesQuery = useSamplingSuites();
  const newSessionMutation = useCreateSamplingSuiteSession();

  const [samplingSuiteId, setSamplingSuiteId] = useState<string | null>(null);
  const suites = samplingSuitesQuery.data;

  const openUnownedAnalysisSessions = useSamplingSuiteSessions({
    samplingSuiteSampleAnalysisId: analysis.id,
    isOpen: true,
    isOwned: false,
  });

  const openUnownedSuiteSessions = useSamplingSuiteSessions({
    samplingSuiteId: samplingSuiteId ?? undefined,
    isOpen: true,
    isOwned: false,
  });

  if (suites) {
    if (suites.length === 0) {
      return <Alert color='orange'>No VALI-Sample instances found.</Alert>;
    }

    if (suites.length === 1 && samplingSuiteId === null) {
      setSamplingSuiteId(suites[0].id);
    }
  }

  const startSession = () => {
    if (samplingSuiteId === null) {
      throw new Error('sampling suite id required');
    }

    const sessionId = uuidv4();
    newSessionMutation.mutate(
      {
        samplingSessionId: sessionId,
        samplingSuiteSampleAnalysisId: analysis.id,
        samplingSuiteId,
      },
      {
        onSuccess() {
          Router.push('SamplingSessionSession', { sessionId });
        },
      },
    );
  };

  const onStartSessionClick = () => {
    if (
      (openUnownedAnalysisSessions.data &&
        openUnownedAnalysisSessions.data.length > 0) ||
      (openUnownedSuiteSessions.data &&
        openUnownedSuiteSessions.data.length > 0)
    ) {
      modals.openConfirmModal({
        title: "Close other user's session?",
        centered: true,
        children: (
          <Text>
            Another user has an active session on this analysis or with the
            VALI-Sample instance. Starting this session will forcefully
            terminate their session. Do you wish to continue?
          </Text>
        ),
        labels: {
          confirm: 'Close other session',
          cancel: 'Back to safety',
        },
        confirmProps: { color: 'red' },
        onCancel: () => {},
        onConfirm: startSession,
      });
    } else {
      startSession();
    }
  };

  const suiteSelector =
    suites && suites.length > 1 ? (
      <Select
        label='VALI-Sample Instance'
        value={samplingSuiteId}
        onChange={(suiteId) => setSamplingSuiteId(suiteId)}
        data={suites.map((suite) => ({ value: suite.id, label: suite.name }))}
      />
    ) : null;

  // TODO: Show warning if another session is in progress using the same suite, or on the same analysis

  if (newSessionMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle={'Error Starting VALI-Sample Session'}
        entityName={'VALI-Sample Session'}
        mutation={newSessionMutation}
        formVariant={'create'}
      />
    );
  }

  return (
    <Group>
      {suiteSelector}
      <Button
        {...buttonProps}
        leftIcon={<IconPlugConnected />}
        disabled={samplingSuiteId === null}
        color='teal'
        variant='filled'
        loading={samplingSuitesQuery.isLoading || newSessionMutation.isLoading}
        onClick={onStartSessionClick}
      >
        Connect to VALI-Sample
      </Button>
    </Group>
  );
}

function SamplingSuiteAnalysisData(props: {
  analysis: SamplingSuiteSampleAnalysisDTO;
  sessions: SamplingSuiteSessionDTO[];
}) {
  const { analysis, sessions } = props;

  if (analysis.isComplete) {
    return <CompleteAnalysisData analysis={analysis} />;
    // TODO: Show session history
  }

  const anyLinkedCaptures =
    (analysis.wholeSampleCaptures?.captures.length ?? 0) > 0 ||
    Object.values(analysis.materialClassCaptures).some(
      (c) => c.captures.length > 0,
    ) ||
    analysis.unassignedCaptures.length > 0;

  const latestSession = sessions.at(0);
  if (latestSession === undefined) {
    // there have been no sessions on this analysis
    // it's still possible to have linked and assigned captures though through manual linking and assignment

    if (anyLinkedCaptures) {
      // TODO: show partial data + start session button + edit button
      return (
        <>
          <IncompleteAnalysisView analysis={analysis} />
          <StartSessionButton analysis={analysis} />
        </>
      );
    }
    // there are no linked captures, and there has never been a session
    // TODO: Show edit button so user can link any unlinked captures
    return (
      <Center>
        <Stack align='center' spacing='xs'>
          <Title order={3} color='dimmed'>
            No Captures
          </Title>
          <Text c='dimmed'>
            No captures have been linked to this analysis yet.
          </Text>
          <Text c='dimmed'>
            Start a session to take captures, or edit the analysis to link
            existing captures.
          </Text>
          <StartSessionButton analysis={analysis} size='lg' />
        </Stack>
      </Center>
    );
  }

  // there has been at least one session on this analysis
  const latestSessionIsActive = latestSession.endDetails === null;
  const latestSessionIsOwned = latestSession.owned;

  if (latestSessionIsActive) {
    if (latestSessionIsOwned) {
      // the user owns an active session on this analysis
      return (
        <Alert title='Open Session' color='pink'>
          <Stack>
            <Text>You have an active session for this analysis.</Text>
            <LinkButton
              to={Router.SamplingSessionSession({
                sessionId: latestSession.id,
              })}
              color='pink'
              size='md'
            >
              Resume Session
            </LinkButton>
          </Stack>
        </Alert>
      );
    }
    // there is an active session, but we don't own it
    return (
      <>
        <IncompleteAnalysisView analysis={analysis} />
        <StartSessionButton analysis={analysis} />
      </>
    );
  }

  // there is no active session on the analysis
  return (
    <>
      <StartSessionButton analysis={analysis} />
      <IncompleteAnalysisView analysis={analysis} />
    </>
  );

  // TODO: We need to show partial state that is editable without a session
}

function IncompleteAnalysisView(props: {
  analysis: SamplingSuiteSampleAnalysisDTO;
}) {
  const { analysis } = props;

  const [activeMaterialClass, setActiveMaterialClass] = useState<string | null>(
    null,
  );
  const [emptyState, setEmptyState] = useState<Record<string, boolean>>({
    whole:
      analysis.wholeSampleCaptures === null ||
      analysis.wholeSampleCaptures.captures.length === 0,
    ...Object.fromEntries(
      analysis.materialClassSet.materialClasses.map((mc) => {
        const hasCaptures =
          mc.id in analysis.materialClassCaptures &&
          analysis.materialClassCaptures[mc.id].captures.length > 0;
        return [mc.id, !hasCaptures];
      }),
    ),
  });

  const wholeSampleRow = (
    <MaterialClassRow
      key='whole'
      materialClass={null}
      captures={analysis.wholeSampleCaptures?.captures ?? []}
      totalNetWeight={
        analysis.wholeSampleCaptures?.totalUndriedNetWeight ?? null
      }
      totalDriedNetWeight={
        analysis.wholeSampleCaptures?.totalDriedNetWeight ?? null
      }
      isEmpty={emptyState['whole']}
      onEmptyChange={(isEmpty) =>
        setEmptyState((s) => ({ ...s, whole: isEmpty }))
      }
      active={activeMaterialClass === null}
      activate={() => setActiveMaterialClass(null)}
    />
  );

  const materialClassRows = analysis.materialClassSet.materialClasses.map(
    (mc) => {
      const captures =
        mc.id in analysis.materialClassCaptures
          ? analysis.materialClassCaptures[mc.id]
          : null;

      return (
        <MaterialClassRow
          key={mc.id}
          materialClass={mc}
          captures={captures?.captures ?? []}
          totalNetWeight={captures?.totalUndriedNetWeight ?? null}
          totalDriedNetWeight={captures?.totalDriedNetWeight ?? null}
          // TODO: Client-side empty state
          isEmpty={emptyState[mc.id]}
          onEmptyChange={(isEmpty) =>
            setEmptyState((s) => ({ ...s, [mc.id]: isEmpty }))
          }
          active={activeMaterialClass === mc.id}
          activate={() => setActiveMaterialClass(mc.id)}
        />
      );
    },
  );

  const unlinkedCaptures = <UnlinkedCaptures />;

  const patchMutation = usePatchSamplingSuiteSampleAnalysis();

  const namedCaptures: { capture: SamplingSuiteCaptureDTO; name: string }[] =
    [];
  for (const capture of analysis.wholeSampleCaptures?.captures ?? []) {
    namedCaptures.push({ capture, name: 'Whole Sample' });
  }

  for (const mc of analysis.materialClassSet.materialClasses) {
    if (mc.id in analysis.materialClassCaptures) {
      const captures = analysis.materialClassCaptures[mc.id].captures;
      for (const capture of captures) {
        namedCaptures.push({
          capture,
          name: mc.name,
        });
      }
    }
  }

  return (
    <Group align='flex-start' className={classes.topLevel}>
      <AssignedCapturesContainer>
        {wholeSampleRow}
        {materialClassRows}
        <BottomRow
          key='bottom'
          states={[
            {
              isEmpty: emptyState['whole'],
              captures: analysis.wholeSampleCaptures?.captures ?? [],
              materialClassId: null,
            },
            ...analysis.materialClassSet.materialClasses.map(
              (materialClass) => {
                const captures =
                  materialClass.id in analysis.materialClassCaptures
                    ? analysis.materialClassCaptures[materialClass.id]
                    : null;

                return {
                  isEmpty: emptyState[materialClass.id],
                  captures: captures?.captures ?? [],
                  materialClassId: materialClass.id,
                };
              },
            ),
          ]}
          unassignedCaptures={analysis.unassignedCaptures}
          completionMutation={patchMutation}
          complete={() => {
            patchMutation.mutate({
              analysisId: analysis.id,
              patch: {
                completed: true,
              },
            });
          }}
        />
      </AssignedCapturesContainer>
      <AssignedCapturesLightbox namedCaptures={namedCaptures} />

      <Paper withBorder p='sm'>
        <Stack>
          <div>
            <Title order={4}>Unassigned Captures</Title>
            <Text color='dimmed' size='sm'>
              Linked to this analysis
            </Text>
          </div>
          <UnassignedCaptureTable
            captures={analysis.unassignedCaptures}
            activeMaterialClass={activeMaterialClass}
          />
        </Stack>
      </Paper>
      <UnassignedCapturesLightbox captures={analysis.unassignedCaptures} />

      {unlinkedCaptures ? (
        <Paper withBorder p='sm'>
          <Stack>
            <div>
              <Title order={4}>Unlinked Captures</Title>
              <Text color='dimmed' size='sm'>
                Not linked to any analysis
              </Text>
            </div>
            {unlinkedCaptures}
          </Stack>
        </Paper>
      ) : null}

      <CaptureArchiveDrawerButton />
    </Group>
  );
}

function CompleteAnalysisData(props: {
  analysis: SamplingSuiteSampleAnalysisDTO;
}) {
  const { analysis } = props;

  return (
    <SimpleGrid cols={2}>
      <CompleteAnalysisTable analysis={analysis} />
      <Stack w='100%'>
        <Paper withBorder p='md'>
          <Title order={4}>Mass Composition</Title>
          <CompositionChart
            composition={composition(
              analysis.materialClassSet.materialClasses.map(
                (materialClass) =>
                  [
                    materialClass.name,
                    materialClass.id in analysis.materialClassCaptures
                      ? analysis.materialClassCaptures[materialClass.id]
                          .weightProportion ?? 0
                      : 0,
                  ] as const,
              ),
            )}
            rotateXAxisLabels
          />
        </Paper>
        {SEGMENTATION_ENABLED ? (
          <Paper withBorder p='md'>
            <Title order={4} mb='md'>
              Material Size Distribution
            </Title>
            <MultiSegPsdCell
              w='100%'
              h='200px'
              hideYLabel={false}
              segmentations={Object.values(analysis.materialClassCaptures)
                .flatMap((c) => c.captures)
                .map((c) => c.segmentation)}
            />
          </Paper>
        ) : null}
      </Stack>
    </SimpleGrid>
  );
}

function CompleteAnalysisTable(props: {
  analysis: SamplingSuiteSampleAnalysisDTO;
}) {
  const { analysis } = props;
  const materialClassCaptures = new Map(
    Object.entries(analysis.materialClassCaptures),
  );

  const { materialClasses } = analysis.materialClassSet;

  let largestMaxFeretDiameter: number | undefined = undefined;

  for (const { captures } of Object.values(analysis.materialClassCaptures)) {
    for (const capture of captures) {
      if (capture.segmentation.status !== 'complete') continue;
      for (const mask of capture.segmentation.masks) {
        largestMaxFeretDiameter ??= mask.maxFeretDiameterInches;
        largestMaxFeretDiameter = Math.max(
          largestMaxFeretDiameter,
          mask.maxFeretDiameterInches,
        );
      }
    }
  }

  return (
    <Paper
      withBorder
      className={
        SEGMENTATION_ENABLED
          ? classes.completeAnalysisTableSegmentationEnabled
          : classes.completeAnalysisTableSegmentationDisabled
      }
    >
      <Box className={classes.fauxTableHeadings}>
        <div>
          <Text w='max-content'>Material Class</Text>
        </div>
        <Flex justify='center'>
          <Text>Captures</Text>
        </Flex>
        <Flex justify='right'>
          <Text>Weight</Text>
        </Flex>
        <Flex justify='right'>
          <Text w='max-content'>Wt. %</Text>
        </Flex>
        {SEGMENTATION_ENABLED ? (
          <Flex justify='center'>
            <Text>
              PSD{' '}
              <Text span color='dimmed'>
                (Max Diameter)
              </Text>
            </Text>
          </Flex>
        ) : null}
      </Box>
      {materialClasses.map((mc) => {
        const classCaptures = materialClassCaptures.get(mc.id);

        if (!classCaptures) {
          return (
            <div key={mc.id} style={{ display: 'contents' }}>
              <Text weight={500} color='dimmed'>
                {mc.name}
              </Text>
              <div style={{ textAlign: 'center' }}>
                <Text color='dimmed'>none</Text>
              </div>
              <div style={{ textAlign: 'right' }}>
                <WithUnit unitSuffix='g'>
                  <Text c='dimmed'>0</Text>
                </WithUnit>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Text c='dimmed'>0%</Text>
              </div>
              {SEGMENTATION_ENABLED ? (
                <div style={{ textAlign: 'center' }}>
                  <Text c='dimmed'>none</Text>
                </div>
              ) : null}
            </div>
          );
        }

        const segmentations = classCaptures.captures.map((c) => c.segmentation);

        return (
          <div key={mc.id} style={{ display: 'contents' }}>
            <Flex align='center'>
              <Text weight={600}>{mc.name}</Text>
            </Flex>

            <Stack>
              {classCaptures.captures.map((c) => (
                <Card key={c.captureId} shadow='sm'>
                  <Card.Section>
                    <SamplingSuiteCaptureThumbnail capture={c} />
                  </Card.Section>
                </Card>
              ))}
            </Stack>
            <Flex justify='right' align='center'>
              <NetWeight weight={classCaptures.adjustedNetWeight} />
            </Flex>
            <Flex justify='right' align='center'>
              {classCaptures.weightProportion ? (
                <Text>
                  {(classCaptures.weightProportion * 100).toFixed(1)}%
                </Text>
              ) : null}
            </Flex>
            {SEGMENTATION_ENABLED ? (
              <Flex align='center'>
                <MultiSegPsdCell
                  segmentations={segmentations}
                  xMax={largestMaxFeretDiameter}
                />
              </Flex>
            ) : null}
          </div>
        );
      })}

      <Box className={classes.totalWrapper}>
        <Flex align='center' justify='left'>
          <Text weight='bolder'>TOTAL</Text>
        </Flex>
        <Flex align='center' justify='center'>
          {
            [...materialClassCaptures.values()].flatMap((c) => c.captures)
              .length
          }
        </Flex>
        <Flex align='center' justify='right'>
          <NetWeight weight={analysis.accumulatedTotalNetWeight} />
        </Flex>
        <Flex align='center' justify='right'>
          100%
        </Flex>
        {SEGMENTATION_ENABLED ? (
          <Flex>
            <MultiSegPsdCell
              segmentations={[...materialClassCaptures.values()]
                .flatMap((c) => c.captures)
                .map((c) => c.segmentation)}
            />
          </Flex>
        ) : null}
      </Box>

      {analysis.wholeSampleCaptures &&
      analysis.wholeSampleCaptures.captures.length > 0 ? (
        <div style={{ display: 'contents' }}>
          <Flex align='center'>
            <Badge>Whole Sample</Badge>
          </Flex>
          <Stack>
            {analysis.wholeSampleCaptures.captures.map((c) => (
              <Card key={c.captureId} shadow='sm'>
                <Card.Section>
                  <SamplingSuiteCaptureThumbnail capture={c} />
                </Card.Section>
              </Card>
            ))}
          </Stack>
          <Flex justify='right' align='center'>
            <NetWeight
              weight={analysis.wholeSampleCaptures.adjustedNetWeight}
            />
          </Flex>
          <Flex justify='right' align='center'>
            {/* TODO: Render error % */}
            {/* {analysis.wholeSampleCaptures.weightProportion === null
              ? '100'
              : (analysis.wholeSampleCaptures.weightProportion * 100).toFixed(
                  1,
                )}
            % */}
          </Flex>
          {SEGMENTATION_ENABLED ? (
            <Flex align='center'>
              <MultiSegPsdCell
                segmentations={analysis.wholeSampleCaptures.captures.map(
                  (c) => c.segmentation,
                )}
              />
            </Flex>
          ) : null}
        </div>
      ) : null}
    </Paper>
  );
}

function MultiSegPsdCell(
  props: {
    segmentations: SamplingSuiteCaptureSegmentationDTO[];
  } & Omit<ParticleSizeDistributionChartProps, 'sizes' | 'binWidthInches'>,
) {
  const {
    segmentations,
    w = '100%',
    h = '100px',
    hideYLabel = true,
    ...rest
  } = props;
  const completedSegs: CompleteSamplingSuiteCaptureSegmentationDTO[] = [];
  const pendingSegs: PendingSamplingSuiteCaptureSegmentationDTO[] = [];
  const failedSegs: FailedSamplingSuiteCaptureSegmentationDTO[] = [];
  for (const seg of segmentations) {
    match(seg)
      .with({ status: 'complete' }, (completed) => {
        completedSegs.push(completed);
      })
      .with({ status: 'pending' }, (pending) => pendingSegs.push(pending))
      .with({ status: 'failed' }, (failed) => failedSegs.push(failed))
      .exhaustive();
  }

  const allMasks = completedSegs.flatMap((seg) => seg.masks);
  const allSizes = allMasks.map((mask) => mask.maxFeretDiameterInches);
  return (
    <ParticleSizeDistributionChart
      h={h}
      w={w}
      sizes={allSizes}
      binWidthInches={0.5}
      hideYLabel={hideYLabel}
      {...rest}
    />
  );
}
