import { create } from 'zustand';
import { MaterialClassSetId } from '../../rest-client';
import { BaseSampleTableStore, SampleTableRow } from './SampleTableState';

type CommoditySampleTableStore = BaseSampleTableStore;

export const useCommoditySampleTableStore = create<CommoditySampleTableStore>()(
  (set) => ({
    filteredRows: undefined,
    setFilteredRows: (rows: SampleTableRow[]) => {
      set(() => ({ filteredRows: rows }));
    },
    materialClassSetId: null,
    setMaterialClassSetId: (id: MaterialClassSetId | null) => {
      set(() => ({ materialClassSetId: id }));
    },
  }),
);
