import {
  Alert,
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  Flex,
  Group,
  Progress,
  Radio,
  Stack,
  Text,
} from '@mantine/core';
import { IconAlertHexagon, IconChecks, IconX } from '@tabler/icons-react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { usePatchSamplingSuiteCapture } from '../../api/samplingSuite';
import {
  NetWeightDTO,
  SamplingSuiteCaptureDTO,
  WeightUnit,
} from '../../rest-client';
import NetWeight, { NetWeightZero } from '../../Weights/NetWeight';
import { CaptureLightbox } from '../CaptureLightbox';
import { useCaptureLightboxStore } from '../CaptureLightboxStore';
import {
  CapturePreview,
  CapturePreviewPlaceholder,
} from '../SamplingSuiteCapture';
// eslint-disable-next-line css-modules/no-unused-class
import classes from './SamplingSuiteAnalysis.module.css';
import { useSamplingSuiteAnalysisContext } from './SamplingSuiteAnalysisContext';

export function AssignedCapturesContainer(props: { children: ReactNode }) {
  const { children } = props;

  const headerRow = (
    <div key='header' className={classes.tableHeader}>
      <Text size='lg' weight={600} px='xs' align='center'>
        Class
      </Text>
      <Text size='lg' weight={600} px='xs' align='center'>
        Empty
      </Text>
      <Text size='lg' weight={600} px='xs' align='center'>
        Captures
      </Text>
      <Text size='lg' weight={600} px='xs' align='right'>
        Weight
      </Text>
    </div>
  );
  return (
    <Box className={classes.table}>
      {headerRow}
      {children}
    </Box>
  );
}

interface MaterialClassRowProps {
  materialClass: {
    id: string;
    name: string;
  } | null;
  captures: SamplingSuiteCaptureDTO[];
  totalNetWeight: NetWeightDTO | null;
  totalDriedNetWeight: NetWeightDTO | null;
  isEmpty: boolean;
  onEmptyChange?: (isEmpty: boolean) => void;
  active: boolean;
  activate: () => void;
}

export function MaterialClassRow(props: MaterialClassRowProps) {
  const {
    materialClass,
    isEmpty,
    onEmptyChange,
    captures,
    totalNetWeight,
    totalDriedNetWeight,
    active,
    activate,
  } = props;

  const classNames = [classes.row];
  classNames.push(isEmpty ? classes.empty : classes.nonEmpty);
  classNames.push(active ? classes.activeRow : classes.inactiveRow);
  classNames.push(
    captures.length > 0 ? classes.hasCaptures : classes.noCaptures,
  );

  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!rowRef.current) return;
    if (active) {
      rowRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [active]);

  return (
    <div className={classNames.join(' ')}>
      <Flex
        sx={{ scrollMargin: '12rem' }}
        ref={rowRef}
        align='center'
        p='xs'
        onClick={activate}
        className={isEmpty ? undefined : classes.activator}
        gap='xs'
      >
        <Radio color='blue' readOnly size='md' checked={active} />
        <Text className={classes.rowName}>
          {materialClass?.name ?? 'Whole Sample'}
        </Text>
      </Flex>
      <Flex align='center' justify='center'>
        <EmptySelect
          hasCaptures={captures.length > 0}
          checked={isEmpty}
          onChange={(e) => onEmptyChange?.(e.currentTarget.checked)}
        />
      </Flex>
      <Flex
        wrap='nowrap'
        align='center'
        sx={{ transition: 'height 200ms ease' }}
        gap='xs'
        p='xs'
      >
        {captures.map((capture, index) => (
          <AssignedCapture
            key={capture.captureId}
            index={index}
            capture={capture}
          />
        ))}
        {captures.length === 0 ? (
          isEmpty ? (
            <Text c='dimmed' align='center' w='100%' size='sm'>
              no material
            </Text>
          ) : (
            <CapturePreviewPlaceholder active={active} />
          )
        ) : null}
      </Flex>

      <Flex align='center' justify='flex-end' px='xs' wrap='nowrap' gap='0.5ch'>
        {totalNetWeight === null ? (
          <NetWeightZero unit={WeightUnit.GRAM} />
        ) : (
          <NetWeight weight={totalNetWeight} />
        )}
        {totalDriedNetWeight !== null ? (
          <>
            <span>/</span>
            <Box c='orange'>
              <NetWeight weight={totalDriedNetWeight} />
            </Box>
          </>
        ) : null}
      </Flex>
    </div>
  );
}

function EmptySelect(props: CheckboxProps & { hasCaptures: boolean }) {
  const { hasCaptures, ...checkboxProps } = props;
  const SkipCheckboxIcon: CheckboxProps['icon'] = ({
    className,
  }: {
    className: string;
  }) => <IconX className={className} />;

  const { checked } = checkboxProps;
  return (
    <Checkbox
      size='md'
      color={checked && hasCaptures ? 'red' : 'yellow'}
      icon={SkipCheckboxIcon}
      {...checkboxProps}
    />
  );
}

function AssignedCapture(props: {
  capture: SamplingSuiteCaptureDTO;
  index: number;
}) {
  const { capture, index } = props;

  const patchMutation = usePatchSamplingSuiteCapture();

  const openLightbox = useCaptureLightboxStore((s) => s.open);

  const { samplingSuiteSampleAnalysisId } = useSamplingSuiteAnalysisContext();

  return (
    <CapturePreview
      showCustomTraySelect
      showWetDryToggle={index > 0 || capture.isDried}
      capture={capture}
      loading={!patchMutation.isIdle}
      onMaximize={() => openLightbox('assigned', capture.captureId)}
      rightButtonProps={{
        onClick: () => {
          patchMutation.mutate({
            capture,
            patch: {
              sampleAnalysisLink: {
                samplingSuiteSampleAnalysisId,
                materialClassAssignment: null,
              },
            },
          });
        },
        color: 'orange',
        label: 'unassign',
      }}
    />
  );
}

export function AssignedCapturesLightbox(props: {
  namedCaptures: { capture: SamplingSuiteCaptureDTO; name: string }[];
}) {
  const { namedCaptures } = props;

  const opened = useCaptureLightboxStore((s) => s.opened);
  const select = useCaptureLightboxStore((s) => s.select);
  const onClose = useCaptureLightboxStore((s) => s.close);
  const captureId = useCaptureLightboxStore((s) => s.captureId);

  return (
    <CaptureLightbox
      captures={namedCaptures.map((c) => c.capture)}
      selectedCaptureId={captureId}
      onCaptureSelect={select}
      titleFn={(capture) =>
        namedCaptures.find((c) => c.capture.captureId === capture.captureId)
          ?.name
      }
      opened={opened === 'assigned'}
      onClose={onClose}
    />
  );
}

export function BottomRow(props: {
  states: {
    isEmpty: boolean;
    captures: SamplingSuiteCaptureDTO[];
    materialClassId: string | null;
  }[];
  unassignedCaptures: SamplingSuiteCaptureDTO[];
  actions?: ReactNode;
  complete: () => void;
  completionMutation: { isLoading: boolean };
}) {
  const { states, actions, unassignedCaptures, complete, completionMutation } =
    props;

  const numStates = states.length;
  const numReadyStates = states.reduce(
    (t, s) =>
      t +
      ((s.isEmpty && s.captures.length === 0) ||
      (!s.isEmpty && s.captures.length > 0)
        ? 1
        : 0),
    0,
  );

  const allRowsReady = numStates === numReadyStates;

  const anyMaterialClassAssignments = states.some(
    (s) => s.materialClassId !== null && !s.isEmpty,
  );

  const anyUnassignedCaptures = unassignedCaptures.length > 0;

  const completable =
    allRowsReady && !anyUnassignedCaptures && anyMaterialClassAssignments;

  const [showNotCompleteableExplainer, setShowNotCompleteableExplainer] =
    useState(false);

  const notCompletableExplainer = completable ? null : (
    <Alert
      title='Analysis Cannot be Completed'
      color='orange'
      withCloseButton
      onClose={() => setShowNotCompleteableExplainer(false)}
    >
      {anyMaterialClassAssignments ? null : (
        <Text>At least one material class must have a capture.</Text>
      )}
      {allRowsReady ? null : (
        <Text>All rows must either be marked as empty, or have a capture.</Text>
      )}
      {anyUnassignedCaptures ? (
        <Text>All staged captures must be assigned or unlinked.</Text>
      ) : null}
    </Alert>
  );

  const completeButton = (
    <Button
      loading={completionMutation.isLoading}
      leftIcon={completable ? <IconChecks /> : <IconAlertHexagon />}
      variant={completable ? 'filled' : 'outline'}
      color={completable ? 'teal' : 'orange'}
      onClick={() => {
        if (completable) {
          complete();
        } else {
          setShowNotCompleteableExplainer(true);
        }
      }}
    >
      Complete Analysis
    </Button>
  );

  const endButtons = (
    <Stack w='100%'>
      {showNotCompleteableExplainer ? notCompletableExplainer : null}
      <Group spacing='xs' position='apart' w='100%'>
        {actions}
        <div>{completeButton}</div>
      </Group>
    </Stack>
  );

  return (
    <div className={classes.bottomRow}>
      <Stack align='center'>
        <Stack spacing='xs' w='100%' align='center'>
          <Text size='sm'>
            {numReadyStates}/{numStates} rows ready
          </Text>
          <Progress
            color={
              anyMaterialClassAssignments
                ? allRowsReady
                  ? 'teal'
                  : 'blue'
                : 'orange'
            }
            value={(100 * numReadyStates) / numStates}
            w='100%'
          />
        </Stack>
        {endButtons}
      </Stack>
    </div>
  );
}
